import React, {useEffect, useState} from 'react';
import {AutoComplete} from "primereact/autocomplete";
import {Box, FormControlLabel} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {findEnumValue} from "../../constants/dictType";
import {Message} from "primereact/message";

interface Props {
    value: any,
    setValue: any,
    completeMethod: any,
    disabled?: boolean,
    canChangeRoomAndTime?: boolean,
    errorMsg?: string,
    label?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    placeholder?: string;
    multiple?: boolean;
    filters?: any;
    dateFilters?: any; // do autocomplete dictionary, zajętość sal, prowadzących itp
    width?: string;
    spaceAfterLabel?: string;
    emptyValue?: boolean;
    classroom?: any; // leniwe filtry, żeby na liście dostępnych sal nie było tych już wybranych we wniosku
    classroom2?: any;
    debriefingRoom?: any;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    autocomplete: {
        width: "100%",
    }
}));

const CustomAutocomplete = ({
                                value,
                                setValue,
                                completeMethod,
                                label = "",
                                labelPlacement = "start",
                                placeholder = "",
                                disabled = false,
                                canChangeRoomAndTime = true,
                                errorMsg = "",
                                multiple = false,
                                filters,
                                dateFilters,
                                width = "200px",
                                spaceAfterLabel = "30px",
                                emptyValue = false,
                                classroom, classroom2, debriefingRoom
                            }: Props) => {
    if (label === "")
        spaceAfterLabel = "0px";
    const {container, autocomplete} = useStyles();
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [matchedItems, setMatchedItems] = useState<any[]>([]);
    const [innerValue, setInnerValue] = useState<any>(multiple ? [] : {name: "", id: 0});
    const [enableRoomChange, setEnableRoomChange] = useState<any>(false);

    const innerCompleteMethod = (event: any) => {
        let newItems = filteredItems;
        if (multiple) {
            newItems = newItems.filter(item => !innerValue.some((item2 : any) => item.id === item2.id))
        }
        if (findEnumValue("CLASSROOM") === filters.dictType) {
            let classroomRemoveIds : any = [];
            if (classroom !== undefined && classroom !== null && classroom.id !== null) {
                classroomRemoveIds = [...classroomRemoveIds, classroom.id]
            }
            if (classroom2 !== undefined && classroom2 !== null && classroom2.id !== null) {
                classroomRemoveIds = [...classroomRemoveIds, classroom2.id]
            }
            if (debriefingRoom !== undefined && debriefingRoom !== null && debriefingRoom.id !== null) {
                classroomRemoveIds = [...classroomRemoveIds, debriefingRoom.id]
            }
            newItems = newItems.filter(item => !classroomRemoveIds.includes(item.id));
        }

        if (!event.query.trim().length) {
            newItems = [...newItems];
        } else {
            newItems = newItems.filter(item => item.name.toLowerCase().includes(event.query.toLowerCase()))
        }
        setMatchedItems(newItems);
    }

    useEffect( () => {
        completeMethod({
            dictionarySearchDto: {...filters, ...dateFilters, active: true},
        }).then((res: any) => {
            let items;
            if (emptyValue) {
                items = [{name: "", value: 0}, ...res.data]
            } else {
                items = res.data;
            }
            setFilteredItems(items);
            setMatchedItems(items);
            if (!multiple) {
                if (value !== null && value !== undefined && !items.some((item: any) => item.id === value.id)) {
                    setValue({name: "", id: 0});
                    setInnerValue({name: "", id: 0});
                    if (!canChangeRoomAndTime) {
                        setEnableRoomChange(true);
                    }
                }
            } else {
                // todo: ustaw stara wartosc jak wyżej jesli dostepna
                setValue([]);
                setInnerValue([]);
            }

        });
    }, [filters, dateFilters])

    useEffect(() => {
        if (value === null) {
            if (multiple){
                setInnerValue([]);
            } else {
                setInnerValue({name: "", id: 0});
            }
        } else {
            setInnerValue(value);
        }

    }, [value]);

    return (
        <Box>
            <FormControlLabel
                label={<div style={{width: width}}>{label}</div>}
                control={
                    <AutoComplete value={innerValue} suggestions={matchedItems} field="name"
                                  completeMethod={(e) => innerCompleteMethod(e)}
                                  onChange={(e) => {
                                      if ((typeof e.value) === "string") {
                                          setInnerValue({name: e.value, id: 0});
                                          if (e.value === "") {
                                              setValue({name: e.value, id: 0});
                                          }
                                      } else {
                                          setInnerValue(e.value);
                                          if (multiple) {
                                              setValue([...e.value]);
                                          } else {
                                              setValue(e.value);
                                          }
                                      }
                                  }}
                                  placeholder={placeholder} dropdown multiple={multiple}
                                  disabled={disabled && !enableRoomChange} className={autocomplete}/>
                }
                labelPlacement={labelPlacement}
                className={container}
                sx={{gap: spaceAfterLabel, cursor: "initial"}}
            />
            {errorMsg !== "" && enableRoomChange && <div style={{paddingTop: "10px"}}><Message severity="error" text={errorMsg}/></div>}
        </Box>
    )
}

const filtersEqual = (filters1: any, filters2: any) => {
    if (filters1 === null || filters1 === undefined || filters2 === null || filters2 === undefined) return true;
    return ((filters1.dictType === undefined && filters2.dictType === undefined) || filters1.dictType === filters2.dictType)
        && ((filters1.departmentId === undefined && filters2.departmentId === undefined) || filters1.departmentId === filters2.departmentId)
        && ((filters1.degreeCourseId === undefined && filters2.degreeCourseId === undefined) || filters1.degreeCourseId === filters2.degreeCourseId);
}

const dateFiltersEqual = (filters1: any, filters2: any) => {
    if (filters1 === null || filters1 === undefined || filters2 === null || filters2 === undefined) return true;
    return ((filters1.eventId === undefined && filters2.eventId === undefined) || filters1.eventId === filters2.eventId)
        && ((filters1.date === undefined && filters2.date === undefined) || filters1.date === filters2.date)
        && ((filters1.startTime === undefined && filters2.startTime === undefined) || filters1.startTime === filters2.startTime)
        && ((filters1.endTime === undefined && filters2.endTime === undefined) || filters1.endTime === filters2.endTime)
        && ((filters1.repeating === undefined && filters2.repeating === undefined) || filters1.repeating === filters2.repeating)
        && ((filters1.repeatFrequency === undefined && filters2.repeatFrequency === undefined) || filters1.repeatFrequency === filters2.repeatFrequency)
        && ((filters1.repeatEndDate === undefined && filters2.repeatEndDate === undefined) || filters1.repeatEndDate === filters2.repeatEndDate)
        && ((filters1.repeatsNumber === undefined && filters2.repeatsNumber === undefined) || filters1.repeatsNumber === filters2.repeatsNumber);
}

const areListEquals = (list1: any, list2: any) => {
    if (list1 === null && list2 === null) return true;
    if (list2 === null || list1 === null) return false;
    if (list1.length !== list2.length) return false;
    for (let i = 0; i < list1.length; i += 1) {
        if (list1[i].id !== list2[i].id) {
            return false;
        }
    }
    return true;
}

const classroomsDifferent = (c1: any, c2: any) => {
    if (c1 === undefined && c2 === undefined) return false;
    if (c1 === undefined || c2 === undefined) return true;
    if (c1 === null && c2 === null) return false;
    if (c1 === null || c2 === null) return true;
    return c1.id !== c2.id || c1.name !== c2.name;
}

export default React.memo(CustomAutocomplete, ((prevProps: any, nextProps: any) => {
    //zwraca true jeśli są takie same
    if (classroomsDifferent(prevProps.classroom, nextProps.classroom)
        || classroomsDifferent(prevProps.classroom2, nextProps.classroom2)
        || classroomsDifferent(prevProps.debriefingRoom, nextProps.debriefingRoom))
        return false;
    return filtersEqual(prevProps.filters, nextProps.filters) && dateFiltersEqual(prevProps.dateFilters, nextProps.dateFilters)
        && (prevProps.disabled === undefined || prevProps.disabled === nextProps.disabled)
        && ((prevProps.multiple !== undefined && nextProps.multiple !== undefined && areListEquals(prevProps.value, nextProps.value))
            || prevProps.value === null && nextProps.value === null
            || (prevProps.value != null && nextProps.value != null && prevProps.value.id === nextProps.value.id));
}));

// export default CustomAutocomplete;