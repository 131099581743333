import * as React from 'react';
import Box from '@mui/material/Box';
import {makeStyles} from "@material-ui/core/styles";
import {FormControlLabel, Stack} from "@mui/material";
import {ColorPicker} from "primereact/colorpicker";
import {InputMask} from "primereact/inputmask";
import {useEffect, useState} from "react";
import "../../assets/theme/style.css";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        marginTop: "20px",
    },
    selectColor: {
        width: "100%",
        minWidth: "180px",
    },
    pickColor: {
        display: "flex",
        alignItems: "baseline",
    },
    inputMask: {
        width: "85px",
        border: "none !important",
        '&:hover, &:focus, &:active': {
            backgroundColor: "#ffffff",
            border: "1px solid black",
        },
        marginRight: "10px",
    }
}));

interface Props {
    value: any;
    setValue: any;
    label?: string;
    labelWidth?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
}

const CustomColorPicker = ({value, setValue, label = "", labelWidth, labelPlacement="start", spaceAfterLabel="30px"}: Props) => {
    const classes = useStyles();
    const [hexValue,setHexValue] = useState("");
    const [insideValue, setInsideValue] = useState("");

    const updateColor = (color: any) => {
        setHexValue(color);
        if (!color.includes("_")) {
            if (color.match(/^#[a-f0-9]{6}$/i) !== null) {
                setInsideValue(color.substring(1));
                setValue(color);
            } else {
                setInsideValue(value.substring(1));
                setHexValue(value);
            }
        }
    }

    useEffect(() => {
        setHexValue(value);
        if (value !== "") {
            setInsideValue(value.substring(1));
        } else {
            setInsideValue("");
        }
    }, [value])

    return (
        <Box>
            <FormControlLabel
                control={
                    <div className={classes.pickColor}>
                        <InputMask mask="#******" value={hexValue} onChange={(e) => updateColor(e.value)}
                                   className={classes.inputMask} placeholder={"#ffffff"} id={"color-picker-input"}/>
                        <ColorPicker value={insideValue} onChange={(e) => {
                                        setInsideValue(e.value);
                                        setValue("#"+e.value);
                                        setHexValue("#"+e.value);
                                     }}
                                     format={"hex"}
                                     className={classes.selectColor}/>
                    </div>
                }
                label={labelWidth === undefined ? label : <div style={{width: labelWidth}}>{label}</div>}
                labelPlacement={labelPlacement}
                className={classes.container}
                sx={{gap: spaceAfterLabel, alignItems: "baseline", cursor: "initial"}}
            />
        </Box>
    );
}

export default CustomColorPicker;