import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {TablePagination} from "@mui/material";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles({
    root: {
        border: 0,
    },
});

interface Props {
    page: number,
    setPage: any,
    rowsPerPage: number,
    setRowsPerPage: any,
    totalRecords: number,
}

const CustomPagination = ({page, setPage, rowsPerPage, setRowsPerPage, totalRecords = 0} : Props) => {

    const classes = useStyles();
    const {t} = useTranslation();

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    return (
        <TablePagination
            className={classes.root}
            rowsPerPageOptions={[5, 10, 20, 50]}
            page={page}
            count={totalRecords}
            rowsPerPage={rowsPerPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={''}
            labelDisplayedRows={({from, to, count}) => {return from + "-" + to + " " + t('pagination.of') +" " + count}}
            SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
            }}
        />
    );
}

export default CustomPagination;