export const Colors = {
    WHITE: '#fff',
    BLACK: '#000',
    ALMOST_BLACK: '#313131',
    DARK_TURQUOISE: '#178DAA',
    TURQUOISE: '#18ABB5',
    PASTEL_TURQUOISE: '#daf6f6',
    LIGHT_TURQUOISE: '#c5f1f1',
    LIGHT_BLUE: '#64d1ef',
    DARKER_BLUE: '#599aea',
    BLUE_TURQUOISE: '#15B8D4',
    PRIMARY_GREEN_LIGHT: '#008a3f',
    PRIMARY_GREEN: '#007a37',
    DARK_GREEN: '#014620',
    LIGHT_GREEN: '#90b790',
    LIGHT_BROWN: '#ab9424',
    VERY_LIGHT_GREY: '#f7faf9',
    LIGHTER_GREY: '#dee2e1',
    LIGHT_GREY: '#BABEBD',
    MEDIUM_GREY: '#808080',
    GREY: '#616161',
    RED: '#E1231D',
    LIGHT_RED: '#ee6e6a',
    BEIGE: '#fcdca4',
}

export const exampleColors = [
    {
        label: "niebieski",
        value: "#00a1c9"
    },
    {
        label: "turkusowy",
        value: "#00c9b6"
    },
    {
        label: "morski",
        value: "#00877a"
    },
    {
        label: "ciemnozielony",
        value: "#00662e"
    },
    {
        label: "zielony",
        value: "#00ad4e"
    },
    {
        label: "jasnozielony",
        value: "#a2ff7a"
    },
    {
        label: "żółty",
        value: "#f8ff7a"
    },
    {
        label: "ciemnożółty",
        value: "#ffdd00"
    },
    {
        label: "jasnobrązowy",
        value: "#ba7000"
    },
    {
        label: "brązowy",
        value: "#964b00"
    },
    {
        label: "ciemnobrązowy",
        value: "#6e4200"
    },
    {
        label: "czarny",
        value: "#000000"
    },
    {
        label: "biały",
        value: "#ffffff"
    },
];

export const getColorLabel = (hexColor: string) => {
    let label = hexColor
    // exampleColors.forEach((item) => {
    //     if (item.value === hexColor) {
    //         label = item.label
    //     }
    // })
    return label
}

