/* eslint-disable */
import axios from 'axios'

export const FileUtils = {

    forceFileDownload(response, fileName) {
        this.forceBlobDownload(new Blob([response.data]), fileName);
    },

    forceSvgDownload(svg, fileName) {
        this.forceBlobDownload(new Blob([svg.toString()]), fileName);
    },

    forceBlobDownload(blob, fileName) {
        const url = window.URL.createObjectURL(blob);
        this.forceUrlDownload(url, fileName);
    },

    forceUrlDownload(url, fileName) {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },

    downloadFile(query, fileName, url) {
        return axios({
            url: process.env.REACT_APP_API_URL + url,
            method: 'get',
            responseType: 'blob',
        }).then((response) => {
            this.forceFileDownload(response, fileName);
            return true;
        }).catch(() => {
            return false;
        });
    },
};
