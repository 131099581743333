import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    acceptEventRequestUsingPOST as acceptEventRequest,
    commentEventRequestUsingPOST as commentEventRequest,
    rejectEventRequestUsingPOST as rejectEventRequest
} from "../../swagger/vue-api-client";
import {useHistory} from "react-router-dom";
import ReservationView from "../reservation/ReservationView";
import {Dialog} from "primereact/dialog";
import {toastInfo} from "../../services/InfoService";
import {useTranslation} from "react-i18next";
import CalendarHeader from "./components/CalendarHeader";
import {SystemRole} from "../../utils/SystemRole";
import CalendarDayContent from "./components/CalendarDayContent";

interface Props {
    date: Date;
    setDate: Function;
    publicView: number | false;
}

const CalendarView = ({date, setDate, publicView}: Props) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const history = useHistory();

    const [chosenDate, setChosenDate] = useState(date);
    const [chosenWeekDates, setChosenWeekDates] = useState(new Array<Date>());
    const [statusList, setStatusList] = useState(SystemRole.isAdmin() || SystemRole.isPlanner() ? ["ACCEPTED", "PENDING", "NEGOTIATING"] : ["ACCEPTED"]);
    const [openPreview, setOpenPreview] = useState(false);
    const [previewedEvent, setPreviewedEvent] = useState({});
    const [showCalendar, setShowCalendar] = useState(false);
    const [viewType, setViewType] = useState(0);
    const [onlyMyEvents, setOnlyMyEvents] = useState(false);
    const [showWeek, setShowWeek] = useState(false);

    useEffect(() => {
        /*
        {label: t('calendar.viewAll'), value: 0},
        {label: t('calendar.viewAccepted'), value: 1},
        {label: t('calendar.viewNotRejected'), value: 2},
        {label: t('calendar.viewRejected'), value: 3},
         */

        if (viewType === 0) {
            setStatusList(["ACCEPTED", "PENDING", "NEGOTIATING", "REJECTED"]);
        } else if (viewType === 1) {
            setStatusList(["ACCEPTED"]);
        } else if (viewType === 2) {
            setStatusList(["ACCEPTED", "PENDING", "NEGOTIATING"]);
        } else if (viewType === 3) {
            setStatusList(["REJECTED"]);
        }
    }, [viewType])

    useEffect(() => {
        setChosenWeekDates(getWeekDates())
        // eslint-disable-next-line
    }, [showWeek, chosenDate])

    const goToEditView = (eventId: number) => {
        history.push({pathname: "/edit-reservation/" + eventId});
    }

    const acceptRequest = (eventId: number) => {
        acceptEventRequest({eventId: eventId}).then((res : any) => {
            if (res.data) {
                toastInfo("success", t('calendar.applicationAccepted'));
                setOpenPreview(false);
            } else {
                toastInfo("error", t('errorOccurred'));
            }
        });
    }
    const rejectRequest = (eventId: number) => {
        rejectEventRequest({eventId: eventId}).then((res : any) => {
            if (res.data) {
                toastInfo("success", t('calendar.applicationRejected'));
                setOpenPreview(false);
            } else {
                toastInfo("error", t('errorOccurred'));
            }
        });
    }
    const suggestChangeToRequest = (eventId: number, requestComment: string) => {
        commentEventRequest({eventId: eventId, requestComment: requestComment}).then((res : any) => {
            if (res.data) {
                toastInfo("success", t('calendar.applicationCommented'));
                setOpenPreview(false);
            } else {
                toastInfo("error", t('errorOccurred'));
            }
        });
    }

    const getWeekDates = () => {
        let monday = new Date(chosenDate)
        if (monday.getDay() === 0) {
            monday.setDate(monday.getDate() - 6);
        } else if (monday.getDay() > 1) {
            monday.setDate(monday.getDate() - monday.getDay() + 1)
        }

        let dates = []
        for (let i = 0; i < 5; i++) {
            let newDay = new Date(monday)
            newDay.setDate(newDay.getDate() + i)
            dates.push(newDay)
        }

        return dates
    }

    const getDayName = (day: Date) => {
        const options: any = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return day.toLocaleDateString(i18n.language === "pl" ? "pl-PL" : "en", options);
    }

    return (
        <div className={publicView ? classes.publicCalendar : classes.calendar}>
            <CalendarHeader
                chosenDate={chosenDate}
                setChosenDate={setChosenDate}
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                chosenWeekDates={chosenWeekDates}
                viewType={viewType}
                setViewType={setViewType}
                onlyMyEvents={onlyMyEvents}
                setOnlyMyEvents={setOnlyMyEvents}
                showWeek={showWeek}
                setShowWeek={setShowWeek}
                setFocusDate={setDate}
                publicView={publicView !== false}
            />
            {showWeek ? (
                <>
                    {chosenWeekDates.map((day, index) =>
                        <React.Fragment key={index}>
                            <div className={classes.dayHeader}>{getDayName(day)}</div>
                            <CalendarDayContent statusList={statusList} chosenDate={day} setOpenPreview={setOpenPreview}
                                                setPreviewedEvent={setPreviewedEvent} onlyMyEvents={onlyMyEvents} scrollToTime={date} setScrollToTime={setDate}
                                                publicView={publicView}/>
                        </React.Fragment>
                    )}
                </>
            ) : (
                <CalendarDayContent statusList={statusList} chosenDate={chosenDate} setOpenPreview={setOpenPreview}
                                    setPreviewedEvent={setPreviewedEvent} onlyMyEvents={onlyMyEvents} scrollToTime={date} setScrollToTime={setDate}
                                    publicView={publicView}/>
            )}
            <Dialog header={t('calendar.preview.title')} visible={openPreview} style={{width: '55vw'}}
                    onHide={() => setOpenPreview(false)}>
                <ReservationView event={previewedEvent} decisionView={true} acceptOnClick={acceptRequest}
                                 editOnClick={goToEditView} rejectOnClick={rejectRequest} commentOnClick={suggestChangeToRequest}/>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    calendar: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },
    publicCalendar: {
        padding: "0px 40px",
        paddingBottom: 0,
        marginBottom: 0,
        display: "inline",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
        marginTop: 20,
        overflowY: "hidden",
    },

    dayHeader: {
        marginBottom: 15,
        fontSize: "1.1rem",
        fontWeight: "bold",
    },
}));

export default CalendarView;

