import React from 'react';
import {Link, useHistory,} from "react-router-dom";
import {makeStyles, Theme} from "@material-ui/core/styles";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {useTranslation} from "react-i18next";
import {Stack} from "@mui/material";
import {CgLogOut} from "react-icons/all";
import {CgLogIn} from "react-icons/all";
import styled from "styled-components";
import {Colors} from "../../constants/colors";
import {toastInfo} from "../../services/InfoService";
import polishIcon from "../../assets/polish-icon.png";
import englishIcon from "../../assets/english-icon.png";
import umb_white from "../../assets/logo_umb_biale.png";
import {SystemRole} from "../../utils/SystemRole";

interface Props {
    activeTab: number;
    setActiveTab: any;
    updateRequestCounts: any;
    requestCounts: any;
    myRequestCounts: any;
    openMenu: boolean;
    setOpenMenu: any;
    setPublicView: any;
}

interface StyleProps {
    showMenu: boolean;
    smallScreen: boolean;
}


function LeftMenu({activeTab, setActiveTab, updateRequestCounts, myRequestCounts, requestCounts, openMenu, setOpenMenu, setPublicView}: Props) {

    const styleProps: StyleProps = {showMenu: openMenu, smallScreen: window.innerWidth < 960};
    const classes = useStyles(styleProps);
    const history = useHistory();
    const {t, i18n} = useTranslation();

    const getEventCountString = (count : number) => {
        if (count === 0)
            return "";
        return " (" + count + ")";
    }

    const onLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        localStorage.removeItem("fullName");
        localStorage.removeItem("userId");
        history.push({pathname: "/login", state: {}});
        window.location.reload(); // FIXME: usunąć jak naprawimy panel po lewej
        toastInfo("success", t('managementPanel.loggedOut'));
    }

    const onLogin = () => {
        history.push({pathname: "/login", state: {}});
        window.location.reload(); // FIXME: usunąć jak naprawimy panel po lewej
    }

    const onCalendarClick = (tabNumber: number) => {
        if (SystemRole.isAuthorized()) {
            changeActiveTab(tabNumber)
        } else {
            localStorage.setItem("publicView", String(8));
            setPublicView(8);
            history.push("/");
        }
    }

    const changeActiveTab = (tabNumber: number) => {
        setActiveTab(tabNumber);
        setOpenMenu(false);
    }

    return (
        <div className={classes.leftMenuContainer}>
            <div>
            <div className={classes.userName}>{localStorage.getItem("fullName")}</div>
            <div className={classes.menuOptions}>
                <div className={classes.eventTextEvent}>
                    <StyledLink to={"/home"} onClick={() => onCalendarClick(0)}>
                        {t('managementPanel.calendar')}
                    </StyledLink>
                </div>
                    <div className={classes.horizontalLine}/>
                { (SystemRole.isAuthorized()) &&
                <div>
                    <div className={classes.eventTextEvent}>
                        <StyledLink to={"/my-reservations"} onClick={() => changeActiveTab(1)}>
                            {t('managementPanel.myReservations.title')}
                        </StyledLink>
                    </div>
                    {activeTab === 1 && <Stack onClick={() => updateRequestCounts()} direction={"column"} spacing={2} style={{fontSize: "initial"}}>
                        <Link onClick={() => changeActiveTab(1)} to={"/my-reservations"} style={{paddingTop: "15px"}} className={classes.subLink}>{t('managementPanel.reservationsStatus.all')}</Link>
                        <Link onClick={() => changeActiveTab(1)} to={"/my-reservations/ACCEPTED"} className={classes.subLink}>{t('managementPanel.reservationsStatus.accepted')} {getEventCountString(myRequestCounts.accepted)}</Link>
                        <Link onClick={() => changeActiveTab(1)} to={"/my-reservations/REJECTED"} className={classes.subLink}>{t('managementPanel.reservationsStatus.rejected')} {getEventCountString(myRequestCounts.rejected)}</Link>
                        <Link onClick={() => changeActiveTab(1)} to={"/my-reservations/PENDING"} className={classes.subLink}>{t('managementPanel.reservationsStatus.pending')} {getEventCountString(myRequestCounts.pending)}</Link>
                        <Link onClick={() => changeActiveTab(1)} to={"/my-reservations/NEGOTIATING"} className={classes.subLink}>{t('managementPanel.reservationsStatus.negotiating')} {getEventCountString(myRequestCounts.negotiating)}</Link>
                    </Stack>}
                    <div className={classes.horizontalLine}/>
                </div> }
                { (SystemRole.isAdminOrPlanner()) &&
                <div>
                    <div className={classes.eventTextEvent}>
                        <StyledLink to={"/manage-reservations"} onClick={() => changeActiveTab(2)}>
                            {t('managementPanel.manageReservations.title')}
                        </StyledLink>
                    </div>
                    {activeTab === 2 && <Stack onClick={() => updateRequestCounts()} direction={"column"} spacing={2} style={{fontSize: "initial"}}>
                        <Link onClick={() => changeActiveTab(2)} to={"/manage-reservations"} style={{paddingTop: "15px"}} className={classes.subLink}>{t('managementPanel.reservationsStatus.all')}</Link>
                        <Link onClick={() => changeActiveTab(2)} to={"/manage-reservations/ACCEPTED"} className={classes.subLink}>{t('managementPanel.reservationsStatus.accepted')} {getEventCountString(requestCounts.accepted)}</Link>
                        <Link onClick={() => changeActiveTab(2)} to={"/manage-reservations/REJECTED"} className={classes.subLink}>{t('managementPanel.reservationsStatus.rejected')} {getEventCountString(requestCounts.rejected)}</Link>
                        <Link onClick={() => changeActiveTab(2)} to={"/manage-reservations/PENDING"} className={classes.subLink}>{t('managementPanel.reservationsStatus.pending')} {getEventCountString(requestCounts.pending)}</Link>
                        <Link onClick={() => changeActiveTab(2)} to={"/manage-reservations/NEGOTIATING"} className={classes.subLink}>{t('managementPanel.reservationsStatus.negotiating')} {getEventCountString(requestCounts.negotiating)}</Link>
                    </Stack>}
                    <div className={classes.horizontalLine}/>
                </div> }

                { (SystemRole.isAuthorized()) &&
                <div>
                    <div className={classes.eventTextEvent}>
                        <StyledLink to={"/restricted-dates"} onClick={() => changeActiveTab(3)}>{t('managementPanel.restrictedDates')}</StyledLink>
                    </div>
                    <div className={classes.horizontalLine}/>
                </div> }
                { (localStorage.getItem("role") === "ADMIN") &&
                <div>
                    <div className={classes.eventTextEvent}>
                        <StyledLink to={"/users"} onClick={() => changeActiveTab(4)}>{t('managementPanel.users')}</StyledLink>
                    </div>
                    <div className={classes.horizontalLine}/>
                    <div className={classes.eventTextEvent}>
                        <StyledLink to={"/dictionaries"} onClick={() => changeActiveTab(5)}>
                            {t('managementPanel.manageDictionaries.title')}
                        </StyledLink>
                    </div>
                    {activeTab === 5 && <Stack direction={"column"} spacing={2} style={{fontSize: "initial"}}>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/TEACHER"} style={{paddingTop: "15px"}} className={classes.subLink}>{t('managementPanel.manageDictionaries.teacher')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/CLASSROOM"} className={classes.subLink}>{t('managementPanel.manageDictionaries.classroom')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/DEPARTMENT"} className={classes.subLink}>{t('managementPanel.manageDictionaries.department')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/DEGREE_COURSE"} className={classes.subLink}>{t('managementPanel.manageDictionaries.degreeCourse')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/SUBJECT"} className={classes.subLink}>{t('managementPanel.manageDictionaries.subject')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/EQUIPMENT"} className={classes.subLink}>{t('managementPanel.manageDictionaries.equipment')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/EVENT_TYPE"} className={classes.subLink}>{t('managementPanel.manageDictionaries.eventType')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/STUDENT_GROUP"} className={classes.subLink}>{t('managementPanel.manageDictionaries.studentGroup')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/TECHNICIAN"} className={classes.subLink}>{t('managementPanel.manageDictionaries.technician')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/CATEGORY"} className={classes.subLink}>{t('managementPanel.manageDictionaries.category')}</Link>
                        <Link onClick={() => changeActiveTab(5)} to={"/dictionary/PUBLIC_CATEGORY"} className={classes.subLink}>{t('managementPanel.manageDictionaries.publicCategory')}</Link>
                    </Stack>}
                    <div className={classes.horizontalLine}/>
                    <div className={classes.eventTextEvent} onClick={() => changeActiveTab(6)}>
                        <StyledLink to={"/reports"}>
                            {t('managementPanel.reports')}
                        </StyledLink>
                    </div>
                    <div className={classes.horizontalLine}/>
                    <div className={classes.eventTextEvent} onClick={() => changeActiveTab(7)}>
                        <StyledLink to={"/system"}>
                            {t('managementPanel.system')}
                        </StyledLink>
                    </div>
                </div> }
                <div className={classes.bottomMenu}>
                    { (SystemRole.isAuthorized()) ?
                        (<div className={classes.bottomMenuGroup} style={{marginBottom: -20}}>
                            <CgLogOut className={classes.bottomMenuIcon}/>
                            <div className={classes.bottomMenuText} onClick={() => onLogout()}>{t('managementPanel.logout')}</div>
                        </div> ) :
                        (<div className={classes.bottomMenuGroup} style={{marginBottom: -20}}>
                            <div className={classes.bottomMenuText} onClick={() => onLogin()}>{t('managementPanel.login')}</div>
                            <CgLogIn className={classes.bottomMenuIcon}/>
                        </div> )}
                    <div className={classes.languageOptions}>
                        <img src={polishIcon} alt={t('managementPanel.polishLanguage')}
                             className={classes.languageOption} onClick={() => i18n.changeLanguage("pl")}/>
                        <img src={englishIcon} alt={t('managementPanel.englishLanguage')}
                             className={classes.languageOption} onClick={() => i18n.changeLanguage("en")}/>
                    </div>
                </div>
                <div>
                    <img src={umb_white} className={classes.logo}/>
                </div>
            </div>
            </div>
        </div>
    );
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    leftMenuContainer: props => ({
        backgroundColor: Colors.LIGHT_GREEN,
        transition: "0.5s",
        alignItems: "center",
        textAlign: "center",
        fontSize: 23,
        fontWeight: "bold",
        padding: props.smallScreen && !props.showMenu ? 0 : "30px 0",
        height: props.smallScreen && !props.showMenu ? 0 : "100%",
        overflow: "hidden",
    }),

    userName: {
        color: Colors.DARK_GREEN,
        textTransform: "uppercase",
    },

    menuOptions: {
        fontSize: 18,
        paddingTop: 60,
    },

    column: {
        float: "left",
        padding: "10px",
    },

    horizontalLine: {
        borderTop: "1px ridge",
        borderColor: Colors.GREY,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 15,
        marginBottom: 15,
    },

    eventTextEvent: {
        cursor: "pointer",
        transition: "all 0.2s",
        "&:hover": {
            color: Colors.VERY_LIGHT_GREY,
        },
    },

    bottomMenu: {
        marginTop: 80,
    },

    bottomMenuGroup: {
        margin: "15px 0",
        textTransform: "uppercase",
        color: Colors.DARK_GREEN,
        height: '50px',
        bottom: 0,
        width: '100%',
        display: "flex",
        justifyContent: "center",
        cursor: "pointer",
        transition: "all 0.2s",
        "&:hover": {
            color: Colors.VERY_LIGHT_GREY,
        },
    },

    bottomMenuIcon: {
        fontSize: '30px',
        float: "left",
    },

    bottomMenuText: {
        marginTop: "5px",
        marginLeft: "5px",
        "&:hover": {
            color: Colors.VERY_LIGHT_GREY,
            cursor: "pointer",
        },
        float: "left",
    },

    subLink: {
        fontWeight: "normal",
        textDecoration: "none",
        color: "inherit",
        transition: "all 0.2s",
        "&:hover": {
            color: Colors.VERY_LIGHT_GREY,
        },
    },

    languageOptions: {
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: 30,
        [theme.breakpoints.down('sm')]: {
            display: "flex",
            marginTop: 10,
            marginBottom: -10,
        },
    },

    languageOption: {
        height: 35,
        cursor: "pointer",
        margin: 10,
    },

    logo: {
        position: "relative",
        top: 20,
    },
}));

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:hover, &:active, &:visited {
    text-decoration: none;
    color: inherit;
  }
`;


export default LeftMenu;
