import React from 'react';
import {Box} from "@material-ui/core";
import {FormControlLabel, TextField} from "@mui/material";

interface Props {
    value: string,
    setValue: any,
    label?: string,
    disabled?: boolean,
}

const CustomInputTextarea = ({value, setValue, label="", disabled = false} : Props) => {

    return (
        <Box>
            <FormControlLabel
                label={label}
                control={
                    <TextField
                        multiline
                        minRows={4}
                        value={value}
                        onChange={(event) => setValue(event.target.value)}
                        disabled={disabled}
                        fullWidth={true}
                    />
                }
                labelPlacement="top"
                sx={{alignItems: "flex-start", width: "95%", cursor: "initial"}}
            />
        </Box>
    );
}

export default CustomInputTextarea;
