import * as React from 'react';
import {useEffect, useState} from 'react';
import {Box, FormControlLabel} from "@mui/material";
import {makeStyles} from '@material-ui/core/styles';
import {Calendar} from "primereact/calendar";

interface Props {
    value: Date | undefined;
    initialDate: Date | undefined;
    setValue: any;
    label?: string;
    disabled?: boolean;
    width?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
}

const useStyles = makeStyles(() => ({
    selectTime: {
        width: "260px",
    },
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
}));

const SelectEventTimeStart = ({value, setValue, label = "", disabled = false, initialDate = undefined, width = "200px",
                              labelPlacement = "start", spaceAfterLabel = "30px"}: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    if (label === "")
        spaceAfterLabel = "0px";
    const {selectTime, container} = useStyles();

    const checkAndSetStartTime = (e: any) => {
        const v = e.value;
        const hours = v.getHours();
        const minutes = v.getMinutes();
        if (hours < 8 || hours >= 20 || (hours == 19 && minutes > 15)) {
            if (hours < 8) {
                v.setHours(8,0,0,0).toString();
            } else {
                v.setHours(19,15,0,0).toString();
            }
        }
        setValue(v);
    }

    useEffect(() => {
        if (!initialDate) {
            // eslint-disable-next-line
            initialDate = new Date();
            initialDate.setHours(10, 30, 0, 0).toString();
        }
        setValue(initialDate);
        setIsLoading(false);
    }, []);


    return (
        <Box>
            {!isLoading && <FormControlLabel
                label={<div style={{width: width}}>{label}</div>}
                control={
                    <Calendar showTime timeOnly hourFormat="24" value={value} stepMinute={15}
                              onChange={(e) => checkAndSetStartTime(e)}
                              className={selectTime} readOnlyInput disabled={disabled}/>
                }
                labelPlacement={labelPlacement}
                className={container}
                sx={{gap: spaceAfterLabel, cursor: "initial"}}
            />}
        </Box>
    );
}

export default SelectEventTimeStart;