import React, {useState} from 'react';
import {TabPanel, TabView} from "primereact/tabview";
import {constDictType, findEnumValue} from "../../constants/dictType";
import CustomSelectOne from "../../components/form/CustomSelectOne";
import CustomCalendar from "../../components/form/CustomCalendar";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import CustomDayPicker from "../../components/form/CustomDayPicker";
import {
    eventToCsvUsingGET as getEventsCsv,
    getDictionariesUsingPOST as getDictionaries,
    getReportUsingPOST as getReports,
} from "../../swagger/vue-api-client";
import CustomAutocomplete from "../../components/form/CustomAutocomplete";
import {ActionToolbar} from "../../utils/styled";
import ReportsEventTable from "./components/ReportsEventTable";
import startOfWeek from "date-fns/startOfWeek";
import {endOfMonth} from "date-fns";
import {toastInfo} from "../../services/InfoService";
import {FileUtils} from "../../utils/FileUtils";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import ReportsButtons from "./components/ReportsButtons";

const selectPeriodTime = [
    {
        value: 0,
        label: "dzień",
    },
    {
        value: 1,
        label: "tydzień",
    },
    {
        value: 2,
        label: "miesiąc",
    },
    {
        value: 3,
        label: "od-do",
    }
]

const ReportsView = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [activeIndexTime, setActiveIndexTime] = useState(0);
    const [activeIndexAll, setActiveIndexAll] = useState(0);
    const [periodType, setPeriodType] = useState(0);
    const [monthDate, setMonthDate] = useState<Date>();
    const [fromDate, setFromDate] = useState<Date>();
    const [toDate, setToDate] = useState<Date>();
    const [weekDate, setWeekDate] = useState<Date>();
    const [dayDate, setDayDate] = useState<Date>();
    const [subject, setSubject] = useState<any>(null);
    const [classroom, setClassroom] = useState<any>(null);
    const [teacher, setTeacher] = useState<any>(null);
    const [eventType, setEventType] = useState<any>(null);
    const [studentGroup, setStudentGroup] = useState<any>(null);
    const [showReport, setShowReport] = useState(false);
    const [reportData, setReportData] = useState([]);
    const [numberOfHours, setNumberOfHours] = useState(0);
    const [showStudentCount, setShowStudentCount] = useState(false);
    const [numberOfStudents, setNumberOfStudents] = useState(0);
    const [technician, setTechnician] = useState<any>(null);
    const [showAcceptanceTime, setShowAcceptanceTime] = useState(false);
    const [reportQuery, setReportQuery] = useState({});
    const {t} = useTranslation();
    const classes = useStyles();

    const showCalendarType = () => {
        if (periodType === 0) {
            return <CustomCalendar value={dayDate} setValue={setDayDate} label={t('reports.dayDate')} showWeek={false}/>
        } else if (periodType === 1) {
            return <CustomDayPicker value={weekDate} setValue={setWeekDate} label={t('reports.weekDate')}/>
        } else if (periodType === 2) {
            return <CustomCalendar value={monthDate} setValue={setMonthDate} label={t('reports.monthDate')} month={true}
                                   showWeek={true}/>
        } else if (periodType === 3) {
            return (
                <div>
                    <CustomCalendar value={fromDate} setValue={setFromDate} label={t('reports.fromDate')}/>
                    < br/>
                    <CustomCalendar value={toDate} setValue={setToDate} label={t('reports.toDate')}/>
                </div>
            )
        }
    }

    const generateReport = (dict: constDictType, id: any) => {
        let startDate = null, endDate = null;

        if (id === null || id.id === null) {
            toastInfo("error", t('reports.noSelectedElementInfo'));
            return;
        }

        let idList = [];

        for (let i = 0; i < id.length; i += 1) {
            idList.push(id[i].id);
        }

        const reportQueryDto = {
            dictType: dict === constDictType.NULL ? null : dict,
            idList: idList,
            start: new Date(),
            end: new Date(),
        }

        if (periodType === 0) { //dzień
            if (dayDate !== undefined && dayDate !== null) {
                startDate = new Date(dayDate.getTime())
                endDate = new Date(dayDate.getTime());
            }

        } else if (periodType === 1) {
            let weekDateLocal = weekDate;

            if (weekDateLocal === undefined) {
                weekDateLocal = new Date();
            }

            let start = new Date(startOfWeek(weekDateLocal));
            let end = new Date(startOfWeek(weekDateLocal));

            if (weekDateLocal.getDay() !== startOfWeek(weekDateLocal).getDay()) {
                start.setDate(start.getDate() + 1);
                end.setDate(end.getDate() + 7);
            } else {
                start.setDate(start.getDate() - 6);
                end.setDate(end.getDate());
            }

            startDate = start;
            endDate = end;
        } else if (periodType === 2) {
            if (monthDate !== undefined) {
                startDate = monthDate;
                endDate = endOfMonth(monthDate);
            }
        } else if (periodType === 3) {
            if (fromDate !== undefined && toDate !== undefined) {
                startDate = fromDate;
                endDate = toDate;
            }
        }

        if (startDate !== undefined && endDate !== undefined && startDate !== null && endDate !== null) {
            endDate.setHours(23);
            endDate.setMinutes(59);
            startDate.setHours(2);

            reportQueryDto.start = startDate;
            reportQueryDto.end = endDate;
        } else {
            if (dict !== constDictType.NULL) {
                toastInfo("error", t('reports.noSelectedDateInfo'))
            }
        }

        setReportQuery(reportQueryDto);

        getReports({reportQueryDto: reportQueryDto}).then((response: any) => {
            if (dict === constDictType.STUDENT_GROUP && id[0].id === -1) {
                let sum: number = 0;

                for (let i = 0; i < response.data.length; i += 1) {
                    sum += response.data[i].studentGroupCount;
                }

                setNumberOfStudents(sum);
                setShowStudentCount(true);
            } else if (dict === constDictType.NULL) {
                response.data = getTimeDifference(response.data);
                setShowAcceptanceTime(true);
            } else {
                let sum: number = 0;

                for (let i = 0; i < response.data.length; i += 1) {
                    sum += response.data[i].numberOfHours;
                }

                setNumberOfHours(sum);
                setShowReport(true);
            }
            setReportData(response.data);
        })
    }

    const getTimeDifference = (data: any[]) => {
        for (let i = 0; i < data.length; i += 1) {
            if (data[i].dateOfFirstAcceptedOrRejected == null) {
                data[i].creationDate = t('reports.notConsidered');
            } else {
                let difference = Math.abs(Date.parse(data[i].creationDate) - Date.parse(data[i].dateOfFirstAcceptedOrRejected));
                let days = Math.floor(difference / (1000 * 60 * 60 * 24));
                let hours = Math.floor(difference / (1000 * 60 * 60)) % 24;
                let minutes = Math.floor(difference / (1000 * 60)) % 60;

                data[i].creationDate = "";

                if (days !== 0) {
                    data[i].creationDate = days + (days === 1 ? t('reports.singleDayLabel') : t('reports.manyDaysLabel'));
                }

                if (hours !== 0) {
                    data[i].creationDate += hours + " h ";
                }

                data[i].creationDate += minutes + " min";
            }
        }

        return data;
    }

    return (
        <div className={classes.reports}>
            <div className={classes.header}><h2>{t('reports.title')}</h2></div>
            <div className={classes.content}>
                <TabView activeIndex={activeIndex} onTabChange={(e) => {
                    setShowStudentCount(false);
                    setShowReport(false);
                    setActiveIndex(e.index);
                    setShowAcceptanceTime(false);
                }}>
                    <TabPanel header={t('reports.temporal')}>
                        <CustomSelectOne items={selectPeriodTime} value={periodType} setValue={setPeriodType}
                                         label={t('reports.selectPeriodTime')}/>
                        < br/>
                        {showCalendarType()}

                        < br/>
                        <TabView activeIndex={activeIndexTime} onTabChange={(e) => {
                            setShowStudentCount(false);
                            setShowReport(false);
                            setActiveIndexTime(e.index);
                        }}>
                            <TabPanel header={t('reports.subject')}>
                                <CustomAutocomplete
                                    value={subject}
                                    setValue={setSubject}
                                    completeMethod={getDictionaries}
                                    multiple={true}
                                    filters={{
                                        dictType: findEnumValue("SUBJECT"),
                                    }}
                                    label={t('reports.subject')}
                                />
                                <ReportsButtons dictType={constDictType.SUBJECT} value={subject} reportQuery={reportQuery}
                                                generateReport={generateReport} showReport={showReport}/>
                            </TabPanel>
                            <TabPanel header={t('reports.classroom')}>
                                <CustomAutocomplete
                                    value={classroom}
                                    setValue={setClassroom}
                                    completeMethod={getDictionaries}
                                    multiple={true}
                                    filters={{
                                        dictType: findEnumValue("CLASSROOM"),
                                    }}
                                    label={t('reports.classroom')}
                                />
                                <ReportsButtons dictType={constDictType.CLASSROOM} value={classroom} reportQuery={reportQuery}
                                                generateReport={generateReport} showReport={showReport}/>
                            </TabPanel>
                            <TabPanel header={t('reports.teacher')}>
                                <CustomAutocomplete
                                    value={teacher}
                                    setValue={setTeacher}
                                    completeMethod={getDictionaries}
                                    multiple={true}
                                    filters={{
                                        dictType: findEnumValue("TEACHER"),
                                    }}
                                    label={t('reports.teacher')}
                                />
                                <ReportsButtons dictType={constDictType.TEACHER} value={teacher} reportQuery={reportQuery}
                                                generateReport={generateReport} showReport={showReport}/>
                            </TabPanel>
                            <TabPanel header={t('reports.eventType')}>
                                <CustomAutocomplete
                                    value={eventType}
                                    setValue={setEventType}
                                    completeMethod={getDictionaries}
                                    multiple={true}
                                    filters={{
                                        dictType: findEnumValue("EVENT_TYPE"),
                                    }}
                                    label={t('reports.eventType')}
                                />
                                <ReportsButtons dictType={constDictType.EVENT_TYPE} value={eventType} reportQuery={reportQuery}
                                                generateReport={generateReport} showReport={showReport}/>
                            </TabPanel>
                            <TabPanel header={t('reports.studentGroup')}>
                                <CustomAutocomplete
                                    value={studentGroup}
                                    setValue={setStudentGroup}
                                    completeMethod={getDictionaries}
                                    multiple={true}
                                    filters={{
                                        dictType: findEnumValue("STUDENT_GROUP"),
                                    }}
                                    label={t('reports.studentGroup')}
                                />
                                <ReportsButtons dictType={constDictType.STUDENT_GROUP} value={studentGroup}
                                                reportQuery={reportQuery} generateReport={generateReport} showReport={showReport}/>
                            </TabPanel>
                            <TabPanel header={t('reports.technician')}>
                                <CustomAutocomplete
                                    value={technician}
                                    setValue={setTechnician}
                                    completeMethod={getDictionaries}
                                    multiple={true}
                                    filters={{
                                        dictType: findEnumValue("TECHNICIAN"),
                                    }}
                                    label={t('reports.technician')}
                                />
                                <ReportsButtons dictType={constDictType.TECHNICIAN} value={technician} reportQuery={reportQuery}
                                                generateReport={generateReport} showReport={showReport}/>
                            </TabPanel>
                            <TabPanel header={t('reports.numberOfStudents')}>
                                <ReportsButtons dictType={constDictType.STUDENT_GROUP} value={[{id: -1}]} reportQuery={reportQuery}
                                                generateReport={generateReport} showReport={showReport}/>
                            </TabPanel>
                        </TabView>
                        {showStudentCount && <p style={{fontSize: 24}}>{t('reports.numberOfStudents')} <b>{numberOfStudents}</b></p>}
                        {showReport && <p style={{fontSize: 24}}>{t('reports.numberOfHours') + ":"} <b>{numberOfHours}</b></p>}
                        {showReport && <ReportsEventTable events={reportData}/>}
                        {showStudentCount && <ReportsEventTable events={reportData} countStudents={1}/>}
                    </TabPanel>
                    <TabPanel header={t('reports.comprehensive')}>
                        <TabView activeIndex={activeIndexAll} onTabChange={(e) =>
                        {
                            setActiveIndexAll(e.index);
                            setShowAcceptanceTime(false);
                        }}>
                            <TabPanel header={t('reports.statusesCsvXlsx')}>
                                <CustomGenerateReport
                                    onClick={() => {
                                        getEventsCsv({})
                                            .then((response: any) => {
                                                FileUtils.forceFileDownload(response, t('reports.filenameCsv'));
                                            })
                                    }} text={t('reports.downloadCsv')}/>
                                <CustomGenerateReport
                                    onClick={() => {
                                        FileUtils.downloadFile(
                                            null,
                                            t('reports.filenameXlsx'),
                                            "/event/xlsx",
                                        );
                                    }} text={t('reports.downloadXlsx')}/>
                            </TabPanel>
                            <TabPanel header={t('reports.timeOfBooking')}>
                                <CustomGenerateReport
                                    onClick={() => generateReport(constDictType.NULL, {value: -1})}/>
                                {showAcceptanceTime && <ReportsEventTable events={reportData} countStudents={2}/>}
                            </TabPanel>
                        </TabView>
                    </TabPanel>
                </TabView>
            </div>
        </div>
    )
}

interface GenerateReportInterface {
    onClick: any,
    text?: string,
}

const CustomGenerateReport = ({onClick, text}: GenerateReportInterface) => {
    const {t} = useTranslation();
    if (text === undefined) {
        text = t('reports.generate');
    }

    return (
        <ActionToolbar>
            <CustomTextButton width={"160px"} height={"30px"} backgroundColor={Colors.LIGHT_GREEN}
                              onClick={onClick}
                              text={text}
            />
        </ActionToolbar>
    )
}

const useStyles = makeStyles(theme => ({
    reports: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    content: {
        maxWidth: "100%",
    }
}));

export default ReportsView;