import React, {useEffect} from 'react'
import {confirmEmailUsingPOST} from "../swagger/vue-api-client";
import {useTranslation} from "react-i18next";

const ConfirmEmail = () => {
    const {t} = useTranslation();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const confirmEmailToken = urlParams.get('confirmEmailToken');

    useEffect(() => {
        confirmEmailUsingPOST({confirmEmailToken}).then(() => {
            console.log("Adres email został potwierdzony");
        });
    });

    return (
        <div>
            <p>{t('confirmEmail.title')}</p>
            <a href="login">{t('confirmEmail.loginButton')}</a>
        </div>
    )
}

export default ConfirmEmail;