import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {ListItemButton, ListItemIcon} from "@mui/material";
import {BsCircleFill} from "react-icons/all";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(() => ({
    container: {
        padding: "20px 40px",
    },
    subLink: {
        textDecoration: "none",
        color: "inherit",
        fontSize: 20,
        margin: 5
    },
}));

const DictionaryListView = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();

    return (
        <div className={classes.container}>
            <h2>{t('dictionary.listHeader')}</h2>
            <ListItemButton onClick={() => {history.push("/dictionary/TEACHER")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.teacher')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/CLASSROOM")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.classroom')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/DEPARTMENT")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.departmentName')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/DEGREE_COURSE")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.degreeCourseName')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/SUBJECT")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.subjectName')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/EQUIPMENT")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.equipment')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/EVENT_TYPE")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.eventType')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/STUDENT_GROUP")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.studentGroup')}</span>
            </ListItemButton>

            <ListItemButton onClick={() => {history.push("/dictionary/TECHNICIAN")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.technician')}</span>
            </ListItemButton>
            <ListItemButton onClick={() => {history.push("/dictionary/CATEGORY")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.category')}</span>
            </ListItemButton>
            <ListItemButton onClick={() => {history.push("/dictionary/PUBLIC_CATEGORY")}}>
                <ListItemIcon>
                    <BsCircleFill />
                </ListItemIcon>
                <span className={classes.subLink}>{t('dictionary.publicCategory')}</span>
            </ListItemButton>
        </div>
    )
}


export default DictionaryListView;