import React, {useEffect, useState} from 'react';

import {Redirect, Route, Switch, useHistory,} from "react-router-dom";
import {isTokenValidUsingGET,} from "./swagger/vue-api-client";
import HomePage from "./views/HomePage";
import UserListView from "./views/user/UserListView";
import UserView from "./views/user/UserView";
import UserEditView from "./views/user/UserEditView";
import ReservationFormView from "./views/reservation/ReservationFormView";
import DictionaryListView from "./views/dictionary/DictionaryListView";
import DictionaryView from "./views/dictionary/DictionaryView";
import DictionaryInfo from "./views/dictionary/DictionaryInfo";
import DictionaryEditInfo from "./views/dictionary/DictionaryEditInfo";
import MyReservationListView from "./views/reservation/ReservationListView";
import ManageReservationListView from "./views/reservation/ManageReservationListView";
import ReportsView from "./views/reports/ReportsView";
import SystemView from "./views/system/SystemView";
import RestrictedDateListView from "./views/restrictedDate/RestrictedDateListView";
import RestrictedDateFormView from "./views/restrictedDate/RestrictedDateFormView";

interface Props {
    updateRequestCounts: any;
    setPublicView: any;
}

const PrivateRoute = ({updateRequestCounts, setPublicView}: Props) => {
    const history = useHistory();
    const [loaded, setLoaded] = useState<boolean>(false);

    let token = localStorage.getItem("token");

    useEffect(() => {
        if (token === null) {
            history.push("/login");
        } else {
            isTokenValidUsingGET().then(() => {
                setLoaded(true);
            }).catch(() => {
                history.push("/login");
                window.location.reload();
            });
        }
    }, [history, token]);

    if (!loaded) {
        return <></>
    }
    else {
        return (
            <Switch>
                <Route path="/home" component={HomePage} />
                <Route exact path="/" component={HomePage} />
                <Route path="/users" component={UserListView}/>
                <Route path="/restricted-dates/:id" component={RestrictedDateFormView}/>
                <Route path="/restricted-dates" component={RestrictedDateListView}/>
                <Route exact path="/user/:id" component={UserView}/>
                <Route exact path="/user/edit/:id" component={UserEditView}/>
                <Route exact path="/my-reservations" component={MyReservationListView} />
                <Route exact path="/my-reservations/:status" component={MyReservationListView} />
                <Route exact path="/manage-reservations" render={() => (<ManageReservationListView updateRequestCounts={updateRequestCounts}/>)}/>
                <Route exact path="/manage-reservations/:status" render={() => (<ManageReservationListView updateRequestCounts={updateRequestCounts}/>)}/>
                <Route exact path="/new-reservation" render={() => (<ReservationFormView updateRequestCounts={updateRequestCounts}/>)} />
                <Route exact path="/new-reservation/:room/:time" render={() => (<ReservationFormView updateRequestCounts={updateRequestCounts}/>)} />
                <Route exact path="/edit-reservation/:id" render={() => (<ReservationFormView updateRequestCounts={updateRequestCounts}/>)} />
                <Route exact path="/dictionaries" component={DictionaryListView} />
                <Route exact path="/dictionary/:dicTypeString" component={DictionaryView} />
                <Route exact path="/dictionary/:dicTypeString/:id" component={DictionaryInfo} />
                <Route exact path="/dictionary/edit/:dicTypeString/:id" component={DictionaryEditInfo} />
                <Route exact path="/reports" component={ReportsView} />
                <Route exact path="/system" render={() => <SystemView setPublicView={setPublicView}/>}/>
                <Redirect from="*" to="not-found" />
            </Switch>
        )
    }
}

export default PrivateRoute;