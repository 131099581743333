import React, {useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
import {getUserUsingGET} from "../../swagger/vue-api-client";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {AiOutlineHome, AiOutlineSearch, FiMenu} from "react-icons/all";
import csmLogo from "../../assets/logoCSM.png"
import CustomInputSearchText from "../form/CustomInputSearchText";
import ReservationSearchCriteria from "../../views/reservation/components/ReservationSearchCriteria";
import {useTranslation} from "react-i18next";
import englishIcon from "../../assets/english-icon.png";
import polishIcon from "../../assets/polish-icon.png";
import {Dialog} from "primereact/dialog";

interface Props {
    openMenu: boolean;
    setOpenMenu: any;
}

const TopMenu = ({openMenu, setOpenMenu}: Props) => {
    const [searchString, setSearchString] = useState("");
    // eslint-disable-next-line
    const [user, setUser] = useState<any>(null);
    const [showExpandedSearch, setShowExpandedSearch] = useState(false);

    const getClearEventSearchCriteria = () => {
        return {
            search: false,
            searchString: "",
            classroom: "",
            eventType: "",
            faculty: "",
            degreeCourse: "",
            subject: "",
            studentGroup: "",
            assignedTechnician: "",
            teacher: "",
            teacherOther: "",
            postFactumReport: "",
        };
    };
    const [searchCriteria, setSearchCriteria] = useState(getClearEventSearchCriteria()); // todo można by tu za każdym razem nie czyścić bo się ciut niewygodnie używa

    const classes = useStyles();
    const {t, i18n} = useTranslation();
    const history = useHistory();

    useEffect(() => {
        getUserUsingGET({id: localStorage.getItem("userId")}).then((res: any) => {
            setUser(res.data);
        });
    }, []);

    const doSearch = () => {
        if (searchString) {
            const searchCriteriaWithSearchString = searchCriteria;
            searchCriteriaWithSearchString.searchString = searchString;

            history.push({pathname: "/manage-reservations/search-results",
                state: {searchCriteria: searchCriteriaWithSearchString},
                hash: Math.random().toString(36).slice(2)});
        }
        setShowExpandedSearch(false);
    }

    const updateSearchCriteria = (sc : any) => {
        setSearchCriteria(sc);
        setShowExpandedSearch(false);
    }

    useEffect(() => {
        if (searchCriteria.search) {
            const searchCriteriaWithSearchString = searchCriteria;
            searchCriteriaWithSearchString.searchString = searchString;

            history.push({pathname: "/manage-reservations/search-results",
                state: {searchCriteria: searchCriteriaWithSearchString},
                hash: Math.random().toString(36).slice(2)});
        }
        // eslint-disable-next-line
    }, [searchCriteria, searchString]);

    const changeMenuVisibility = () => {
        setOpenMenu(!openMenu);
        window.scrollTo(0,0);
    }

    return (
        <div className={classes.topMenuContainer}>
            <div className={classes.logoContainer}>
                <img className={classes.logo} src={csmLogo} alt={t('managementPanel.logo')}/>
            </div>
            <div className={classes.rightContainer}>
                <AiOutlineHome className={classes.homeIcon} onClick={() => history.push("/home")}/>
                <div onClick={() => setShowExpandedSearch(v => !v)} className={classes.searchContainer}>
                    <div className={classes.searchInput}>
                        <CustomInputSearchText onKeyPress={doSearch} value={searchString} setValue={setSearchString}
                                           label={t('managementPanel.search.placeholder')} width="300px"/>
                    </div>
                    <AiOutlineSearch onClick={doSearch} className={classes.searchIcon}/>
                </div>
                <div className={classes.languageOptions}>
                    <img src={polishIcon} alt={t('managementPanel.polishLanguage')}
                         className={classes.languageOption} onClick={() => i18n.changeLanguage("pl")}/>
                    <img src={englishIcon} alt={t('managementPanel.englishLanguage')}
                         className={classes.languageOption} onClick={() => i18n.changeLanguage("en")}/>
                </div>
                <FiMenu className={classes.menuIcon} onClick={changeMenuVisibility}/>
            </div>
            <Dialog header={t('managementPanel.search.title')} visible={showExpandedSearch} style={{width: '95vw'}}
                    onHide={() => setShowExpandedSearch(false)}>
                <ReservationSearchCriteria updateSearchCriteria={updateSearchCriteria} searchString={searchString}
                                           setSearchString={setSearchString} doSearch={doSearch}/>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    topMenuContainer: {
        position: "fixed",
        width: "100%",
        maxWidth: "100vw",
        height: 90,
        zIndex: 100,
        display: "grid",
        gridTemplateColumns: "min(20%, 300px) auto",
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "30% auto",
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: "auto auto",
        },
    },

    logoContainer: {
        backgroundColor: Colors.WHITE,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    logo: {
        height: 80,
    },

    rightContainer: {
        backgroundColor: Colors.DARK_GREEN,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 10,
    },

    homeIcon: {
        fontSize: 50,
        color: Colors.WHITE,
        cursor: "pointer",
        marginLeft: 10,
        [theme.breakpoints.down('sm')]: {
            fontSize: 40,
        },
    },

    searchContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        visibility: localStorage.getItem("role") === "USER" ? "hidden" : "inherit",
    },

    searchInput: {
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },

    searchIcon: {
        fontSize: 40,
        color: Colors.WHITE,
        cursor: "pointer",
    },

    languageOptions: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },

    languageOption: {
        height: 35,
        cursor: "pointer",
        margin: 10,
    },

    menuIcon: {
        fontSize: 40,
        color: Colors.WHITE,
        cursor: "pointer",
        marginRight: 10,
        display: "none",
        [theme.breakpoints.down('sm')]: {
            display: "flex",
        },
    },

    expandedSearchContainer: {
        position: "fixed",
        display: "flex",
        width: "100%",
        maxWidth: "100vw",
        top: 90,
        fontSize: 25,
        zIndex: 1,
    },
}));

export default TopMenu;