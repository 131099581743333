import React from 'react';
import styled from "styled-components";
import {Colors} from "../../constants/colors";

interface Props {
    onClick: any;
    backgroundColor?: string;
    text?: string,
    disabled?: boolean,
    width?: string,
    height?: string,
    icon?: any,
}

const CustomTextButton = ({onClick, backgroundColor=Colors.LIGHT_GREY, text, disabled = false, width, height="38px", icon = null}: Props) => {
    return (
        disabled ? (
            <DisabledButton onClick={() => {}} backgroundColor={backgroundColor} icon={icon} height={height}>
                {icon && <LeftIcon>{icon}</LeftIcon>}
                <Label>{text}</Label>
            </DisabledButton>
        ) : (
            <ClickableButton onClick={onClick} backgroundColor={backgroundColor} width={width} icon={icon} height={height}>
                {icon && <LeftIcon>{icon}</LeftIcon>}
                <Label>{text}</Label>
                </ClickableButton>
        )
    );
};

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-width: 0;
  border-radius: 25px;
  font-weight: 400;
  text-decoration: none;
  padding: 5px 15px;
  margin: 10px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s;
`;

const ClickableButton = styled(Button)<Props>`
  background-color: ${(props: Props) => props.backgroundColor};
  width: ${(props: Props) => props.width};
  height: ${(props: Props) => props.height};
  cursor: pointer;

  &:focus, &:hover, &:active {
    outline: none;
  }

  &:hover, &:active {
    color: ${Colors.WHITE};
  }
`;

const DisabledButton = styled(Button)<Props>`
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
`;

const Label = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftIcon = styled.div`
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default CustomTextButton;
