import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {constDictType, getLabelOfDictionary} from "../../../constants/dictType";
import CustomSelectOne from "../CustomSelectOne";
import {
    getDegreeCoursesAndDepartmentUsingPOST as getDegreeCourses,
    getDepartmentsUsingGET as getDepartments,
    getSubjectWithDegreeCoursesAndDepartmentUsingPOST as getSubjects,
    getCategoriesUsingGET as getCategories,
    getPublicCategoriesUsingGET as getPublicCategories,
} from "../../../swagger/vue-api-client";
import {useTranslation} from "react-i18next";

interface Props {
    dictType: constDictType,
    value: string,
    setValue: any,
    parentValue: any,
}

const useStyles = makeStyles(theme => ({
}));

const CustomSelectOneDictionary = ({dictType, value, setValue, parentValue}: Props) => {
    const {t} = useTranslation();
    useStyles();
    const [items, setItems] = useState<any[]>([]);

    useEffect(() => {
        if (dictType === constDictType.DEPARTMENT) {
            getDepartments({query: ""}).then((response: any) => {
                setItems(response.data);
            });
        } else if (dictType === constDictType.CATEGORY) {
            getCategories({query: ""}).then((response: any) => {
                setItems(response.data);
            });
        } else if (dictType === constDictType.PUBLIC_CATEGORY) {
            getPublicCategories({query: ""}).then((response: any) => {
                setItems(response.data);
            });
        }

    }, [dictType]);

    useEffect(() => {
        if (dictType === constDictType.DEGREE_COURSE) {
            const dictionaryTypeDto = {
                departmentId: parentValue
            }

            getDegreeCourses({dictionaryTypeDto: dictionaryTypeDto}).then((response: any) => {
                setItems(response.data);
            });
        } else if (dictType === constDictType.SUBJECT) {
            const dictionaryTypeDto = {
                departmentId: parentValue.dictDeparment,
                degreeCourseId: parentValue.dictDegreeCourse,
            }

            getSubjects({dictionaryTypeDto: dictionaryTypeDto}).then((response: any) => {
                setItems(response.data);
            });
        }
    }, [parentValue, dictType]);

    return (
        <CustomSelectOne value={value} setValue={setValue} showClear={true} showFilter={true}
                         items={items} label={getLabelOfDictionary(dictType, t)}/>
    )
}

export default CustomSelectOneDictionary;