import React, {useEffect, useState} from "react";
import {Paper, TableContainer, TableFooter} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import {checkIfDependencyExist, checkIfFieldExist} from "../../../constants/dictDependecies";
import {constDictType, fields, getDictionaryTypeString} from "../../../constants/dictType";
import {getDictionariesUsingPOST as getDictionaries} from "../../../swagger/vue-api-client";
import CustomTextButton from "../../../components/form/CustomTextButton";
import {Colors, getColorLabel} from "../../../constants/colors";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import SortableTableHead from "../../../components/SortableTableHead";
import CustomPagination from "../../../components/CustomPagination";

interface Props {
    searchCriteria: any;
    updateSearchCriteria: any,
    dictType: constDictType;
    reloadSearch: boolean;
    totalRecords: number;
}

const DictionaryTable = ({searchCriteria, dictType, reloadSearch, totalRecords, updateSearchCriteria} : Props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const [dictionaries, setDictionaries] = useState<any[]>([]);

    const history = useHistory();

    const headCells = [
        checkIfFieldExist(dictType, fields.NAME) ?
            {label: t('dictionary.name'), field: "name", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.LENGTH_IN_SEMESTERS) //fixme subject nie ma bezpośredniego pola length in semesters
            ? {label: t('dictionary.lengthInSemesters'), field: "lengthInSemesters", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.STUDENT_COUNT)
            ? {label: t('dictionary.studentCount'), field: "studentCount", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.TITLE)
            ? {label: t('dictionary.title'), field: "title", joinField: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.FIRST_NAME)
            ? {label: t('dictionary.firstName'), field: "firstName", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.LAST_NAME)
            ? {label: t('dictionary.lastName'), field: "lastName", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.ORGANIZATION_UNIT)
            ? {label: t('dictionary.organizationUnit'), field: "organizationUnit", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.TILE_COLOR_HEX)
            ? {label: t('dictionary.tileColorHex'), field: "tileColorHex", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.FONT_COLOR_HEX)
            ? {label: t('dictionary.fontColorHex'), field: "fontColorHex", joinField: "", joinField2: "",  sortable: true} : null,
        checkIfFieldExist(dictType, fields.NUMBER)
            ? {label: t('dictionary.number'), field: "number", joinField: "", joinField2: "", sortable: true} : null,
        checkIfFieldExist(dictType, fields.ACTIVE)
            ? {label: t('dictionary.active'), field: "active", joinField: "", joinField2: "", sortable: false} : null,
        checkIfFieldExist(dictType, fields.MAIN)
            ? {label: t('dictionary.main'), field: "main", joinField: "", joinField2: "", sortable: false} : null,
        checkIfDependencyExist(dictType, constDictType.SUBJECT)
            ? {label: t('dictionary.subjectName'), field: "name", joinField: "subject", joinField2: "", sortable: true} : null,
        checkIfDependencyExist(dictType, constDictType.DEGREE_COURSE)
            ? {label: t('dictionary.degreeCourseName'), field: "name", joinField: "degreeCourse", joinField2: "", sortable: true} : null,
        checkIfDependencyExist(dictType, constDictType.DEPARTMENT)
            ? (dictType === constDictType.DEGREE_COURSE ?
                {label: t('dictionary.departmentName'), field: "name", joinField: "department", joinField2: "", sortable: true}
                : {label: t('dictionary.departmentName'), field: "name", joinField: "degreeCourse", joinField2: "department", sortable: true})
                : null,
        checkIfDependencyExist(dictType, constDictType.CATEGORY)
            ? {label: t('dictionary.category'), field: "name", joinField: "category", joinField2: "", sortable: true} : null,
        checkIfDependencyExist(dictType, constDictType.PUBLIC_CATEGORY)
            ? {label: t('dictionary.publicCategory'), field: "name", joinField: "publicCategory", joinField2: "", sortable: true} : null,
    ].filter((h) => h !== null);

    const showDictionary = (id: Number) => {
        history.push({pathname: "/dictionary/" + getDictionaryTypeString(dictType) + "/" + id.toString()});
    };

    const setPage = (page: any) => {
        updateSearchCriteria({
            ...searchCriteria,
            page: {
                ...searchCriteria.page,
                ...page
            }
        });
    }

    const setPageNr = (page: any) => {
        setPage({
            pageNr: page,
            offset: page * searchCriteria.page.limit
        });
    }

    const setRowsPerPage = (rowsPerPage: any) => {
        setPage({
            offset: searchCriteria.page.pageNr * rowsPerPage,
            limit: rowsPerPage,
        });
    }

    useEffect(() => {
        const dictionarySearchDto = {
            dictType: getDictionaryTypeString(dictType),
            departmentId: searchCriteria.departmentId,
            degreeCourseId: searchCriteria.degreeCourseId,
            subjectId: searchCriteria.subjectId,
            categoryId: searchCriteria.categoryId,
            publicCategoryId: searchCriteria.publicCategoryId,
            name: searchCriteria.name,
            firstName: searchCriteria.firstName,
            lastName: searchCriteria.lastName,
            title: searchCriteria.title,
            organizationUnit: searchCriteria.organizationUnit,
            lengthInSemesters: parseInt(searchCriteria.lengthInSemesters, 10),
            studentCount: parseInt(searchCriteria.studentCount, 10),
            tileColorHex: searchCriteria.tileColor,
            active: searchCriteria.active,
            page: searchCriteria.page,
            number: searchCriteria.number,
            main: searchCriteria.main,
        }

        getDictionaries({dictionarySearchDto: dictionarySearchDto}).then((result: any) => {
            setDictionaries(result.data);
        });
    }, [searchCriteria, dictType, reloadSearch]);

    return (
        <TableContainer component={Paper} className={classes.outer}>
            <Table>
                <SortableTableHead headCells={headCells} page={searchCriteria.page} setPage={setPage}/>

                <TableBody>
                    {dictionaries.map(dict => (
                        <TableRow key={dict.id}>
                            {checkIfFieldExist(dictType, fields.NAME) ? (<TableCell>{dict.name}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.LENGTH_IN_SEMESTERS) ? (<TableCell>{dict.lengthInSemesters}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.STUDENT_COUNT) ? (<TableCell>{dict.studentCount}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.TITLE) ? (<TableCell>{dict.title}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.FIRST_NAME) ? (<TableCell>{dict.firstName}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.LAST_NAME) ? (<TableCell>{dict.lastName}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.ORGANIZATION_UNIT) ? (<TableCell>{dict.organizationUnit}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.TILE_COLOR_HEX) ? (<TableCell>
                                <div key={dict.tileColorHex} className={classes.square}
                                     style={dict.tileColorHex === "#ffffff" ? {background: dict.tileColorHex, border: "solid 1px grey"} : {background: dict.tileColorHex}}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{getColorLabel(dict.tileColorHex)}</div>
                            </TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.FONT_COLOR_HEX) ? (<TableCell>
                                <div key={dict.fontColorHex} className={classes.square}
                                     style={dict.fontColorHex === "#ffffff" ? {background: dict.fontColorHex, border: "solid 1px grey"} : {background: dict.fontColorHex}}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{getColorLabel(dict.fontColorHex)}</div>
                            </TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.NUMBER) ? (<TableCell>{dict.number}</TableCell>) : null}
                            {checkIfFieldExist(dictType, fields.ACTIVE) ? (<TableCell>{dict.active ? t('actions.yes') : t('actions.no')}</TableCell>): null}
                            {checkIfFieldExist(dictType, fields.MAIN) ? (<TableCell>{dict.main ? t('actions.yes') : t('actions.no')}</TableCell>): null}
                            {checkIfDependencyExist(dictType, constDictType.SUBJECT) ? (<TableCell>{dict.subjectName}</TableCell>) : null}
                            {checkIfDependencyExist(dictType, constDictType.DEGREE_COURSE) ? (<TableCell>{dict.degreeCourseName}</TableCell>) : null}
                            {checkIfDependencyExist(dictType, constDictType.DEPARTMENT) ? (<TableCell>{dict.departmentName}</TableCell>) : null}
                            {checkIfDependencyExist(dictType, constDictType.CATEGORY) ? (<TableCell>{dict.categoryName}</TableCell>) : null}
                            {checkIfDependencyExist(dictType, constDictType.PUBLIC_CATEGORY) ? (<TableCell>{dict.publicCategoryName}</TableCell>) : null}
                            <TableCell width={80}>
                                <CustomTextButton text={t('actions.show')}
                                                  onClick={() => showDictionary(dict.id)}
                                                  backgroundColor={Colors.LIGHT_GREEN}/>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <CustomPagination
                            page={searchCriteria.page.pageNr}
                            setPage={setPageNr}
                            rowsPerPage={searchCriteria.page.limit}
                            setRowsPerPage={setRowsPerPage}
                            totalRecords={totalRecords}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>
    )
}

const useStyles = makeStyles(() => ({
    outer: {
        margin: "5px",
    },

    square: {
        width: 20,
        height: 20,
        marginRight: 100,
    },
}));

export default DictionaryTable;