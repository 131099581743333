import React, {useState} from 'react'
import CustomInputText from "../components/form/CustomInputText";
import {makeStyles} from "@material-ui/core/styles";
import CustomTextButton from "../components/form/CustomTextButton";
import {Colors} from "../constants/colors";
import {remindPasswordUsingPOST} from "../swagger/vue-api-client";
import 'react-toastify/dist/ReactToastify.css';
import {toastInfo} from "../services/InfoService";
import umbLogoWithName from '../assets/logo-i-orzel.png';
import {useTranslation} from 'react-i18next';

const RemindPassword = () => {
    const [email, setEmail] = useState("");
    const classes = useStyles();
    const {t} = useTranslation();

    const remindPasswordFunction = () => {
        remindPasswordUsingPOST({passwordRemindRequest: {email: email}}).then(() => {
            toastInfo("success", t('remindPassword.sentEmail'));
        }).catch(() => {
            toastInfo("error", t('remindPassword.wrongEmail'));
        });
    }

    return (
        <div>
            <div className={classes.logoContainer}>
                <img src={umbLogoWithName} alt={t('remindPassword.logo')} height={120} style={{marginLeft: 90}}/>
            </div>
            <div className={classes.container}>
                <div style={{marginTop: 20}}>
                    <h1 style={{display: "inline"}}>{t('remindPassword.title')}</h1>
                </div>
                <CustomInputText value={email} setValue={setEmail} label={t('remindPassword.email')} margin="auto"
                                 onKeyPress={() => remindPasswordFunction()} width="300px"/>
                <br />
                <div style={{marginLeft: 40}}>
                    <CustomTextButton onClick={() => remindPasswordFunction()} backgroundColor={Colors.LIGHT_GREEN}
                                      text={t('remindPassword.submitButton')} width="300px"/>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    logoContainer: {
        display: "flex",
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        marginTop: 80,
    },
    container: {
        height: 210,
        width: 400,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        border: 3,
        borderColor: Colors.PRIMARY_GREEN,
        color: Colors.PRIMARY_GREEN,
        borderStyle: "ridge",
        margin: "auto",
        marginTop: 30,
        borderRadius: 10,
        fontFamily: 'PT Sans',
    },
    a: {
        color: Colors.PRIMARY_GREEN,
    }
}))

export default RemindPassword;