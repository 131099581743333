import React from 'react';
import {FormControlLabel} from "@mui/material";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Dropdown} from "primereact/dropdown";

interface Props {
    items: Array<any>;
    value: any;
    setValue: any;
    label?: string;
    placeholder?: string;
    showClear?: boolean;
    showFilter?: boolean;
    labelWidth?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    dropdown: {
        width: "100%",
        minWidth: "180px",
    }
}));

const CustomSelectOne = ({value, setValue, items, label = "", placeholder = "", showClear=false, showFilter, labelWidth,
                             labelPlacement = "start", spaceAfterLabel = "30px"}: Props) => {
    if (label === "")
        spaceAfterLabel = "0px";
    const {container, dropdown} = useStyles();

    return (
        <Box>
            <FormControlLabel
                control={
                    <Dropdown value={value} options={items} onChange={(e) => setValue(e.value)}
                              placeholder={placeholder} showClear={showClear} filter={showFilter} className={dropdown}>
                    </Dropdown>
                }
                label={labelWidth === undefined ? label : <div style={{width: labelWidth}}>{label}</div>}
                labelPlacement={labelPlacement}
                className={container}
                sx={{gap: spaceAfterLabel, alignItems: "baseline", cursor: "initial"}}
            />
        </Box>
    )
}

export default CustomSelectOne;