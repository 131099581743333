import React from 'react'
import {Paper, TableContainer} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";

interface Props {
    events: any[],
    countStudents?: number,
}

const useStyles = makeStyles(() => ({
    outer: {
        margin: "5px",
    },
}));

const ReportsEventTable = ({events, countStudents = 0} : Props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const dateToString = (eventDate: Date) => {
        const date = new Date(eventDate);
        const options: any = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return date.toLocaleDateString("pl-PL", options);
    }

    return (
        <TableContainer component={Paper} className={classes.outer}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('reports.requestStatus')}</TableCell>
                        {countStudents === 0 && <TableCell>{t('reports.eventType')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.department')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.degreeCourse')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.subject')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.teacher')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.technician')}</TableCell>}

                        <TableCell>{t('reports.studentGroup')}</TableCell>
                        <TableCell>{t('reports.numberOfStudents')}</TableCell>

                        {countStudents % 2 === 0 && <TableCell>{t('reports.classroom')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.date')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.repeating')}</TableCell>}
                        {countStudents === 0 && <TableCell>{t('reports.numberOfHours')}</TableCell>}
                        {countStudents === 2 && <TableCell>{t('reports.processingTime')}</TableCell>}
                        {countStudents % 2 === 0 && <TableCell>{t('reports.postFactumStatus')}</TableCell>}
                        <TableCell/>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {events.length === 0 && <TableRow><TableCell>{t('reports.noData')}</TableCell></TableRow>}
                    {events.map(event => (
                        <TableRow key={event.id}>
                            <TableCell>{t('event.' + event.requestStatus)}</TableCell>
                            {countStudents === 0 && <TableCell>{event.eventTypeName}</TableCell>}
                            {countStudents % 2 === 0 && <TableCell>{event.studentGroupSubjectDepartmentName}</TableCell>}
                            {countStudents % 2 === 0 && <TableCell>{event.studentGroupSubjectDegreeCourseName}</TableCell>}
                            {countStudents % 2 === 0 && <TableCell>{event.subjectName}</TableCell>}
                            {countStudents % 2 === 0 && <TableCell>{event.teacherFullNameWithTitle}</TableCell>}
                            {countStudents % 2 === 0 && <TableCell>{(event.technicianRequired && event.technicianName) ? event.technicianName : ""}</TableCell>}

                            <TableCell>{event.studentGroupName}</TableCell>
                            <TableCell><b>{event.studentGroupCount}</b></TableCell>

                            {countStudents % 2 === 0 && <TableCell>{event.classroomNames.join(", ")}</TableCell>}
                            {countStudents % 2 === 0 && <TableCell>{dateToString(event.date)} {event.startTime.substring(0, 5)}-{event.endTime.substring(0, 5)}</TableCell>}
                            {countStudents === 0 && <TableCell><b>{event.repeating ? (event.repeatFrequency == "DAILY" ? t('reports.repeatDaily') : t('reports.repeatWeekly')) : ""}</b></TableCell>}
                            {countStudents === 0 && <TableCell><b>{event.numberOfHours}</b></TableCell>}
                            {countStudents === 2 && <TableCell>
                                {event.creationDate}
                            </TableCell>}
                            {countStudents % 2 === 0 && <TableCell>{t(event.postFactumStatus ? 'event.postFactum.' + event.postFactumStatus : "")}</TableCell>}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ReportsEventTable;