import React from 'react';
import {BrowserRouter as Router, Route, Switch,} from "react-router-dom";
import LoginView from "./views/LoginView";
import RegisterView from "./views/RegisterView";
import ForbiddenView from "./views/ForbiddenView";
import ConfirmEmail from "./views/ConfirmEmail";
import {ToastContainer} from "react-toastify";
import NotFoundView from "./views/NotFoundView";
import RemindPassword from "./views/RemindPassword";
import ChangePassword from "./views/ChangePassword";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import PrivateContentView from "./views/PrivateContentView";

function App() {
    return (
        <Router>
            <ToastContainer />
            <Switch>
                <Route path="/login" component={LoginView} />
                <Route path="/confirm-email" component={ConfirmEmail} />
                <Route path="/forbidden" component={ForbiddenView} />
                <Route path="/register" component={RegisterView} />
                <Route path="/not-found" component={NotFoundView} />
                <Route path="/remind-password" component={RemindPassword} />
                <Route path="/change-password" component={ChangePassword} />
                <PrivateContentView />
            </Switch>
        </Router>
    )
}

export default App;
