import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Grid, GridSpacing, Paper} from "@material-ui/core";
import CustomInputText from "../../components/form/CustomInputText";
import {ActionToolbar} from "../../utils/styled";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import {useHistory, useParams} from "react-router-dom";
import {
    createOrUpdateDictionaryUsingPOST as createDictionary,
    getDictionariesUsingPOST as getDictionaries
} from "../../swagger/vue-api-client";
import {
    constDictType,
    fields,
    findEnumValue,
    getDictionaryTypeString,
    getLabelOfDictionary
} from "../../constants/dictType";
import {checkIfDependencyExist, checkIfFieldExist} from "../../constants/dictDependecies";
import {toastInfo} from "../../services/InfoService";
import {useTranslation} from "react-i18next";
import CustomAutocomplete from "../../components/form/CustomAutocomplete";
import CustomSelectOneYesNo from "../../components/form/CustomSelectOneYesNo";
import CustomColorPicker from "../../components/form/CustomColorPicker";
import YesNoSelect from "../../components/form/YesNoSelect";

const DictionaryEditInfo = () => {
    const history = useHistory();
    const {t} = useTranslation();
    const classes = useStyles();
    const [name, setName] = useState<string>("");
    const [lengthInSemesters, setLengthInSemester] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [tileColor, setTileColor] = useState<string>("");
    const [fontColor, setFontColor] = useState<string>("");
    const [studentCount, setStudentCount] = useState<string>("");
    const [organizationUnit, setOrganizationUnit] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [category, setCategory] = useState<any>(null);
    const [publicCategory, setPublicCategory] = useState<any>(null);
    const [dictDepartment, setDictDepartment] = useState<any>(null);
    const [prevDepartment, setPrevDepartment] = useState<number>();
    const [dictDegreeCourse, setDictDegreeCourse] = useState<any>(null);
    const [prevDegree, setPrevDegree] = useState<number>();
    const [dictSubject, setDictSubject] = useState<any>(null);
    const [active, setActive] = useState<boolean>(true);
    const [main, setMain] = useState<boolean>(false);
    const [subjectFilters, setSubjectFilters] = useState<any>({
        degreeCourseId: dictDegreeCourse == null ? null : dictDegreeCourse.id,
        dictType: findEnumValue("SUBJECT"),
    });
    const [categoryFilters, setCategoryFilters] = useState<any>({
        category: category == null ? null : category.id,
        dictType: findEnumValue("CATEGORY"),
    });
    const [publicCategoryFilters, setPublicCategoryFilters] = useState<any>({
        publicCategory: publicCategory == null ? null : publicCategory.id,
        dictType: findEnumValue("PUBLIC_CATEGORY"),
    });
    const [degreeCourseFilters, setDegreeCourseFilters] = useState<any>({
        departmentId: dictDepartment == null ? null : dictDepartment.id,
        dictType: findEnumValue("DEGREE_COURSE"),
    });
    const [dictDetails, setDictDetails] = useState<any>();

    const {id} = useParams<{id: string}>();
    const {dicTypeString} = useParams<{dicTypeString: string}>();

    const dictType = findEnumValue(dicTypeString);

    useEffect(() => {
        setSubjectFilters({
            degreeCourseId: dictDegreeCourse == null ? null : dictDegreeCourse.id,
            dictType: findEnumValue("SUBJECT"),
        })
        if (dictDegreeCourse !== null && dictDegreeCourse.id !== prevDegree) {
            setDictSubject(null);
            setPrevDegree(dictDegreeCourse.id);
        }
    }, [dictDegreeCourse, prevDegree]);

    useEffect(() => {
        setDegreeCourseFilters({
            departmentId: dictDepartment == null ? null : dictDepartment.id,
            dictType: findEnumValue("DEGREE_COURSE"),
        })
        if(dictDepartment !== null && dictDepartment.id !== prevDepartment) {
            setDictDegreeCourse(null);
            setPrevDepartment(dictDepartment.id);

        }
    }, [dictDepartment, prevDepartment])


    useEffect(() => {
        const dictionarySearchDto = {
            dictType: dicTypeString,
            id: parseInt(id, 10),
        }

        getDictionaries({dictionarySearchDto: dictionarySearchDto}).then((result: any) => {
            const dictionary = result.data[0];
            setDictDetails(dictionary);
            if (dictionary.departmentName !== null && dictionary.departmentId !== null) {
                setPrevDepartment(dictionary.departmentId);
                setDictDepartment({id: dictionary.departmentId, name: dictionary.departmentName})
            }
            if (dictionary.degreeCourseName !== null && dictionary.degreeCourseId !== null) {
                setPrevDegree(dictionary.degreeCourseId);
                setDictDegreeCourse({id: dictionary.degreeCourseId, name: dictionary.degreeCourseName})
            }
            if (dictionary.categoryName !== null && dictionary.categoryId !== null) {
                setCategory({id: dictionary.categoryId, name: dictionary.categoryName});
            }
            if (dictionary.publicCategoryName !== null && dictionary.publicCategoryId !== null) {
                setPublicCategory({id: dictionary.publicCategoryId, name: dictionary.publicCategoryName});
            }
            if (dictionary.subjectName !== null && dictionary.subjectId !== null) {
                setDictDepartment({id: dictionary.subjectId, name: dictionary.subjectName})
            }
            if (dictionary.name !== null) {
                setName(dictionary.name);
            }
            if (dictionary.firstName !== null) {
                setFirstName(dictionary.firstName);
            }
            if (dictionary.lastName !== null) {
                setLastName(dictionary.lastName);
            }
            if (dictionary.organizationUnit !== null) {
                setOrganizationUnit(dictionary.organizationUnit);
            }
            if (dictionary.title !== null) {
                setTitle(dictionary.title);
            }
            if (dictionary.tileColorHex !== null) {
                setTileColor(dictionary.tileColorHex);
            }
            if (dictionary.fontColorHex !== null) {
                setFontColor(dictionary.fontColorHex);
            }
            if (dictionary.lengthInSemesters !== null) {
                setLengthInSemester(dictionary.lengthInSemesters);
            }
            if (dictionary.studentCount !== null) {
                setStudentCount(dictionary.studentCount);
            }
            if (dictionary.number !== null) {
                setNumber(dictionary.number);
            }
            if (dictionary.active !== null) {
                setActive(dictionary.active);
            }
            if (dictionary.main !== null) {
                setMain(dictionary.main);
            }
        });
    }, [dicTypeString, id]);

    const backToDetailsView = (idArg: String) => {
        history.push({pathname: "/dictionary/" + dicTypeString + "/" + idArg});
    }

    const getIdIfNotNull = (dict: any) => {
        return dict !== null && dict.id !== null ? dict.id : null;
    }

    const save = () => {
        const dictionaryDto = {
            id: dictDetails.id,
            departmentId: getIdIfNotNull(dictDepartment),
            degreeCourseId: getIdIfNotNull(dictDegreeCourse),
            subjectId: getIdIfNotNull(dictSubject),
            dictType: getDictionaryTypeString(dictType),
            categoryId: getIdIfNotNull(category),
            publicCategoryId: getIdIfNotNull(publicCategory),
            organizationUnit: organizationUnit,
            firstName: firstName,
            lastName: lastName,
            name: name,
            title: title,
            lengthInSemesters: parseInt(lengthInSemesters, 10),
            studentCount: parseInt(studentCount, 10),
            tileColorHex: tileColor,
            fontColorHex: fontColor,
            number: number,
            active: active,
            main: main
        }

        createDictionary({dictionaryDto: dictionaryDto}).then((res: any) => {
            if (res.data == "taken") {
                toastInfo("error", t('nameTaken'));
            } else {
                toastInfo("success", t('dictionary.saved'));
                backToDetailsView(res.data)
            }
        }).catch(() => {
            toastInfo("error", t('errorOccurred'));
        });
    }

    return (
        <div className={classes.dictionaryEditInfo}>
            <h2 className={classes.header}>{t('dictionary.editInfoHeader')} {getLabelOfDictionary(dictType, t)}</h2>
            <Paper elevation={2} className={classes.content}>
                    <Grid container={true} direction={'column'} spacing={2 as GridSpacing}>
                        {checkIfFieldExist(dictType, fields.NAME) ? <Grid item xs={8}>
                            <CustomInputText value={name} setValue={setName} label={t('dictionary.name')}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.LENGTH_IN_SEMESTERS) ? <Grid item xs={8}>
                            <CustomInputText value={lengthInSemesters} setValue={setLengthInSemester}
                                             label={t('dictionary.lengthInSemesters')} number={true}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.STUDENT_COUNT) ? <Grid item xs={8}>
                            <CustomInputText value={studentCount} setValue={setStudentCount} label={t('dictionary.studentCount')}
                                             number={true}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.TILE_COLOR_HEX) ? <Grid item xs={8}>
                            <CustomColorPicker value={tileColor} setValue={setTileColor} label={t('dictionary.tileColorHex')}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.FONT_COLOR_HEX) ? <Grid item xs={8}>
                            <CustomColorPicker value={fontColor} setValue={setFontColor} label={t('dictionary.fontColorHex')}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.FIRST_NAME) ? <Grid item xs={8}>
                            <CustomInputText value={firstName} setValue={setFirstName} label={t('dictionary.firstName')} />
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.LAST_NAME) ? <Grid item xs={8}>
                            <CustomInputText value={lastName} setValue={setLastName} label={t('dictionary.lastName')}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.TITLE) ? <Grid item xs={8}>
                            <CustomInputText value={title} setValue={setTitle} label={t('dictionary.title')}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.ORGANIZATION_UNIT) ? <Grid item xs={8}>
                            <CustomInputText value={organizationUnit} setValue={setOrganizationUnit} label={t('dictionary.organizationUnit')}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.NUMBER) ? <Grid item xs={8}>
                            <CustomInputText value={number} setValue={setNumber}
                                             label={t('dictionary.number')} number={true}/>
                        </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.ACTIVE) ? <Grid item xs={8}>
                            <div style={{marginTop: 25}}><CustomSelectOneYesNo value={active} setValue={setActive} /></div>
                            </Grid> : ""}
                        {checkIfFieldExist(dictType, fields.MAIN) ? <Grid item xs={8}>
                            <div style={{marginTop: 25}}><YesNoSelect value={main} setValue={setMain} label={t("dictionary.main")}/></div>
                        </Grid> : ""}
                        {checkIfDependencyExist(dictType, constDictType.DEPARTMENT) ? <Grid item xs={8}><CustomAutocomplete
                            value={dictDepartment}
                            setValue={setDictDepartment}
                            completeMethod={getDictionaries}
                            filters={{
                                dictType: findEnumValue("DEPARTMENT"),
                            }}
                            label={t('reservation.departmentName')}
                        /> </Grid>: ""}

                        {checkIfDependencyExist(dictType, constDictType.DEGREE_COURSE) ? <Grid item xs={8}><CustomAutocomplete
                            value={dictDegreeCourse}
                            setValue={setDictDegreeCourse}
                            completeMethod={getDictionaries}
                            filters={degreeCourseFilters}
                            label={t('reservation.degreeCourse')} disabled={dictDepartment == null}
                        /> </Grid>: ""}
                        {checkIfDependencyExist(dictType, constDictType.SUBJECT) ? <Grid item xs={8}><CustomAutocomplete
                            value={dictSubject}
                            setValue={setDictSubject}
                            completeMethod={getDictionaries}
                            filters={subjectFilters}
                            label={t('reservation.subjectName')}
                            disabled={dictDegreeCourse == null}
                        /> </Grid>: ""}
                    {checkIfDependencyExist(dictType, constDictType.CATEGORY) ? <Grid item xs={8}><CustomAutocomplete
                            value={category}
                            setValue={setCategory}
                            completeMethod={getDictionaries}
                            filters={categoryFilters}
                            label={t('reservation.categoryName')}
                            disabled={false}
                        /> </Grid>: ""}
                    {checkIfDependencyExist(dictType, constDictType.PUBLIC_CATEGORY) ? <Grid item xs={8}><CustomAutocomplete
                            value={publicCategory}
                            setValue={setPublicCategory}
                            completeMethod={getDictionaries}
                            filters={publicCategoryFilters}
                            label={t('reservation.publicCategoryName')}
                            disabled={false}
                        /> </Grid>: ""}
                    </Grid>
                <ActionToolbar className={classes.buttons}>
                    <CustomTextButton onClick={() => backToDetailsView(id)} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={() => save()} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.save')}/>
                </ActionToolbar>
            </Paper>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    dictionaryEditInfo: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    content: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "20px"
    },

    buttons: {
        marginTop: 20,
    },
}));

export default DictionaryEditInfo;