export const SystemRole = {
    roles: [
        {value: "ADMIN", label: "Administrator"},
        {value: "PLANNER", label: "Planista"},
        {value: "USER", label: "Użytkownik"}
    ],

    ADMIN: "ADMIN",
    PLANNER: "PLANNER",
    USER: "USER",

    isAdmin() {
        return localStorage.getItem("role") === this.ADMIN;
    },

    isPlanner() {
        return localStorage.getItem("role") === this.PLANNER;
    },

    isUser() {
        return localStorage.getItem("role") === this.USER;
    },

    isAdminOrPlanner() {
        return localStorage.getItem("role") === this.ADMIN || localStorage.getItem("role") === this.PLANNER;
    },

    isAuthorized() {
        return localStorage.getItem("role") === this.ADMIN || localStorage.getItem("role") === this.PLANNER
        || localStorage.getItem("role") === this.USER;
    },
};
