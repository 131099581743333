import React from 'react';
import {Box, FormControlLabel} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {SelectButton} from "primereact/selectbutton";
import {useTranslation} from "react-i18next";
import {Message} from "primereact/message";

interface Props {
    value: boolean,
    setValue: any,
    label?: any;
    width?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
    infoMsg?: string;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
}));


const YesNoSelect = ({value, setValue, label = "", width = "200px", labelPlacement = "start", spaceAfterLabel = "30px", infoMsg = ""}: Props) => {
    if (label === "")
        spaceAfterLabel = "0px";
    const {container} = useStyles();
    const {t} = useTranslation();
    const yesNoSelection = [{label: t('actions.no'), value: false}, {label: t('actions.yes'), value: true}];

    return (
        <Box className={container}>
            <FormControlLabel
                control={
                    <div>
                        <SelectButton value={value} options={yesNoSelection} onChange={(e) => setValue(e.value)}/>
                        {infoMsg !== "" && <div style={{paddingTop: "10px"}}><Message severity="info" text={infoMsg}/></div>}
                    </div>
                }
                label={<div style={{width: width}}>{label}</div>}
                labelPlacement={labelPlacement}
                sx={{gap: spaceAfterLabel, cursor: "initial"}}
            />
        </Box>
    )
}

export default YesNoSelect;