import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ICalendarClassroomInfo} from "../../../interfaces";

interface Props {
    calendar: Map<number, ICalendarClassroomInfo>;
    publicView? : "now" | "full" | false;
}

const CalendarClassrooms = ({calendar, publicView = false}: Props) => {
    const classes = useStyles();
    const listOfClassroomsAsJSX = () => {
        const classrooms : any = [];
        calendar.forEach((value, i) => {
            classrooms.push(<div className={publicView !== false ? classes.publicItem : classes.item} key={"classroom" + i}>{value.classroomName}</div>);
            for (let j = 1; j < value.dataFields.length; j++) {
                classrooms.push(<div className={publicView !== false ? classes.publicItem : classes.item} key={i + "classroom-empty" + j}/>);
            }
        });
        return classrooms;
    }

    return (
        <div>
            <div className={publicView === "now" ? classes.publicItemHeader : classes.item} style={publicView ? {height:"30px", minHeight:"30px"} : {}}>
                {publicView === "now" ? calendar.values().next().value.publicCategoryName: ""}
            </div>
            {listOfClassroomsAsJSX()}
        </div>
    );
}

const useStyles = makeStyles(() => ({
    item: {
        height: "50px",
        minHeight: "50px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        whiteSpace: "nowrap",
        alignContent: "flex-end",
        paddingRight: "5px",
    },
    publicItem: {
        height: "60px",
        minHeight: "60px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        whiteSpace: "nowrap",
        alignContent: "flex-end",
        paddingRight: "20px",
        fontSize: "1.5em",
        minWidth: "350px",
        fontWeight: "bolder",
    },
    publicItemHeader: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        whiteSpace: "nowrap",
        alignContent: "flex-start",
        paddingRight: "20px",
        fontSize: "3em",
        position: "relative",
        top: "65%",
        fontWeight: "bold",
        transformOrigin: "left",
        transform: "rotate(-90deg)",
    },
}));

export default CalendarClassrooms;

