import React, {useState} from 'react'
import CustomInputText from "../components/form/CustomInputText";
import {makeStyles} from "@material-ui/core/styles";
import CustomTextButton from "../components/form/CustomTextButton";
import {Colors} from "../constants/colors";
import {useHistory} from "react-router-dom";
import {changePasswordUsingPOST} from "../swagger/vue-api-client";
import 'react-toastify/dist/ReactToastify.css';
import {toastInfo} from "../services/InfoService";
import umbLogoWithName from '../assets/logo-i-orzel.png'
import {useTranslation} from "react-i18next";

const ChangePassword = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const resetPasswordToken = urlParams.get('resetPasswordToken');

    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const passwordRegExp = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\[\\]{};:+=|,<.>\\`~£§?/'\\-\\_\"])(?=.*[a-zA-Z]).{8,}$");


    const changePasswordFunction = () => {
        if (newPassword !== confirmNewPassword) {
            toastInfo("error", t('changePassword.passwordsDiffer'));
            return;
        }

        if (!passwordRegExp.test(newPassword)) {
            toastInfo("error", t('changePassword.insufficientPassword'));
            return;
        }
        changePasswordUsingPOST({passwordChangeRequest: {newPassword: newPassword, resetPasswordToken: resetPasswordToken}}).then(() => {
            toastInfo("success", t('changePassword.passwordChanged'));

            history.push("login");
        }).catch(() => {
            toastInfo("error", t('errorOccurred'));
        });
    }

    return (
        <div>
            <div className={classes.logoContainer}>
                <img src={umbLogoWithName} alt={t('changePassword.logo')} height={120} style={{marginLeft: 90}}/>
            </div>
            <div className={classes.container}>
                <div style={{marginTop: 20}}>
                    <h1 style={{display: "inline"}}>{t('changePassword.title')}</h1>
                </div>
                <CustomInputText value={newPassword} setValue={setNewPassword} label={t('changePassword.newPassword')} margin="auto"
                                 onKeyPress={() => changePasswordFunction()} width="300px" password={true}/>
                <CustomInputText value={confirmNewPassword} setValue={setConfirmNewPassword} label={t('changePassword.repeatPassword')} password={true} margin="auto" width="300px"/>
                <br />
                <div style={{marginLeft: 40}}>
                    <CustomTextButton onClick={() => changePasswordFunction()} backgroundColor={Colors.LIGHT_GREEN} text={t('changePassword.submitButton')} width="300px"/>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    logoContainer: {
        display: "flex",
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        marginTop: 80,
    },
    container: {
        height: 260,
        width: 400,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        border: 3,
        borderColor: Colors.PRIMARY_GREEN,
        color: Colors.PRIMARY_GREEN,
        borderStyle: "ridge",
        margin: "auto",
        marginTop: 30,
        borderRadius: 10,
        fontFamily: 'PT Sans',
    },
    a: {
        color: Colors.PRIMARY_GREEN,
    }
}))

export default ChangePassword;