import React, {useEffect, useState} from 'react';
import CustomTextButton from "../../../components/form/CustomTextButton";
import {Colors} from "../../../constants/colors";
import CustomInputText from "../../../components/form/CustomInputText";
import {makeStyles} from "@material-ui/core/styles";
import {ActionToolbar} from '../../../utils/styled';
import CustomAutocomplete from "../../../components/form/CustomAutocomplete";
import {
    getDictionariesUsingPOST as getDictionaries,
    getPostFactumStatusesUsingGET as getPostFactumStatuses,
} from "../../../swagger/vue-api-client";
import {findEnumValue} from "../../../constants/dictType";
import {useTranslation} from "react-i18next";
import CustomSelectOne from "../../../components/form/CustomSelectOne";
import CustomInputSearchText from "../../../components/form/CustomInputSearchText";

interface Props {
    updateSearchCriteria: any;
    searchString: any;
    setSearchString: any;
    doSearch: any;
}

const ReservationSearchCriteria = ({updateSearchCriteria, searchString, setSearchString, doSearch} : Props) => {
    const [classroom, setClassroom] = useState<any>(null);
    const [eventType, setEventType] = useState<any>(null);
    const [postFactumStatus, setPostFactumStatus] = useState<any>(null);
    const [faculty, setFaculty] = useState<any>(null);
    const [degreeCourse, setDegreeCourse] = useState<any>(null);
    const [subject, setSubject] = useState<any>(null);
    const [studentGroup, setStudentGroup] = useState<any>(null);
    const [teacher, setTeacher] = useState<any>(null);
    const [teacherOther, setTeacherOther] = useState("");
    const [assignedTechnician, setAssignedTechnician] = useState<any>();

    const [postFactumStatuses, setPostFactumStatuses] = useState<any[]>([]);

    const [degreeCourseFilters, setDegreeCourseFilters] = useState<any>({
        departmentId: faculty == null ? null : faculty.id,
        dictType: findEnumValue("DEGREE_COURSE"),
    });

    const [subjectFilters, setSubjectFilters] = useState<any>({
        departmentId: faculty == null ? null : faculty.id,
        dictType: findEnumValue("DEGREE_COURSE"),
    });

    const [studentGroupFilters, setStudentGroupFilters] = useState<any>({
        degreeCourseId: degreeCourse == null ? null : degreeCourse.id,
        dictType: findEnumValue("STUDENT_GROUP"),
    });

    useEffect(() => {
        getPostFactumStatuses({}).then((res : any) => {
            setPostFactumStatuses(res.data);
        })
    }, []);

    useEffect(() => {
        setDegreeCourseFilters({
            departmentId: faculty == null ? null : faculty.id,
            dictType: findEnumValue("DEGREE_COURSE"),
        })
        setDegreeCourse(null);
    }, [faculty])

    useEffect(() => {
        setSubjectFilters({
            degreeCourseId: degreeCourse == null ? null : degreeCourse.id,
            dictType: findEnumValue("SUBJECT"),
        })
        setSubject(null);
    }, [degreeCourse])

    useEffect(() => {
        setStudentGroupFilters({
            degreeCourseId: degreeCourse == null ? null : degreeCourse.id,
            dictType: findEnumValue("STUDENT_GROUP"),
        })
        setStudentGroup(null);
    }, [degreeCourse])

    const classes = useStyles();
    const {t} = useTranslation();

    const clear = () => {
        setClassroom("");
        setEventType("");
        setFaculty("");
        setDegreeCourse("");
        setSubject("");
        setStudentGroup("");
        setTeacher("");
        setTeacherOther("");
        setPostFactumStatus("");
        setAssignedTechnician("");
    };

    const search = () => {
        updateSearchCriteria({
            search: true,
            classroom: classroom ? classroom.id : null,
            eventType: eventType ? eventType.id : null,
            faculty: faculty ? faculty.id : null,
            degreeCourse: degreeCourse ? degreeCourse.id : null,
            subject: subject ? subject.id : null,
            studentGroup: studentGroup ? studentGroup.id : null,
            teacher: teacher ? teacher.id : null,
            teacherOther: teacherOther ? teacherOther : null,
            postFactumStatus: postFactumStatus ? postFactumStatus : null,
            assignedTechnician: assignedTechnician ? assignedTechnician.id : null,
            searchString: searchString ? searchString : null,
        });
    };

    const labelPlacement = window.innerWidth < 650 ? "top" : "end";
    const spaceAfterLabel = window.innerWidth < 650 ? "5px" : "30px";

    return (
        <div className={classes.reservationSearchCriteria}>
            <div className={classes.searchInput}>
                <CustomInputSearchText onKeyPress={doSearch} value={searchString} setValue={setSearchString}
                                       label={t('managementPanel.search.placeholder')} width={"260px"}/>
                <div className={classes.searchLabel}>{t('managementPanel.search.mainFieldLabel')}</div>
            </div>
            <CustomAutocomplete
                value={classroom}
                setValue={setClassroom}
                completeMethod={getDictionaries}
                filters={{
                    dictType: findEnumValue("CLASSROOM"),
                }}
                label={t('reservation.classroom')}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomAutocomplete
                value={eventType}
                setValue={setEventType}
                completeMethod={getDictionaries}
                filters={{
                    dictType: findEnumValue("EVENT_TYPE"),
                }}
                label={t('reservation.eventType')}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomAutocomplete
                value={faculty}
                setValue={setFaculty}
                completeMethod={getDictionaries}
                filters={{
                    dictType: findEnumValue("DEPARTMENT"),
                }}
                label={t('reservation.departmentName')}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomAutocomplete
                value={degreeCourse}
                setValue={setDegreeCourse}
                completeMethod={getDictionaries}
                filters={degreeCourseFilters}
                label={t('reservation.degreeCourse')}
                disabled={faculty == null}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomAutocomplete
                value={subject}
                setValue={setSubject}
                completeMethod={getDictionaries}
                filters={subjectFilters}
                label={t('reservation.subjectName')}
                disabled={degreeCourse == null}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomAutocomplete
                value={studentGroup}
                setValue={setStudentGroup}
                completeMethod={getDictionaries}
                filters={studentGroupFilters}
                label={t('reservation.studentGroup')}
                disabled={degreeCourse == null}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomAutocomplete
                value={assignedTechnician}
                setValue={setAssignedTechnician}
                completeMethod={getDictionaries}
                filters={{
                    dictType: findEnumValue("TECHNICIAN"),
                }}
                label={t('reservation.technician')}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomAutocomplete
                value={teacher}
                setValue={setTeacher}
                completeMethod={getDictionaries}
                filters={{
                    dictType: findEnumValue("TEACHER"),
                }}
                label={t('reservation.teacher')}
                width={"250px"}
                spaceAfterLabel={spaceAfterLabel}
                labelPlacement={labelPlacement}
            />
            <CustomSelectOne
                showClear={true}
                value={postFactumStatus}
                setValue={setPostFactumStatus}
                labelWidth={"250px"}
                items={postFactumStatuses}
                label={t('reservation.postFactumStatus')}
                labelPlacement={labelPlacement}
                spaceAfterLabel={spaceAfterLabel}
            />
            <div style={{marginLeft: "15px", marginTop: "-20px", width: "100%"}}>
                <CustomInputText value={teacherOther} setValue={setTeacherOther}
                                 label={t('reservation.teacherOther')} fullWidth={false} margin={"0px"}/>
            </div>
            <ActionToolbar className={classes.buttons}>
                <CustomTextButton onClick={clear} backgroundColor={Colors.LIGHT_GREY} text={t('actions.clear')}/>
                <CustomTextButton onClick={search} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.search')}/>
            </ActionToolbar>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    reservationSearchCriteria: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        flexWrap: "wrap",
        rowGap: 20,
        columnGap: 20,
    },

    searchInput: {
        display: "flex",
        marginLeft: -10,
        marginTop: 10,
        marginRight: 11,
        justifyContent: "flex-start",
        alignItems: "center",
        [theme.breakpoints.down(650)]: {
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
        },
    },

    searchLabel: {
        width: 250,
        marginRight: 7,
        marginLeft: 25
    },

    buttons: {
        display: "flex",
        flexWrap: "wrap",
    },
}));

export default ReservationSearchCriteria;




