import React from 'react';
import CustomTextButton from "../../../components/form/CustomTextButton";
import {Colors} from "../../../constants/colors";
import {reportToCsvUsingPOST as getReportCsv} from "../../../swagger/vue-api-client";
import {FileUtils} from "../../../utils/FileUtils";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import axios from "axios";

interface Props {
    dictType: any;
    value: any;
    generateReport: any;
    showReport: boolean;
    reportQuery: any;
}

const ReportsButtons = ({dictType, value, generateReport, showReport, reportQuery}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const onCsvDownload = () => {
        getReportCsv({reportQueryDto: reportQuery}).then((response: any) => {
            FileUtils.forceFileDownload(response, t('reports.filenameCsv'));
        })
    }

    const onXlsxDownload = () => {
        return axios.post(process.env.REACT_APP_API_URL + "/event/reports/xlsx", reportQuery, {
            url: process.env.REACT_APP_API_URL + "/event/reports/xlsx",
            method: 'post',
            responseType: 'blob',
        }).then((response) => {
            FileUtils.forceFileDownload(response, t('reports.filenameXlsx'));
        }).catch(() => {});
    }

    return (
        <div className={classes.buttonsContainer}>
            {showReport && <CustomGenerateReport onClick={onCsvDownload} text={t('reports.downloadCsv')}/>}
            {showReport && <CustomGenerateReport onClick={onXlsxDownload} text={t('reports.downloadXlsx')}/>}
            <CustomGenerateReport onClick={() => generateReport(dictType, value)}/>
        </div>
    )
}

interface GenerateReportInterface {
    onClick: any,
    text?: string,
}

const CustomGenerateReport = ({onClick, text}: GenerateReportInterface) => {
    const {t} = useTranslation();
    if (text === undefined) {
        text = t('reports.generate');
    }

    return (
        <CustomTextButton width={"160px"} height={"30px"} backgroundColor={Colors.LIGHT_GREEN} onClick={onClick} text={text}/>
    );
}

const useStyles = makeStyles(() => ({
    buttonsContainer: {
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        marginTop: 10,
    },
}));

export default ReportsButtons;