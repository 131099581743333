import React from 'react';
import {Box, FormControlLabel} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import {useTranslation} from "react-i18next";
import {getCalendarLocale} from "../../locales/getCalendarLocale";
import {Message} from "primereact/message";

interface Props {
    value: any;
    setValue: any;
    label?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    showButtonBar?: boolean;
    month?: boolean;
    showWeek?: boolean;
    width?: string;
    spaceAfterLabel?: string;
    disabled?: boolean;
    errorMsg?: string;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
}));

const CustomCalendar = ({value, setValue, label = "", labelPlacement = "start", showButtonBar = false, month = false,
                            showWeek = false, width = "200px", spaceAfterLabel = "30px", disabled = false, errorMsg = ""}: Props) => {
    if (label === "")
        spaceAfterLabel = "0px";
    const {container} = useStyles();
    const {t} = useTranslation();

    addLocale('pl', getCalendarLocale(t));

    var control = {
        control:
            <div><Calendar
                name={'calendar'}
                value={value}
                onChange={(e) => {setValue(e.value)}}
                showIcon
                dateFormat="dd/mm/yy"
                locale="pl"
                showButtonBar={showButtonBar}
                showWeek={showWeek}
                disabled={disabled}
            />
                {errorMsg !== "" && <div style={{paddingTop: "10px"}}><Message severity="error" text={errorMsg}/></div>}
            </div>
    };

    if (month) {
        control.control =
            <Calendar
                id="monthpicker"
                value={value}
                onChange={(e) => setValue(e.value)}
                view="month"
                dateFormat="mm/yy"
                yearNavigator
                yearRange="2010:2030"
                showIcon
                locale="pl"
                showButtonBar={showButtonBar}
        />
    }

    return (
        <Box>
            <FormControlLabel
                {...control}
                label={<div style={{width: width}}>{label}</div>}
                labelPlacement={labelPlacement}
                className={container}
                sx={{gap: spaceAfterLabel, cursor: "initial"}}
            />
        </Box>
    )
}

export default CustomCalendar;