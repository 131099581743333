import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    areThereLongerClassesUsingPOST as areThereLongerClasses,
    checkIsDateRestrictedUsingPOST as checkIsDateRestricted,
    getClassroomsWithCategoriesUsingGET as getClassroomsWithCategories,
    getClassroomsWithPublicCategoriesUsingGET as getClassroomsWithPublicCategories,
    searchEventUsingPOST as searchEvent
} from "../../../swagger/vue-api-client";
import {useTranslation} from "react-i18next";
import {ICalendarClassroomInfo, TCalendarRow} from "../../../interfaces";
import CalendarClassrooms from "../components/CalendarClassrooms";
import CalendarTimetable from "../components/CalendarTimetable";
import {Colors} from "../../../constants/colors";

const MAX_HOUR = 18;
const MAX_HOUR_LONG = 20;
const MIN_HOUR = 8;
const UNIT = 15;
const nrOfColumns = (MAX_HOUR - MIN_HOUR + 1) * (60 / UNIT);
const nrOfColumnsLong = (MAX_HOUR_LONG - MIN_HOUR + 1) * (60 / UNIT);
const nowPVBefore = 90;
const nowPVAfter = 120;

interface Props {
    statusList: Array<string>;
    chosenDate: Date;
    setOpenPreview: Function;
    setPreviewedEvent: Function;
    onlyMyEvents: boolean;
    scrollToTime: Date;
    setScrollToTime: any;
    viewOnly?: boolean;
    key?: number;
    publicView?: number | false;
}

const CalendarDayContent = ({statusList, chosenDate, setOpenPreview, setPreviewedEvent, onlyMyEvents, scrollToTime, setScrollToTime, viewOnly = false, key=1, publicView = false}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();

    const [chosenDateIsRestricted, setChosenDateIsRestricted] = useState(false);
    const [restrictionReason, setRestrictionReason] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [calendarHeight, setCalendarHeight] = useState(0);
    const [calendar, setCalendar] = useState(new Map<number, ICalendarClassroomInfo>()); // klucz - id sali, wartość - wiersze w kalendarzu, które mają być dla niej wypisane
    const [longerTimetable, setLongerTimetable] = useState(false);
    const [horizontalScroll, setHorizontalScroll] = useState(0)
    const [horizontalScrollStart, setHorizontalScrollStart] = useState(0)
    const [scrollingByMouse, setScrollingByMouse] = useState(false)
    const [classroomGroup, setClassroomGroup] = useState<number>(0);
    const [categoryNum, setCategoryNum] = useState<number>(1);
    const [networkError, setNetworkError] = useState<boolean>(false);

    let calendarId = "calendar" + chosenDate.getDate();

    useEffect(() => {
        // @ts-ignore
        document.getElementById(calendarId).scrollLeft = scrollToTime === undefined ? 0 :
            50 * ((scrollToTime.getHours() - 8) * 4 + scrollToTime.getMinutes() / 15)
    }, [scrollToTime, calendarId, calendar])

    useEffect(() => {
        const timezoneOffset = chosenDate.getTimezoneOffset() * 60000;
        const date = (new Date(chosenDate.getTime() - timezoneOffset)).toISOString().substring(0, chosenDate.toISOString().length - 5);
        checkIsDateRestricted({dateString: date}).then((res: any) => {
            if (res.data) {
                setRestrictionReason(res.data);
                setChosenDateIsRestricted(true);
            } else {
                setChosenDateIsRestricted(false);
            }
        });
    }, [chosenDate])

    const addNewEmptyRow = (dataFields: TCalendarRow[], longer: boolean) => {
        const emptyRow: TCalendarRow = [];
        const lastColumn = longer ? nrOfColumnsLong : nrOfColumns;
        for (let i = 0; i < lastColumn; i++) {
            emptyRow.push({
                fieldText: "",
                fieldColor: "",
                fontColor: "",
                fieldType: 0,
                fieldWidth: 0,
                eventId: 0,
                eventStatus: "",
            });
        }
        dataFields.push(emptyRow);
    }

    const addEventInCalendar = (beginField: number, endField: number, beginTime: string[], endTime: string[], row: TCalendarRow, event: any) => {
        for (let i = beginField; i <= endField; i += 1) {
            row[i].fieldColor = event.studentGroupSubjectTileColorHex;
            row[i].fontColor = event.studentGroupSubjectFontColorHex;
            row[i].eventId = event.id;
            row[i].eventStatus = event.requestStatus;
        }
        row[beginField].fieldText = event.subjectName
        if (event.teacherFullNameWithTitle !== null && !publicView) {
            row[beginField].fieldText += ", " + event.teacherFullNameWithTitle
        }
        if (publicView && classroomGroup % categoryNum !== 0) {
            const now = new Date();
            const compare = new Date()
            now.setMinutes(now.getMinutes() - nowPVBefore);
            compare.setHours(+beginTime[0]);
            compare.setMinutes(+beginTime[1]);
            if (now > compare) {
                row[beginField].fieldText += ", " + beginTime[0] + ":" + beginTime[1] + " - " + endTime[0] + ":" + endTime[1];
            } else {
                compare.setHours(+endTime[0]);
                compare.setMinutes(+endTime[1]);
                now.setMinutes(now.getMinutes() + nowPVBefore + nowPVAfter);
                if (now < compare) {
                    row[beginField].fieldText += ", " + beginTime[0] + ":" + beginTime[1] + " - " + endTime[0] + ":" + endTime[1];
                }
            }
        }
        if (event.studentGroupName !== null) {
            row[beginField].fieldText += ", " + event.studentGroupName
        }
        row[beginField].fieldType = 1;
        row[beginField].fieldWidth = endField - beginField + 1;
        row[endField].fieldType = 2;
    }

    useEffect(() => {
        if (publicView) {
            let id = setInterval(() => {
                setIsLoading(true);
                setClassroomGroup((num) => num !== undefined ? (num + 1) % 10002 : 0);
                // setTimeout(() => {setIsLoading(false)}, 200);
            }, publicView * 1000);
            return () => clearInterval(id);
        }
    }, [publicView]);

    useEffect(() => {
        let longer = false
        let calendarTemp = new Map<number, ICalendarClassroomInfo>()
        let helpDate = new Date(chosenDate)
        helpDate.setHours(0)
        helpDate.setMinutes(0)
        helpDate.setSeconds(0)
        let searchCriteria = {
            statusList: publicView ? ["ACCEPTED"] : statusList,
            eventDate: helpDate.getTime(),
            createdByUserId: onlyMyEvents ? localStorage.getItem("userId") : null,
        }

        setIsLoading(true);

        var error = () => {
            setNetworkError(true);
        }
        areThereLongerClasses({
            hour: MAX_HOUR,
            searchCriteria: searchCriteria
        })
            .then((result: any) => {
                longer = result.data
                if (networkError)
                    setNetworkError(false);
                setLongerTimetable(result.data)
                if (publicView)
                    return getClassroomsWithPublicCategories({query:""})
                else
                    return getClassroomsWithCategories({query: ""})
            }, error)
            .then((result: any) => {
                calendarTemp = new Map<number, ICalendarClassroomInfo>()
                // let allCategories = new Set<number>(result.data.map((classroom: any) => classroom.categoryId));
                let allPublicCategories = new Set<number>(result.data.map((classroom: any) => classroom.publicCategoryId));
                if (categoryNum !== allPublicCategories.size + 1) {
                    setCategoryNum(allPublicCategories.size + 1);
                }
                let thisCategory = Array.from(allPublicCategories)[(classroomGroup % categoryNum) - 1];
                if (publicView) {
                    result.data.filter((classroom: any) => classroomGroup % categoryNum === 0 ? classroom.main : classroom.publicCategoryId === thisCategory).forEach((classroom: any) => {
                        const initialRow: TCalendarRow[] = [];
                        addNewEmptyRow(initialRow, longer);
                        calendarTemp.set(classroom.id, {
                            classroomName: classroom.name,
                            categoryName: classroom.categoryName ? classroom.categoryName : "",
                            publicCategoryName: classroom.publicCategoryName ? classroom.publicCategoryName : "",
                            dataFields: initialRow
                        });
                    })
                } else {
                    result.data.forEach((classroom: any) => {
                        const initialRow: TCalendarRow[] = [];
                        addNewEmptyRow(initialRow, longer);
                        calendarTemp.set(classroom.id, {
                            classroomName: classroom.name,
                            categoryName: classroom.categoryName,
                            publicCategoryName: "",
                            dataFields: initialRow
                        });
                    })
                }
                return searchEvent({searchCriteria: searchCriteria})
            }, error)
            .then((res: any) => {
                // dla każdego eventu szukam miejsca dla niego w kalendarzu
                res.data.forEach((event: any) => {
                    const beginTime = event.startTime.split(':');
                    const endTime = event.endTime.split(':');
                    const beginField = (+beginTime[0] - MIN_HOUR) * 4 + ~~(+beginTime[1] / 15);
                    const endField = (+endTime[0] - MIN_HOUR) * 4 + ~~((+endTime[1]) / 15) - 1;

                    if (!(event.repeating && (chosenDate.getDay() == 6 || chosenDate.getDay() == 0))) {
                        event.classrooms.forEach((classroomId: number) => {
                            const calendarClassroomInfo = calendarTemp.get(classroomId);
                            if (calendarClassroomInfo === undefined) return; // return w forEach znaczy to samo co continue
                            let found = false;
                            calendarClassroomInfo.dataFields.forEach((row) => {
                                if (!found) {
                                    // sprawdzam czy row[i].eventId === 0 dla beginField <= i <= endField (jest wolne miejsce w kalendarzu)
                                    let i: number;
                                    for (i = beginField; i <= endField; i += 1) {
                                        if (row[i].eventId !== 0) break;
                                    }
                                    if (i !== endField + 1) {
                                        return;
                                    }
                                    // else jest wolne
                                    found = true
                                    addEventInCalendar(beginField, endField, beginTime, endTime, row, event);
                                }
                            });
                            if (!found) { // nie znaleziono pustego wiersza, trzeba dodać nowy
                                addNewEmptyRow(calendarClassroomInfo.dataFields, longer);
                                addEventInCalendar(beginField, endField, beginTime, endTime, calendarClassroomInfo.dataFields[calendarClassroomInfo.dataFields.length - 1], event);
                            }
                        })
                    }


                });

                calendarTemp.forEach(() => {
                    const dataFields: TCalendarRow[] = [];
                    addNewEmptyRow(dataFields, longer);
                });
                let calendarRowsNr = 0;
                calendarTemp.forEach((value) => {
                    calendarRowsNr += value.dataFields.length;
                })
                setCalendarHeight(publicView ? 30 + 60 * calendarRowsNr : 80 + 50 * calendarRowsNr);
                setCalendar(calendarTemp);
                // if (!publicView)
                    setIsLoading(false);
            }, error)
            .catch(error)

    }, [chosenDate, statusList, onlyMyEvents, publicView, classroomGroup, networkError, categoryNum]);

    const onMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setHorizontalScroll(event.pageX)
        // @ts-ignore
        setHorizontalScrollStart(document.getElementById(calendarId).scrollLeft)
        setScrollingByMouse(true)
    }

    const onMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setHorizontalScroll(event.pageX)
        setScrollingByMouse(false)
    }

    const onMouseMove = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!scrollingByMouse)
            return

        // @ts-ignore
        document.getElementById(calendarId).scrollLeft = (horizontalScrollStart + horizontalScroll - event.pageX)
    }

    const onMouseLeave = () => {
        setScrollingByMouse(false)
    }
    if (!networkError) {
        let publicViewName = publicView ? classroomGroup % categoryNum === 0 ? "full" : "now" : false;

        return (
            <div className={publicView ? classes.publicCalendarDayContent : classes.calendarDayContent} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave}
                 onMouseDown={onMouseDown} onMouseUp={onMouseUp} id={calendarId}>
                {chosenDateIsRestricted ? (
                    <h2>{t('calendar.dayOffFromClasses')}<br/>{restrictionReason}</h2>
                ) : (!publicView || !isLoading) ? (
                    <div style={{height: calendarHeight + "px"}}>
                        <div className={publicViewName == "now" ? classes.timetableNow : classes.timetable} id={"timetable"}>
                            <CalendarClassrooms calendar={calendar}
                                                publicView={publicView ? classroomGroup % categoryNum === 0 ? "full" : "now" : false}
                            />
                            <CalendarTimetable chosenDate={chosenDate} setOpenPreview={setOpenPreview}
                                               calendar={calendar}
                                               setPreviewedEvent={setPreviewedEvent} isLoading={isLoading}
                                               longer={longerTimetable}
                                               viewOnly={viewOnly}
                                               publicView={publicView ? classroomGroup % categoryNum === 0 ? "full" : "now" : false}/>
                        </div>
                    </div>
                ) : ""}
            </div>
        );
    } else {
        return (
            <div style={{textAlign: "center"}}><h2>Wystąpił błąd sieci</h2></div>
        );
    }
}

const useStyles = makeStyles(() => ({
    calendarDayContent: {
        flexDirection: "row",
        justifyContent: "center",
        position: "relative",
        backgroundColor: "#e9f0e9",
        width: "100%",
        overflow: "hidden",
        padding: "30px",
        overflowX: "scroll",
        marginBottom: 60,
    },
    publicCalendarDayContent: {
        flexDirection: "row",
        justifyContent: "center",
        position: "relative",
        backgroundColor: "transparent",
        width: "100%",
        overflow: "hidden",
        padding: "30px",
        overflowX: "hidden",
        overflowY: "hidden",
        marginBottom: 60,
    },

    timetable: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: 0,
        left: 0,
        padding: "30px",
    },

    timetableNow: {
        display: "flex",
        flexDirection: "row",
        position: "absolute",
        top: 0,
        left: 0,
        padding: "30px",
        paddingLeft: "150px"
    },
    // publicTimetable: {
    //     display: "flex",
    //     flexDirection: "row",
    //     position: "relative",
    //     justifyContent: "center",
    //     padding: "30px",
    // },
}));

export default CalendarDayContent;

// todo (w formularzu rezerwacji kalendarz przeładowuje się przy każdej zmianie)
// export default React.memo(CalendarDayContent, ((prevProps, nextProps) => {
//     return prevProps.statusList.toString() === nextProps.statusList.toString()
//         && prevProps.chosenDate.valueOf() === nextProps.chosenDate.valueOf()
//         && prevProps.onlyMyEvents === nextProps.onlyMyEvents
//         && prevProps.scrollToTime.valueOf() === nextProps.scrollToTime.valueOf()
//         && prevProps.viewOnly === nextProps.viewOnly
//         && prevProps.key === nextProps.key;
// }));