import React from 'react';
import {FormControlLabel} from "@mui/material";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface Props {
    label: any;
    text: any;
    width?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
}

const useStyles = makeStyles(() => ({
    textStyle: {
        justifyContent: "center"
    }
}));

const TextWithLabel = ({label, text, width = "200px", labelPlacement = "start", spaceAfterLabel = "30px"}: Props) => {
    const {textStyle} = useStyles();
    if (label === "")
        spaceAfterLabel = "0px";

    return (
        <Box>
            <FormControlLabel
                label={<div style={{width: width}}>{label}</div>}
                control={
                    <div className={textStyle}>{text}</div>
                }
                labelPlacement={labelPlacement}
                sx={{gap: spaceAfterLabel, alignItems: "baseline", cursor: "initial"}}
            />
        </Box>
    )
}

export default TextWithLabel;