import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CustomTextButton from "../../../components/form/CustomTextButton";
import {Colors} from "../../../constants/colors";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {ActionToolbar} from "../../../utils/styled";

interface Props {
    restrictedDateTime: any;
    onDeleteRestrictedDate: any;
    onEditRestrictedDate: any;
}

const RestrictedDateTableRow = ({restrictedDateTime, onDeleteRestrictedDate, onEditRestrictedDate}: Props) => {
    const {t} = useTranslation();
    const classes = useStyles();

    const getTime = (attribute: string) => {
        return restrictedDateTime.repeatingEachYear ?
            restrictedDateTime[attribute].substring(5, 10) :
            restrictedDateTime[attribute].substring(0, 10);
    }

    return (
        <>
            <TableRow className={classes.bigScreenRow}>
                <TableCell>{getTime('startDateTime')}</TableCell>
                <TableCell>{getTime('endDateTime')}</TableCell>
                <TableCell>{restrictedDateTime.reason}</TableCell>
                { localStorage.getItem("role") === "ADMIN" && <TableCell>
                    <ActionToolbar>
                        <CustomTextButton text={t('actions.edit')}
                                          onClick={() => onEditRestrictedDate(restrictedDateTime.id as string)}
                                          backgroundColor={Colors.LIGHT_GREEN}/>
                        <CustomTextButton text={t('actions.delete')}
                                          onClick={() => onDeleteRestrictedDate(restrictedDateTime.id as string)}
                                          backgroundColor={Colors.LIGHT_RED}/>
                    </ActionToolbar>
                </TableCell> }
            </TableRow>
            <TableRow className={classes.smallScreenRow}>
                <TableCell className={classes.mergedCell} colSpan={localStorage.getItem("role") === "ADMIN" ? 4 : 3}>
                    <div className={classes.data}>
                        <div className={classes.time}>{getTime('startDateTime')} - {getTime('endDateTime')}</div>
                        <div>{restrictedDateTime.reason}</div>
                    </div>
                    { localStorage.getItem("role") === "ADMIN" &&
                    <div className={classes.buttons}>
                        <CustomTextButton text={t('actions.edit')}
                                          onClick={() => onEditRestrictedDate(restrictedDateTime.id as string)}
                                          backgroundColor={Colors.LIGHT_GREEN}/>
                        <CustomTextButton text={t('actions.delete')}
                                          onClick={() => onDeleteRestrictedDate(restrictedDateTime.id as string)}
                                          backgroundColor={Colors.LIGHT_RED}/>
                    </div>}
                </TableCell>
            </TableRow>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    bigScreenRow: {
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },

    smallScreenRow: {
        [theme.breakpoints.up('md')]: {
            display: "none",
        },
    },

    mergedCell: {
        display: "flex",
        flexDirection: "column",
    },

    data: {
        display: "flex",
        marginBottom: 10,
    },

    time: {
        marginRight: 20,
        fontWeight: "bold",
        minWidth: 85,
    },

    buttons: {
        display: "flex",
        justifyContent: "center",
    },
}));

export default RestrictedDateTableRow;
