import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Stack} from "@mui/material";
import CustomInputTextarea from "../../components/form/CustomInputTextarea";
import SelectEventStartTime from "../../components/form/SelectEventStartTime";
import TextWithLabel from "../../components/form/TextWithLabel";
import CustomSelectOne from "../../components/form/CustomSelectOne";
import {findEnumValue} from "../../constants/dictType";

import {
    checkIsDateRestrictedUsingPOST as checkIsDateRestricted,
    getDictionariesUsingPOST as getDictionaries,
    getEventUsingGET as getEvent,
    getNewEventUsingGET as getNewEvent,
    getPostFactumStatusesUsingGET as getPostFactumStatuses,
    getRequestStatusesUsingGET as getRequestStatuses,
    saveEventUsingPOST as saveEvent,
} from "../../swagger/vue-api-client";
import CustomAutocomplete from "../../components/form/CustomAutocomplete";
import CustomCalendar from "../../components/form/CustomCalendar";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckIcon from '@mui/icons-material/Check';
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import {ActionToolbar} from "../../utils/styled";
import CustomCheckbox from "../../components/form/CustomCheckBox";
import YesNoSelect from "../../components/form/YesNoSelect";
import CustomInputText from "../../components/form/CustomInputText";
import SelectEventCharacter from "../../components/SelectEventCharacter";
import {toastInfo} from "../../services/InfoService";
import ReservationView from "./ReservationView";
import {Dialog} from 'primereact/dialog';
import {useHistory, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import InputTextWithLabel from "../../components/InputTextWithLabel";
import CalendarDayContent from "../calendar/components/CalendarDayContent";

const getDurations = (eventStartTime: Date) => {
    if (eventStartTime === null || eventStartTime === undefined) return [];
    let maxEndTime = new Date(eventStartTime);
    maxEndTime.setHours(20, 0, 0, 0);
    const maxMiliSec = maxEndTime.valueOf() - eventStartTime.valueOf();
    if (maxMiliSec <= 0) {
        return [];
    }
    const maxReservationTimeUnits = Math.floor(maxMiliSec/1000/60/45);
    if (maxReservationTimeUnits < 0) return [];
    const chooseDurations = new Array(maxReservationTimeUnits);
    let duration = 45;
    for (let i = 0; i < maxReservationTimeUnits; i++) {
        let label = "" + (i + 1) + "x45";
        chooseDurations[i] = {value: duration, label: label};
        duration += 45;
    }
    return chooseDurations;
}

const calculateEventEndTime = (eventStartTime: Date, m: number) => {
    // jakas walidacja? na razie zaloze ze wejscie jest spoko
    let hours = eventStartTime.getHours() + Math.floor(m / 60);
    let minutes = eventStartTime.getMinutes() + m - Math.floor(m / 60) * 60;
    const eventEndTime = new Date(eventStartTime);

    if (minutes >= 60) {
        hours += 1;
        minutes -= 60;
    }
    eventEndTime.setHours(hours);
    eventEndTime.setMinutes(minutes);
    return eventEndTime;
}

const dateToStringTime = (date: Date | undefined) => {
    if (date !== undefined && date !== null) {
        const minutes = date.getMinutes() === 0 ? "00" : date.getMinutes();
        return date.getHours() + ":" + minutes;
    }
    return "";
}

const parseDate = (date: Date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset * 60 * 1000))
    return date.toISOString().split('T')[0]
}

interface Props {
    updateRequestCounts: any;
}
const ReservationFormView = ({updateRequestCounts} : Props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const params = useParams<{ id: string, room: string, time: string}>();
    const id = params.id;
    const room = params.room
    const time = params.time
    const canChangeRoomAndTime = room === undefined && time === undefined
    const isAdminOrPlanner = localStorage.getItem("role") === "ADMIN" || localStorage.getItem("role") === "PLANNER";

    const [loaded, setLoaded] = useState<boolean>(false);
    const [requestStatus, setRequestStatus] = useState<any>(null);
    const [postFactumStatus, setPostFactumStatus] = useState<any>(null);
    const [requestComment, setRequestComment] = useState<any>("");
    const [eventStartTime, setEventStartTime] = useState<Date>();
    const [eventEndTime, setEventEndTime] = useState<Date>();
    const [eventDuration, setEventDuration] = useState(45); // w minutach
    const [eventType, setEventType] = useState<any>(null);
    const [faculty, setFaculty] = useState<any>(null);
    const [degreeCourse, setDegreeCourse] = useState<any>(null);
    const [subject, setSubject] = useState<any>(null);
    const [studentGroup, setStudentGroup] = useState<any>(null);
    const [requiredEquipment, setRequiredEquipment] = useState<any[]>([]);
    const [requiredEquipmentOther, setRequiredEquipmentOther] = useState("");
    const [isRepeating, setIsRepeating] = useState(false);
    const [repeatFrequency, setRepeatFrequency] = useState<any>(null);
    const [repeatEndDate, setRepeatEndDate] = useState<Date>();
    const [repeatsNumber, setRepeatsNumber] = useState();

    const [classroom, setClassroom] = useState<any>(null);
    const [classroom2, setClassroom2] = useState<any>(null);
    const [debriefingRoomRequired, setDebriefingRoomRequired] = useState<any>(false);
    const [debriefingRoom, setDebriefingRoom] = useState<any>(null);
    const [anyClassroom, setAnyClassroom] = useState(false);

    const [teacher, setTeacher] = useState<any>(null);
    const [teacherOther, setTeacherOther] = useState("");

    const [additionalNotes, setAdditionalNotes] = useState("");
    const [eventDate, setEventDate] = useState<Date>();
    const [technicianRequired, setTechnicianRequired] = useState(false);
    const [assignedTechnician, setAssignedTechnician] = useState<any>(null);
    const [chosenDateIsRestricted, setChosenDateIsRestricted] = useState(false);
    const [calendarKey, setCalendarKey] = useState(1);

    const [regulationsAccepted, setRegulationsAccepted] = useState(false);

    const [openPreview, setOpenPreview] = useState(false);

    const [chooseDurations, setChooseDurations] = useState<any[]>([]);
    const [requestStatuses, setRequestStatuses] = useState<any[]>([]);
    const [postFactumStatuses, setPostFactumStatuses] = useState<any[]>([]);

    const [isFirstRenderCourse, setIsFirstRenderCourse] = useState<boolean>(true);
    const [isFirstRenderSubject, setIsFirstRenderSubject] = useState<boolean>(true);
    const [isFirstRenderGroup, setIsFirstRenderGroup] = useState<boolean>(true);

    const [degreeCourseFilters, setDegreeCourseFilters] = useState<any>({
        departmentId: faculty == null ? null : faculty.id,
        dictType: findEnumValue("DEGREE_COURSE"),
    });

    const [subjectFilters, setSubjectFilters] = useState<any>({
        departmentId: faculty == null ? null : faculty.id,
        dictType: findEnumValue("DEGREE_COURSE"),
    });

    const [studentGroupFilters, setStudentGroupFilters] = useState<any>({
        degreeCourseId: degreeCourse == null ? null : degreeCourse.id,
        dictType: findEnumValue("STUDENT_GROUP"),
    });

    const [dateFilters, setDateFilters] = useState<any>(null);

    useEffect(() => {
        setDateFilters({
            eventId: id,
            date: eventDate === undefined ? null : parseDate(eventDate),
            startTime: eventStartTime === undefined ? null : (eventStartTime.getHours() < 10 ? "0" : "") + eventStartTime.getHours() + ":" + (eventStartTime.getMinutes() < 10 ? "0" : "") + eventStartTime.getMinutes(),
            endTime: eventEndTime === undefined ? null : (eventEndTime.getHours() < 10 ? "0" : "") + eventEndTime.getHours() + ":" + (eventEndTime.getMinutes() < 10 ? "0" : "") + eventEndTime.getMinutes(),
            repeating: isRepeating,
            repeatFrequency: repeatFrequency,
            repeatEndDate: repeatEndDate,
            repeatsNumber: repeatsNumber,
        })
    }, [eventDate, eventStartTime, eventEndTime, isRepeating, repeatFrequency, repeatEndDate, repeatsNumber]);

    useEffect(() => {
        setDegreeCourseFilters({
            departmentId: faculty == null ? null : faculty.id,
            dictType: findEnumValue("DEGREE_COURSE"),
        })
        if (!isFirstRenderCourse) {
            setDegreeCourse(null);
        } else if (faculty != null) {
            setIsFirstRenderCourse(false);
        }
        // eslint-disable-next-line
    }, [faculty])

    useEffect(() => {
        setSubjectFilters({
            degreeCourseId: degreeCourse == null ? null : degreeCourse.id,
            dictType: findEnumValue("SUBJECT"),
        })
        if (!isFirstRenderSubject) {
            setSubject(null);
        } else if (degreeCourse != null) {
            setIsFirstRenderSubject(false);
        }
        // eslint-disable-next-line
    }, [degreeCourse])

    useEffect(() => {
        setStudentGroupFilters({
            degreeCourseId: degreeCourse == null ? null : degreeCourse.id,
            dictType: findEnumValue("STUDENT_GROUP"),
        })
        if (!isFirstRenderGroup) {
            setStudentGroup(null);
        } else if (degreeCourse != null) {
            setIsFirstRenderGroup(false);
        }
        // eslint-disable-next-line
    }, [degreeCourse])

    useEffect(() => {
        getRequestStatuses({}).then((res : any) => {
            setRequestStatuses(res.data);
        })
        getPostFactumStatuses({}).then((res : any) => {
            setPostFactumStatuses(res.data);
        })
    }, []);

    useEffect(() => {
        if (id || (room && time)) {
            const getEventFunc : Promise<never> | any = id ? getEvent : getNewEvent
            getEventFunc({id: id, room: room, time: time}).then((res : any) => {
                const event = res.data;
                setEventDate(new Date(Date.parse(event.date)));
                const startTime = new Date(Date.parse(event.date + "T" + event.startTime));
                setEventStartTime(startTime);

                const classrooms = [];
                let j = 0;
                for (let i = 0; i < event.classrooms.length; i++) {
                    if (event.classrooms[i] !== event.debriefingRoom) {
                        classrooms.push({name: event.classroomNames[i], id: event.classrooms[i]});
                        if (j === 0) setClassroom(classrooms[j]);
                        if (j === 1) setClassroom2(classrooms[j]);
                        j++;
                    }
                }
                // setClassrooms(classrooms);
                if (id) {
                    const endTime = new Date(Date.parse(event.date + "T" + event.endTime));
                    setEventEndTime(endTime);
                    setEventDuration((endTime.getTime() - startTime.getTime()) / 60000);
                    setEventType({name: event.eventTypeName, id: event.eventType});
                    setFaculty({name: event.studentGroupSubjectDepartmentName, id: event.studentGroupSubjectDepartmentId});
                    setDegreeCourse({name: event.studentGroupSubjectDegreeCourseName, id: event.degreeCourse});
                    setSubject({name: event.studentGroupSubjectName, id: event.subject});
                    setStudentGroup({name: event.studentGroupName, id: event.studentGroup});

                    const requiredEquipmentList = [];
                    for (let i = 0; i < event.requiredEquipment.length; i++) {
                        requiredEquipmentList.push({name: event.requiredEquipmentNames[i], id: event.requiredEquipment[i]});
                    }
                    setRequiredEquipment(requiredEquipmentList);

                    setRequiredEquipmentOther(event.requiredEquipmentOther);
                    setIsRepeating(event.repeating);
                    if (event.repeatFrequency)
                        setRepeatFrequency(event.repeatFrequency);
                    if (event.repeatEndDate) {
                        setRepeatEndDate(new Date(Date.parse(event.repeatEndDate.substring(0, 10))));
                    } if (event.repeatsNumber)
                        setRepeatsNumber(event.repeatsNumber);
                    if (event.teacher) {
                        setTeacher({name: event.teacherFullNameWithTitle, id: event.teacher});
                    }
                    setTeacherOther(event.teacherOther);

                    setTechnicianRequired(event.technicianRequired);
                    setAssignedTechnician({name: event.technicianName, id: event.technicianId});

                    setRegulationsAccepted(true);
                    setAdditionalNotes(event.additionalNotes);
                    setRequestStatus(event.requestStatus);
                    setPostFactumStatus(event.postFactumStatus);
                    setRequestComment(event.requestComment);

                    setDebriefingRoomRequired(event.debriefingRoomRequired);
                    setDebriefingRoom({name: event.debriefingRoomName, id: event.debriefingRoom});
                }
                setLoaded(true);
            }).catch(() => {
                toastInfo("error", t('errorOccurred'));
            });
        } else {
            setEventDate(new Date());
        }
    }, [room, id, time, t]);

    useEffect(() => {
        // policz koniec zajęć
        if (eventStartTime !== undefined && eventDuration !== undefined) {
            setEventEndTime(calculateEventEndTime(eventStartTime, eventDuration));
        }
    }, [eventStartTime, eventDuration]);

    useEffect(() => {
        if (repeatsNumber !== undefined) {
            setRepeatEndDate(undefined);
        }
    }, [repeatsNumber]);

    useEffect(() => {
        if (repeatEndDate !== undefined) {
            setRepeatsNumber(undefined);
        }
    }, [repeatEndDate]);

    useEffect(() => {
        if (eventStartTime !== undefined){
            const durations = getDurations(eventStartTime);
            setChooseDurations(durations);
            if (durations.length !== 0 && durations[durations.length - 1].value < eventDuration) {
                setEventDuration(45);
            }
        }
        setCalendarKey((calendarKey + 1) % 2);
    }, [eventStartTime])

    useEffect(() => {
        if (eventDate === null || eventDate === undefined) return;
        const timezoneOffset = eventDate.getTimezoneOffset() * 60000;
        const date = (new Date(eventDate.getTime() - timezoneOffset)).toISOString().substring(0, eventDate.toISOString().length - 5);
        checkIsDateRestricted({dateString: date}).then((res: any) => {
            if (res.data) {
                setChosenDateIsRestricted(true);
            } else {
                if (!isAdminOrPlanner && (eventDate.getDay() == 6 || eventDate.getDay() == 0)) {
                    setChosenDateIsRestricted(true);
                } else {
                    setChosenDateIsRestricted(false);
                }
            }
        })
    }, [eventDate])

    const dateIsNotChosen = () => {
        return eventDate === null || eventDate === undefined
            || eventStartTime === null
            || isRepeating === null
            || (isRepeating
                && (repeatFrequency === null ||
                    ((repeatEndDate === null || repeatEndDate === undefined) && (repeatsNumber === null || repeatsNumber === undefined))))
    }

    const makeReservation = () => {
        if ((!isAdminOrPlanner && chosenDateIsRestricted) || eventType === null || (!anyClassroom && (classroom === null || classroom.id === 0)) || ((teacher === null || teacher.id === 0) && !teacherOther) || !eventDate
            || (subject === null || subject.id === 0) || (faculty === null || faculty.id === 0) || (degreeCourse === null || degreeCourse.id === 0)
            || !eventStartTime || !eventEndTime || (studentGroup === null || studentGroup.id === 0)
            || (id && requestStatus === "ACCEPTED" && technicianRequired && (assignedTechnician === null || assignedTechnician.id === 0))
            || (id && requestStatus === "ACCEPTED" && debriefingRoomRequired && (debriefingRoom === null || debriefingRoom.id === 0))
            || (id && requestStatus === "ACCEPTED" && anyClassroom && (classroom === null || classroom.id === 0))
            || (!id && technicianRequired && isAdminOrPlanner && (assignedTechnician === null || assignedTechnician.id === 0))
            || (!id && debriefingRoomRequired && isAdminOrPlanner && (debriefingRoom === null || debriefingRoom.id === 0))
            || (!id && anyClassroom && isAdminOrPlanner && (classroom === null || classroom.id === 0))
            || (isRepeating && !repeatFrequency) || technicianRequired === null || anyClassroom === null || debriefingRoomRequired === null || isRepeating === null){
            toastInfo("error", t('reservation.notAllFields'));
            return;
        }
        if (!regulationsAccepted) {
            toastInfo("error", t('reservation.regulationsNotAccepted'));
            return;
        }
        if ((teacher !== null && teacher.id !== 0) && teacherOther) {
            toastInfo("error", t('reservation.onlyOneTeacher'));
            return;
        }

        let classrooms : any;
        if (anyClassroom) {
            classrooms = [];
        } else if ((classroom2 === null || classroom2.id === 0) && (debriefingRoom === null || debriefingRoom.id === 0)) {
            classrooms = [classroom.id];
        } else if (!(classroom2 === null || classroom2.id === 0) && (debriefingRoom === null || debriefingRoom.id === 0)) {
            classrooms = [classroom.id, classroom2.id, null];
        } else if ((classroom2 === null || classroom2.id === 0) && !(debriefingRoom === null || debriefingRoom.id === 0)) {
            classrooms = [classroom.id, null, debriefingRoom.id];
        } else {
            classrooms = [classroom.id, classroom2.id, debriefingRoom.id];
        }

        const eventDto = {
            id : id,
            requestStatus: requestStatus,
            postFactumStatus: postFactumStatus,
            requestComment: requestComment,
            date: parseDate(eventDate),
            startTime: (eventStartTime.getHours() < 10 ? "0" : "") + eventStartTime.getHours() + ":" + (eventStartTime.getMinutes() < 10 ? "0" : "") + eventStartTime.getMinutes(),
            endTime: (eventEndTime.getHours() < 10 ? "0" : "") + eventEndTime.getHours() + ":" + (eventEndTime.getMinutes() < 10 ? "0" : "") + eventEndTime.getMinutes(),
            repeating: isRepeating,
            repeatFrequency: repeatFrequency === undefined ? null : repeatFrequency,
            repeatEndDate: repeatEndDate === undefined ? null : repeatEndDate,
            repeatsNumber: repeatsNumber === undefined ? null : repeatsNumber,
            eventType: eventType.id,
            classrooms: classrooms,
            debriefingRoom: debriefingRoom === null ? null : debriefingRoom.id,
            debriefingRoomRequired: debriefingRoomRequired,
            anyClassroom: anyClassroom,
            teacher: teacher === null ? 0 : teacher.id,
            teacherOther: teacherOther,
            studentGroup: studentGroup.id,
            subject: subject.id,
            degreeCourse: degreeCourse.id,
            technicianRequired: technicianRequired,
            technicianId: assignedTechnician == null ? null : assignedTechnician.id,
            additionalNotes: additionalNotes,
            requiredEquipment: requiredEquipment.map((e) => (e.id)),
            requiredEquipmentOther: requiredEquipmentOther,
        }

        saveEvent({eventDto: eventDto})
            .then((res: any) => {
                if (res && res.data && (res.data.restrictedDateReason)) {
                    toastInfo("error", t('reservation.restrictedDateSelected') + res.data.restrictedDateReason);
                } else if (res && res.data && (res.data.classroomNotFree || res.data.teacherNotFree || res.data.groupNotFree)) {
                    let message =
                        res.data.classroomNotFree && !res.data.teacherNotFree && !res.data.groupNotFree ? t('reservation.notFree.classroom') + res.data.troubleDate :
                            res.data.classroomNotFree && res.data.teacherNotFree && !res.data.groupNotFree ? t('reservation.notFree.teacherAndClassroom') + res.data.troubleDate :
                                res.data.classroomNotFree && !res.data.teacherNotFree && res.data.groupNotFree ? t('reservation.notFree.classroomAndGroup') + res.data.troubleDate :
                                    !res.data.classroomNotFree && res.data.teacherNotFree && !res.data.groupNotFree ? t('reservation.notFree.teacher') + res.data.troubleDate :
                                        !res.data.classroomNotFree && res.data.teacherNotFree && res.data.groupNotFree ? t('reservation.notFree.groupAndTeacher') + res.data.troubleDate :
                                            !res.data.classroomNotFree && !res.data.teacherNotFree && res.data.groupNotFree ? t('reservation.notFree.group') + res.data.troubleDate :
                                                t('reservation.notFree.classroomAndGroupAndTeacher') + res.data.troubleDate;
                    toastInfo("error", message);
                    updateRequestCounts();
                } else {
                    toastInfo("success", id ? t('reservation.saved') : t('reservation.sent'));
                    updateRequestCounts();
                    eventDate.setHours(eventStartTime.getHours())
                    eventDate.setMinutes(eventStartTime.getMinutes())
                    history.push({pathname: "/home", state: {date: eventDate}});
                }
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
                updateRequestCounts();
            });
    }

    const labelPlacement = window.innerWidth < 650 ? "top" : "start";
    const spaceAfterLabel = window.innerWidth < 650 ? "5px" : "30px";

    return (
        <div className={classes.reservationForm}>
            {(!id || loaded) &&
                <>
                    <div className={classes.headerContainer}>
                        {!id && <h2>{t('reservation.title.new')}</h2> }
                        {id && <h2>{t('reservation.title.edit')}</h2> }
                    </div>
                    <div className={classes.content} style={window.innerWidth >= 1200 ? {width: "100%", display: "flex"} : {width: "100%"}}>
                        {window.innerWidth < 1200 && eventDate !== null && eventDate !== undefined && !chosenDateIsRestricted && eventStartTime !== undefined &&
                            <CalendarDayContent statusList={["ACCEPTED"]} chosenDate={eventDate} setOpenPreview={()=>{}}
                                                setPreviewedEvent={()=>{}} onlyMyEvents={false} scrollToTime={eventStartTime}
                                                setScrollToTime={setEventStartTime}
                                                viewOnly={true} key={calendarKey}/>}
                        <Stack spacing={3} mb={5}>
                            {(id && requestStatus === "NEGOTIATING") &&
                                <div>
                                    {isAdminOrPlanner ? (
                                        <div style={{marginBottom: "25px", marginLeft: "13px"}}>
                                            <CustomInputText value={requestComment} setValue={setRequestComment}
                                                             label={t('reservation.requestComment')}/>
                                        </div>
                                    ) : (
                                        <div><h3>{t('reservation.requestComment')}: </h3> <p><b>{requestComment}</b></p></div>
                                    )}
                                </div>
                            }
                            {id && isAdminOrPlanner ? (
                                <>
                                    <CustomSelectOne value={requestStatus} setValue={setRequestStatus} labelWidth={"250px"}
                                                     items={requestStatuses} label={t('reservation.status')}
                                                     labelPlacement={labelPlacement} spaceAfterLabel={spaceAfterLabel}/>
                                    <CustomSelectOne showClear={true} value={postFactumStatus} setValue={setPostFactumStatus}
                                                     items={postFactumStatuses} label={t('reservation.postFactumStatus')}
                                                     labelPlacement={labelPlacement} spaceAfterLabel={spaceAfterLabel}
                                                     labelWidth={"250px"}/>
                                </>
                            ) : null}
                            <CustomCalendar value={eventDate} setValue={setEventDate} label={t('reservation.date')} disabled={!canChangeRoomAndTime}
                                            width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}
                                            errorMsg={(!isAdminOrPlanner && chosenDateIsRestricted) ? t('reservation.chosenDateRestricted') : ""}/>

                            <SelectEventStartTime value={eventStartTime} setValue={setEventStartTime} disabled={!canChangeRoomAndTime}
                                                  label={t('reservation.startTime')} initialDate={eventStartTime}
                                                  width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <CustomSelectOne value={eventDuration} setValue={setEventDuration}
                                             items={chooseDurations} label={t('reservation.eventDuration')}
                                             labelWidth={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <TextWithLabel label={t('reservation.endTime')} text={dateToStringTime(eventEndTime)}
                                           width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <SelectEventCharacter isRepeating={isRepeating} setIsRepeating={setIsRepeating} repeatFrequency={repeatFrequency}
                                                  setRepeatFrequency={setRepeatFrequency} repeatEndDate={repeatEndDate} setRepeatEndDate={setRepeatEndDate}
                                                  repeatsNumber={repeatsNumber} setRepeatsNumber={setRepeatsNumber}
                                                  width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <CustomAutocomplete value={eventType} setValue={setEventType} completeMethod={getDictionaries}
                                                filters={{dictType: findEnumValue("EVENT_TYPE")}} label={t('reservation.eventType')}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <CustomAutocomplete value={faculty} setValue={setFaculty} completeMethod={getDictionaries}
                                                filters={{dictType: findEnumValue("DEPARTMENT")}} label={t('reservation.departmentName')}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <CustomAutocomplete value={degreeCourse} setValue={setDegreeCourse} completeMethod={getDictionaries}
                                                filters={degreeCourseFilters} label={t('reservation.degreeCourse')} disabled={faculty === null || faculty.id === 0}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <CustomAutocomplete value={subject} setValue={setSubject} completeMethod={getDictionaries}
                                                filters={subjectFilters} label={t('reservation.subjectName')} disabled={degreeCourse === null || degreeCourse.id === 0}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <CustomAutocomplete value={studentGroup} setValue={setStudentGroup} completeMethod={getDictionaries}
                                                filters={studentGroupFilters} label={t('reservation.studentGroup')} disabled={degreeCourse === null || degreeCourse.id === 0}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            {canChangeRoomAndTime ? (
                                <div style={{display: "inline-flex"}}>
                                    <div >
                                        <YesNoSelect value={anyClassroom} setValue={setAnyClassroom} label={t('reservation.anyClassroom')}
                                            width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}
                                            infoMsg={anyClassroom ? t('reservation.anyClassroomInfo') : ""}/>
                                    </div>
                                </div>
                            ) : null}

                            {(!anyClassroom || isAdminOrPlanner) && <React.Fragment>
                                <CustomAutocomplete value={classroom} setValue={setClassroom} completeMethod={getDictionaries}
                                                    filters={{dictType: findEnumValue("CLASSROOM")}} label={t('reservation.classroom')}
                                                    width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement} canChangeRoomAndTime={canChangeRoomAndTime}
                                                    disabled={!canChangeRoomAndTime || dateIsNotChosen()} dateFilters={dateFilters} classroom2={classroom2}
                                                    debriefingRoom={debriefingRoom} errorMsg = {t('reservation.changeClassroom')}
                                />

                                <CustomAutocomplete value={classroom2} setValue={setClassroom2} completeMethod={getDictionaries}
                                                    filters={{dictType: findEnumValue("CLASSROOM")}} label={t('reservation.classroom2')}
                                                    width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement} emptyValue={true}
                                                    disabled={dateIsNotChosen()} dateFilters={dateFilters} classroom={classroom} debriefingRoom={debriefingRoom}/>
                            </React.Fragment>}

                            <YesNoSelect value={debriefingRoomRequired} setValue={setDebriefingRoomRequired} label={t('reservation.debriefingRoomRequired')}
                                         width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>
                            { (debriefingRoomRequired && isAdminOrPlanner) &&
                                <CustomAutocomplete value={debriefingRoom} setValue={setDebriefingRoom} completeMethod={getDictionaries}
                                                    filters={{dictType: findEnumValue("CLASSROOM")}} dateFilters={dateFilters} label={t('reservation.debriefingClassroom')}
                                                    width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}
                                                    disabled={dateIsNotChosen()} classroom={classroom} classroom2={classroom2}/>
                            }
                            <CustomAutocomplete value={teacher} setValue={setTeacher} completeMethod={getDictionaries}
                                                filters={{dictType: findEnumValue("TEACHER")}} dateFilters={dateFilters} label={t('reservation.teacher')}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}
                                                disabled={dateIsNotChosen()}/>

                            <InputTextWithLabel label={t('reservation.teacherOther')} value={teacherOther}
                                                setValue={setTeacherOther} placeholder={t('reservation.enterManually')}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <CustomAutocomplete value={requiredEquipment} setValue={setRequiredEquipment} completeMethod={getDictionaries}
                                                filters={{dictType: findEnumValue("EQUIPMENT")}} dateFilters={dateFilters} label={t('reservation.requiredEquipment')}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement} multiple={true}
                                                disabled={dateIsNotChosen()}/>

                            <InputTextWithLabel label={t('reservation.requiredEquipmentOther')} value={requiredEquipmentOther}
                                                setValue={setRequiredEquipmentOther} placeholder={t('reservation.enterManually')}
                                                width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            <YesNoSelect value={technicianRequired} setValue={setTechnicianRequired} label={t('reservation.technicianRequired')}
                                         width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>

                            { (technicianRequired && isAdminOrPlanner) &&
                                <CustomAutocomplete value={assignedTechnician} setValue={setAssignedTechnician} completeMethod={getDictionaries}
                                                    filters={{dictType: findEnumValue("TECHNICIAN")}} dateFilters={dateFilters} label={t('reservation.technician')}
                                                    width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}
                                                    disabled={dateIsNotChosen()}/>
                            }

                        </Stack>
                        <div style={{width: "60%", paddingLeft: "50px", paddingTop: "100px"}}>
                            {window.innerWidth >= 1200 && eventDate !== null && eventDate !== undefined && !chosenDateIsRestricted &&
                                <CalendarDayContent statusList={["ACCEPTED"]} chosenDate={eventDate} setOpenPreview={()=>{}}
                                                    setPreviewedEvent={()=>{}} onlyMyEvents={false} scrollToTime={eventStartTime as Date}
                                                    setScrollToTime={eventStartTime}
                                                    viewOnly={true}/>}
                        </div>
                    </div>
                    <Stack spacing={3} mb={5}>
                        <CustomInputTextarea value={additionalNotes} setValue={setAdditionalNotes} label={t('reservation.additionalNotes')}/>
                        { !id && <CustomCheckbox value={regulationsAccepted} setValue={setRegulationsAccepted}
                                                 label={<div>{t('reservation.accept')} <a href={"/"} style={{textDecoration: "none"}}>{t('reservation.regulations')}</a></div>}
                        />}
                    </Stack>
                </> }
            {!id &&
                <ActionToolbar className={classes.buttons}>
                    <CustomTextButton width={"160px"} height={"60px"} backgroundColor={Colors.BEIGE}
                                      onClick={() => {setOpenPreview(true);}} text={t('reservation.preview')} icon={<RemoveRedEyeIcon/>}/>
                    <CustomTextButton width={"300px"} height={"60px"} backgroundColor={Colors.LIGHT_GREEN}
                                      onClick={() => {makeReservation()}}
                                      text={t('reservation.makeReservationForConsideration')} icon={<CheckIcon/>}/>
                </ActionToolbar> }
            {(id && loaded) &&
                <ActionToolbar>
                    <CustomTextButton width={"300px"} height={"60px"} backgroundColor={Colors.LIGHT_GREEN}
                                      onClick={() => {makeReservation()}} text={t('reservation.confirm')} icon={<CheckIcon/>}/>
                </ActionToolbar>}
            { !id && <Dialog header={t('reservation.preview')} visible={openPreview}
                             style={{width: (window.innerWidth < 960 ? '95vw' : '55vw')}} onHide={() => setOpenPreview(false)}>
                <ReservationView decisionView={false} event={{
                    date: !eventDate ? t('actions.none') : eventDate.toLocaleDateString(),
                    startTime: eventStartTime?.toTimeString().substring(0, 5),
                    endTime: eventEndTime?.toTimeString().substring(0, 5),
                    eventType: eventType === null ? t('actions.none') : eventType.name,
                    departmentName: faculty === null ? t('actions.none') : faculty.name,
                    degreeCourse: degreeCourse === null ? t('actions.none') : degreeCourse.name,
                    subjectName: subject === null ? t('actions.none') : subject.name,
                    studentGroup: studentGroup === null ? t('actions.none') : studentGroup.name,
                    anyClassroom: anyClassroom ? t('actions.yes') : t('actions.no'),
                    classroom: classroom === null ? t('actions.none') : classroom.name,
                    classroom2: classroom2 === null ? t('actions.none') : classroom2.name,
                    // classrooms: classrooms.length === 0 ? t('actions.none') : classrooms.map((c) => c.name).join(", "),
                    teachers: teacher === null && teacherOther === "" ? t('actions.none') : teacher === null ? teacherOther : teacherOther === "" ? teacher.name : teacher.name + ", " + teacherOther,
                    requiredEquipment: requiredEquipment.length === 0 ? (requiredEquipmentOther === "" ? t('actions.none') : requiredEquipmentOther) : requiredEquipment.map((e) => e.name).join(", ") + (requiredEquipmentOther === "" ? "" : ", " + requiredEquipmentOther),
                    technicianRequired: technicianRequired ? t('actions.yes') : t('actions.no'),
                    debriefingRoomRequired: debriefingRoomRequired ? t('actions.yes') : t('actions.no'),
                    isRepeating: isRepeating ? t('calendar.cyclical') : t('calendar.oneTime'),
                    repeatFrequency: !isRepeating || repeatFrequency === null ? t('actions.none') : repeatFrequency,
                    repeatEndDate: !isRepeating ? t('actions.none') : repeatEndDate === undefined ? t('actions.none') : repeatEndDate.toLocaleDateString(),
                    repeatsNumber: !isRepeating ? t('actions.none') : repeatsNumber === undefined ? t('actions.none') : repeatsNumber,
                    additionalNotes: additionalNotes,
                }}/>
            </Dialog> }
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    reservationForm: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    content: {
        maxWidth: "100%",
    },

    rowElement: {
        display: "flex",
        flexDirection: "row",
    },

    buttons: {
        display: "flex",
        flexWrap: "wrap",
    }
}));

export default ReservationFormView;