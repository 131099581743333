export enum constDictType  {
    CLASSROOM,
    DEGREE_COURSE,
    DEPARTMENT,
    EQUIPMENT,
    EVENT_TYPE,
    STUDENT_GROUP,
    SUBJECT,
    TEACHER,
    TECHNICIAN,
    CATEGORY,
    PUBLIC_CATEGORY,
    NULL,
}

export enum fields  {
    NAME,
    LENGTH_IN_SEMESTERS,
    FIRST_NAME,
    LAST_NAME,
    TITLE,
    STUDENT_COUNT,
    TILE_COLOR_HEX,
    FONT_COLOR_HEX,
    ORGANIZATION_UNIT,
    NUMBER,
    ACTIVE,
    SEMESTER_NUMBER,
    MAIN,
}

export const findEnumValue = (dictTypeString: string) => {
    if (dictTypeString === "CLASSROOM") {
        return constDictType.CLASSROOM;
    } else if (dictTypeString === "DEGREE_COURSE") {
        return  constDictType.DEGREE_COURSE;
    } else if (dictTypeString === "DEPARTMENT") {
        return constDictType.DEPARTMENT;
    } else if (dictTypeString === "EQUIPMENT") {
        return constDictType.EQUIPMENT;
    } else if (dictTypeString === "EVENT_TYPE") {
        return constDictType.EVENT_TYPE;
    } else if (dictTypeString === "STUDENT_GROUP") {
        return constDictType.STUDENT_GROUP;
    } else if (dictTypeString === "SUBJECT") {
        return constDictType.SUBJECT;
    } else if (dictTypeString === "TEACHER") {
        return constDictType.TEACHER;
    } else if (dictTypeString === "CATEGORY") {
        return constDictType.CATEGORY;
    } else if (dictTypeString === "PUBLIC_CATEGORY") {
        return constDictType.PUBLIC_CATEGORY;
    }
    else {
        return constDictType.TECHNICIAN;
    }
}

export const aboveDictionaryType = (dictType: constDictType) => {
    if (dictType === constDictType.CLASSROOM) {
        return constDictType.DEPARTMENT;
    } else if (dictType === constDictType.DEGREE_COURSE) {
        return constDictType.DEPARTMENT;
    } else if (dictType === constDictType.STUDENT_GROUP) {
        return constDictType.SUBJECT;
    } else if (dictType === constDictType.SUBJECT) {
        return constDictType.DEGREE_COURSE;
    }
}

export const getLabelOfDictionary = (dictType: constDictType, t: any) => {
    if (dictType === constDictType.CLASSROOM) {
        return t('dictionary.classroom');
    } else if (dictType === constDictType.DEGREE_COURSE) {
        return t('dictionary.degreeCourseName');
    } else if (dictType === constDictType.DEPARTMENT) {
        return t('dictionary.departmentName');
    } else if (dictType === constDictType.EQUIPMENT) {
        return t('dictionary.equipment');
    } else if (dictType === constDictType.EVENT_TYPE) {
        return t('dictionary.eventType');
    }  else if (dictType === constDictType.STUDENT_GROUP) {
        return t('dictionary.studentGroup');
    } else if (dictType === constDictType.SUBJECT) {
        return t('dictionary.subjectName');
    }  else if (dictType === constDictType.TEACHER) {
        return t('dictionary.teacher');
    } else if (dictType === constDictType.TECHNICIAN) {
        return t('dictionary.technician');
    } else if (dictType === constDictType.CATEGORY) {
        return t('dictionary.category');
    } else if (dictType === constDictType.PUBLIC_CATEGORY) {
        return t('dictionary.publicCategory');
    }
}

export const getDictionaryTypeString = (dictType: constDictType) => {
    if (dictType === constDictType.CLASSROOM) {
        return "CLASSROOM";
    } else if (dictType === constDictType.DEGREE_COURSE) {
        return "DEGREE_COURSE";
    } else if (dictType === constDictType.DEPARTMENT) {
        return "DEPARTMENT";
    } else if (dictType === constDictType.EQUIPMENT) {
        return "EQUIPMENT";
    } else if (dictType === constDictType.EVENT_TYPE) {
        return "EVENT_TYPE";
    }  else if (dictType === constDictType.STUDENT_GROUP) {
        return "STUDENT_GROUP";
    } else if (dictType === constDictType.SUBJECT) {
        return "SUBJECT";
    }  else if (dictType === constDictType.TEACHER) {
        return "TEACHER";
    } else if (dictType === constDictType.TECHNICIAN) {
        return "TECHNICIAN";
    } else if (dictType === constDictType.CATEGORY) {
        return "CATEGORY";
    } else if (dictType === constDictType.PUBLIC_CATEGORY) {
        return "PUBLIC_CATEGORY";
    }

    return null;
}