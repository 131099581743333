import React, {useEffect, useState} from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    TableContainer
} from "@material-ui/core";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import {useTranslation} from "react-i18next";
import {
    deleteRestrictedDateTimeUsingPOST,
    getAllRestrictedDateTimesUsingPOST as getAllRestrictedDateTimes
} from "../../swagger/vue-api-client";
import {toastInfo} from "../../services/InfoService";
import RestrictedDateTableRow from "./components/RestrictedDateTableRow";

const RestrictedDateListView = () => {
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState(false);
    const [restrictionToDelete, setRestrictionToDelete] = useState("");
    const history = useHistory();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [restrictedDateTimes, setRestrictedDateTimes] = useState<any>(null);
    const [updateDateTimes, setUpdateDateTimes] = useState(false);

    const classes = useStyles();

    const editRestrictedDate = (id : string) => {
        history.push({pathname: "/restricted-dates/" + id});
    };

    const deleteRestrictedDate = (id : string) => {
        setOpenConfirmDeleteDialog(true);
        setRestrictionToDelete(id);
    };

    const doDeleteRestrictedDate = () => {
        deleteRestrictedDateTimeUsingPOST({id: restrictionToDelete}).then(() => {
            setUpdateDateTimes(!updateDateTimes);
            setOpenConfirmDeleteDialog(false);
        }).catch(() => {
            toastInfo("error", t('errorOccurred'));
        });
    }

    const goToMakeNew = () => {
        history.push({pathname: "/restricted-dates/new"});
    };

    useEffect(() => {
        getAllRestrictedDateTimes()
            .then((res : any) => {
                setRestrictedDateTimes(res.data);
                setIsLoading(false);
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
                setIsLoading(false);
            });
    }, [updateDateTimes, t]);

    const sortDates = (arr: any[]) => {
        arr.sort(function (e1: any, e2: any) {
            return (new Date(e2.startDateTime)).getTime() - (new Date(e1.startDateTime)).getTime();
        });

        return arr;
    }

    return (
        <div className={classes.restrictedDateList}>
            <div className={classes.headerContainer}>
                <div className={classes.header}><h2>{t('restrictedDate.title')}</h2></div>
                { localStorage.getItem("role") === "ADMIN" &&
                <CustomTextButton width={"160px"} backgroundColor={Colors.LIGHT_GREY}
                                  onClick={() => {goToMakeNew()}}
                                  text={t('restrictedDate.addButton')}
                                  icon={<AddIcon/>}/> }
            </div>
            <div className={classes.content}>
            {isLoading ? <CircularProgress/> :
                <TableContainer component={Paper} className={classes.restrictedDateList}>
                    <Table>
                        <TableHead>
                            <TableRow className={classes.tableHeaderRow}>
                                <TableCell width={100}>{t('restrictedDate.from')}</TableCell>
                                <TableCell width={100}>{t('restrictedDate.to')}</TableCell>
                                <TableCell>{t('restrictedDate.reason')}</TableCell>
                                <TableCell width={60}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(!restrictedDateTimes || restrictedDateTimes.length === 0) && <TableRow><TableCell>{t('restrictedDate.notFound')}</TableCell></TableRow>}
                            {sortDates(restrictedDateTimes).map((restrictedDateTime: any) => (
                                <RestrictedDateTableRow
                                    restrictedDateTime={restrictedDateTime}
                                    onDeleteRestrictedDate={deleteRestrictedDate}
                                    onEditRestrictedDate={editRestrictedDate}
                                    key={restrictedDateTime.id}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
            <Dialog open={openConfirmDeleteDialog} onClose={() => setOpenConfirmDeleteDialog(false)}
                    PaperProps={{
                        style: {
                            minHeight: "20vh",
                            minWidth: "30vw"
                        }
                    }}>
                <DialogTitle>{t('restrictedDate.deleteLabel')}</DialogTitle>
                <DialogContent><DialogContentText>{t('restrictedDate.deleteLabelWarning')}</DialogContentText></DialogContent>
                <DialogActions>
                    <CustomTextButton onClick={() => setOpenConfirmDeleteDialog(false)} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={() => doDeleteRestrictedDate()} backgroundColor={Colors.LIGHT_RED} text={t('actions.delete')}/>
                </DialogActions>
            </Dialog>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    tableHeaderRow: {
        '& > *': {
            fontWeight: "bold",
        },
        [theme.breakpoints.down('sm')]: {
            '& > *': {
                display: "none",
            },
        },
    },

    restrictedDateList: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    header: {
        paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },

    content: {
        width: "100%",
        minHeight: 400,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

export default RestrictedDateListView;
