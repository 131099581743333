import React, {useState} from 'react'
import CustomInputText from "../components/form/CustomInputText";
import {makeStyles} from "@material-ui/core/styles";
import CustomTextButton from "../components/form/CustomTextButton";
import {Colors} from "../constants/colors";
import {Link, useHistory} from "react-router-dom";
import {loginUsingPOST} from "../swagger/vue-api-client";
import 'react-toastify/dist/ReactToastify.css';
import {toastInfo} from "../services/InfoService";
import styled from "styled-components";
import umbLogoWithName from '../assets/logo-i-orzel.png';
import {useTranslation} from "react-i18next";

const LoginView = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const classes = useStyles();
    const {t} = useTranslation();

    const history = useHistory();

    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("fullName");
    localStorage.removeItem("userId");

    const signInFunction = () => {
        loginUsingPOST({authenticationRequest: {login: email, password: password}}).then((res: any) => {
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role", res.data.role);
            localStorage.setItem("fullName", res.data.fullName);
            localStorage.setItem("userId", res.data.userId);

            history.push({pathname: "/home", state: {user: res.data}});

            toastInfo("success", t('login.loggedIn'));
        }).catch((error: any) => {
            if (error.response.status === 400) {
                toastInfo("error", t('login.confirmEmail'));
            } else {
                toastInfo("error", t('login.incorrectData'));
            }
        });
    }

    const signUpFunction = () => {
        history.push("/register");
    }

    return (
        <div>
            <div className={classes.logoContainer}>
                <img src={umbLogoWithName} alt={t('login.logo')} height={120} style={{marginLeft: 90}}/>
            </div>
            <div className={classes.container}>
                <div style={{marginTop: 20}}>
                    <h1 style={{display: "inline"}}>{t('login.title')}</h1>
                </div>
                <CustomInputText value={email} setValue={setEmail} label={t('login.email')} margin="auto"
                                 onKeyPress={() => signInFunction()} width="300px"/>
                <CustomInputText value={password} setValue={setPassword} label={t('login.password')} password={true} margin="auto"
                                 onKeyPress={() => signInFunction()} width="300px"/>
                <br />
                <div style={{marginTop: '-5px'}}>
                    <StyledLink to={"/remind-password"}>{t('login.remindPasswordButton')}</StyledLink>
                </div>
                <div style={{marginLeft: 40}}>
                    <CustomTextButton onClick={() => signInFunction()} backgroundColor={Colors.LIGHT_GREEN} text={t('login.submitButton')} width="300px"/>
                </div>
                <div style={{marginTop: 20}}>
                    <StyledLink to={"/register"}>{t('login.noAccountLabel')}</StyledLink>
                </div>
                <div style={{marginLeft: 40, marginTop: -5}}>
                    <CustomTextButton onClick={() => signUpFunction()} backgroundColor={Colors.LIGHT_BROWN} text={t('login.registerButton')} width="300px"/>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    logoContainer: {
        display: "flex",
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        marginTop: 80,
    },
    container: {
        height: 355,
        width: 400,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        border: 3,
        borderColor: Colors.PRIMARY_GREEN,
        color: Colors.PRIMARY_GREEN,
        borderStyle: "ridge",
        margin: "auto",
        marginTop: 30,
        borderRadius: 10,
        fontFamily: 'PT Sans',
    },
    a: {
        color: Colors.PRIMARY_GREEN,
    }
}))

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: black;
  &:hover, &:active, &:visited {
    color: black;
  }
`;

export default LoginView;