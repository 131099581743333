import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getEventUsingGET} from "../../../swagger/vue-api-client";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ICalendarClassroomInfo, ICalendarField, TCalendarRow} from "../../../interfaces";
import CalendarDayContent from "./CalendarDayContent";
import {SystemRole} from "../../../utils/SystemRole";

/** https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript */
function getCssStyle(element: any, prop: any) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
}

function getCanvasFontSize(el : HTMLElement | null = document.body) {
    if (el === undefined || el === null) return ``;
    const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
    const fontSize = getCssStyle(el, 'font-size') || '16px';
    const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

    return `${fontWeight} ${fontSize} ${fontFamily}`;
}

function getTextWidth(text: any, font: any) : any {
    // @ts-ignore
    const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

const calendarFieldWidth = 50;
const publicCalendarFieldWidth = 80;
const fullPublicCalendarFieldWidth = 35;
const nowPVBefore = 90;
const nowPVAfter = 90;

interface Props {
    chosenDate: Date;
    setOpenPreview: Function;
    setPreviewedEvent: Function;
    isLoading: boolean;
    calendar: Map<number, ICalendarClassroomInfo>;
    longer?: boolean;
    viewOnly?: boolean;
    publicView: "full" | "now" | false;
}

const CalendarTimetable = ({chosenDate, setOpenPreview, setPreviewedEvent, isLoading, calendar, longer=false, viewOnly=false, publicView}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    // const [first, setFirst] = useState<number>(0);
    // const [last, setLast] = useState<number>(41);

    var first=0, last=41, select=(publicView === 'full'? 12 : 6);
    let hours: string[] = [];
    let offset = 0;
    const minutes = [":00", ":15", ":30", ":45"];
    if (publicView !== 'now') {
        for (let i = 8; i <= (longer ? 20 : 18); i++) {
            minutes.forEach(minute => hours.push(i + minute));
        }
        if (publicView) {
            const now = new Date();
            select = (now.getHours() - 8) * 4 + ~~(now.getMinutes() / 15);
        }
    } else {
    //     let now = new Date(2022, 2, 14, 14,30);
    //     select = (now.getHours() - 8) * 4 + ~~(now.getMinutes() / 15);
        let now = new Date();
        now.setMinutes(now.getMinutes() - nowPVBefore)
        offset = ~~(now.getMinutes() / 15);
        let hour = now.getHours();
        now.setMinutes(now.getMinutes() + nowPVAfter);
        // if(publicView === 'full') {
        //     select = 14 + offset;
        //     offset = 0;
        // }
        if (hour < 8) {
            hour = 8;
            offset = 0;
            select = (now.getHours() - 8) * 4 + ~~(now.getMinutes() / 15);
        }
        first=((hour - 8) * 4 + offset);
        last=first + 15;
        for (let i = 0; i <= 14 && (hour <= 18 || (hour <= 20 && longer)); i++) {
            hours.push(hour + minutes[(offset + i) % 4]);
            if ((offset + i) % 4 === 3) {
                hour++;
            }
        }
    }
    if (publicView) {
        viewOnly = true;
    }

    const setCalendarClick = (sala: number, godzina: number, eventId: number) => {
        if (eventId === 0) {
            var date = new Date(chosenDate)
            date.setHours(~~(godzina/4) + 8,15*(godzina%4),0,0)
            history.push('/new-reservation/'+ sala+'/'+date.getTime()/1000)
        } else {
            getEventUsingGET({id: eventId}).then((result: any) => {
                const event = result.data;
                const requiredEquipmentString = event.requiredEquipmentNames.length === 0 ? (event.requiredEquipmentOther === "" ? t('actions.none') : event.requiredEquipmentOther) : event.requiredEquipmentNames.join(", ") + (event.requiredEquipmentOther === "" ? "" : ", " + event.requiredEquipmentOther);
                const tmpClassrooms = [];
                for (let i = 0; i < event.classrooms.length; i++) {
                    if (event.classrooms[i] !== event.debriefingRoom) {
                        tmpClassrooms.push({name: event.classroomNames[i], id: event.classrooms[i]});
                    }
                }
                setPreviewedEvent({
                    id: event.id,
                    requestStatus: event.requestStatus,
                    postFactumStatus: event.postFactumStatus,
                    date: event.date,
                    startTime: event.startTime.substring(0, 5),
                    endTime: event.endTime.substring(0, 5),
                    resultType: event.resultTypeName,
                    departmentName: event.studentGroupSubjectDepartmentName,
                    degreeCourse: event.studentGroupSubjectDegreeCourseName,
                    subjectName: event.studentGroupSubjectName,
                    studentGroup: event.studentGroupName,
                    eventType: event.eventTypeName,
                    classroomId: event.classroomNames === null || event.classroomNames.length === 0? 0 : tmpClassrooms[0].id,
                    classroom: event.classroomNames === null || event.classroomNames.length === 0? t('actions.none') : tmpClassrooms[0].name,
                    classroom2Id: event.classroomNames === null || tmpClassrooms.length < 2 ? 0 : tmpClassrooms[1].id,
                    classroom2: event.classroomNames === null || tmpClassrooms.length < 2 ? t('actions.none') : tmpClassrooms[1].name,
                    anyClassroom: event.anyClassroom ? t('actions.yes') : t('actions.no'),
                    debriefingRoomRequired: event.debriefingRoomRequired ? t('actions.yes') : t('actions.no'),
                    debriefingRoomId: event.debriefingRoomId === null ? 0 : event.debriefingRoomId,
                    debriefingRoom: event.debriefingRoomId === null ? t('actions.none') : event.debriefingRoomName,
                    teachers: event.teacherFullNameWithTitle,
                    requiredEquipment: requiredEquipmentString,
                    technicianRequired: event.technicianRequired ? t('actions.yes') : t('actions.no'),
                    isRepeating: event.repeating ? t('calendar.cyclical') : t('calendar.oneTime'),
                    repeatFrequency: !event.repeating || event.repeatFrequency === null ? t('actions.none') : event.repeatFrequency,
                    repeatEndDate: !event.repeating ? t('actions.none') : !event.repeatEndDate || event.repeatEndDate.length === 0 ? t('actions.none') : event.repeatEndDate.substring(0, 10),
                    repeatsNumber: !event.repeating ? t('actions.none') : !event.repeatsNumber ? t('actions.none') : event.repeatsNumber,
                    additionalNotes: event.additionalNotes,
                    technicianId: event.technicianId,
                    technicianName: event.technicianName,
                    createdBy: event.createdBy,
                })
                setOpenPreview(true);
            });
        }
    }

    const fitText = (text: string, style: any, fieldWidth: number) => {
        const x =  fieldWidth - getTextWidth("...", style) - 15; // 15px zapasu
        let l = 0, r = text.length;
        while (l < r) {
            let m = Math.floor((l + r) / 2);
            if (getTextWidth(text.substring(0, m), style) < x) {
                l = m + 1;
            } else {
                r = m;
            }
        }
        return l;
    }

    const getFieldText = (calendarField: ICalendarField, id: string) => {
        const fieldWidth = publicView === "full" ? fullPublicCalendarFieldWidth :
            publicView === "now" ? publicCalendarFieldWidth :
                calendarFieldWidth
        const style = getCanvasFontSize(document.getElementById(id));
        if ((calendarField.fieldType === 1 && !publicView)
            && calendarField.fieldWidth * fieldWidth <= getTextWidth(calendarField.fieldText, style)) {
            // obcinam tekst
            let l = fitText(calendarField.fieldText, style, calendarField.fieldWidth * fieldWidth);
            return calendarField.fieldText.substring(0, l) + "...";
        } else if (publicView && calendarField.fieldText !== "") {
            calendarField.fieldText = calendarField.fieldText.split(",")[0];
            calendarField.textSize = "1.8em";
            if (calendarField.fieldWidth * fieldWidth <= getTextWidth(calendarField.fieldText, style)) {
                let width = calendarField.fieldWidth * fieldWidth - 10;
                let scrollTime = calendarField.fieldWidth * 2;
                return (<div>
                    <div className={classes.scrollTextContainer} style={{width: width + "px"}}>
                        <span className={classes.scrollText}
                              style={{animation: "text-scroll " + scrollTime + "s linear infinite"}}>
                            {calendarField.fieldText}&nbsp;&nbsp;
                        </span>
                            </div>
                            <div className={classes.scrollTextContainer} style={{width: width + "px"}}>
                        <span className={classes.scrollText}
                              style={{animation: "text-scroll2 " + scrollTime + "s linear infinite", animationFillMode: "both"}}>
                            {calendarField.fieldText}&nbsp;&nbsp;
                        </span>
                    </div>
                </div>)
            }
        }
        return calendarField.fieldText;
    }

    const getFieldTextSize = (calendarField: ICalendarField, id: string) => {
        if (publicView && calendarField.textSize !== "")
            return calendarField.textSize;
        return "1em"
    }

    const calendarRowAsJSX = (row: TCalendarRow, i : number, classroomId: number, lastRow: boolean, separator: boolean ) => {
        const rowFields: any = [];
        row.forEach((calendarField, j) => {
            const borderBottom = lastRow ? '1.5px solid #000' : '0px';
            const borderTopMakeSeparator = lastRow && !publicView ? '4px solid #000' : '0px';
            let itemBorderTop = "none";
            let itemBorderRight = "none";
            let itemBorderBottom = "none";
            let itemBorderLeft = "none";
            let itemBorderProps = "dashed 2px #bbb";
            if (!(calendarField.eventStatus === "ACCEPTED")) {
                itemBorderTop = itemBorderProps;
                itemBorderRight = calendarField.fieldType > 1 ? itemBorderProps : "none";
                itemBorderBottom = itemBorderProps;
                itemBorderLeft = calendarField.fieldType % 2 === 1 ? itemBorderProps : "none";
            }

            let borderTopWithSeparator = separator ? borderTopMakeSeparator : "none";

            const onCalendarClick = viewOnly? () => {} : () => setCalendarClick(classroomId, j, calendarField.eventId);
            if (publicView !== 'now' || (j >= first && j < last)) {
                if (publicView && j === first && calendarField.fieldType % 2 === 0 && calendarField.eventId !== 0) {
                    let p = j;
                    while (p > 0 && row[--p].fieldType !== 1){}
                    calendarField.fieldText = row[p].fieldText
                    calendarField.fieldWidth = row[p].fieldWidth - (j - p);

                }
                if (publicView && calendarField.fieldWidth > last - j) {
                    calendarField.fieldWidth = last - j;
                    console.log(calendarField);
                }
                rowFields.push(
                    <div key={"row" + i + "column" + j}>{calendarField.eventId !== 0 ?
                        <div className={publicView ? classes.publicCalendarItem : classes.calendarItem}
                             key={"row-inside" + i + "column" + j}
                             style={Object.assign({}, calendarField.fieldType === 1 ? {
                                         paddingLeft: "1px",
                                         borderRight: 'none',
                                         borderBottom,
                                         borderLeft: publicView === 'full' && j % 2 === 1 ? 'none' : '1.5px ' + (j % 4 === 0 ? 'solid ' : 'dashed ') + '#000'
                                     }
                                     : calendarField.fieldType === 2 ? {
                                             paddingRight: "1px",
                                             borderLeft: 'none',
                                             borderBottom
                                         }
                                         : {borderLeft: 'none', borderRight: 'none', borderBottom},
                                 publicView && j - first === select ? {backgroundColor: "#efefef", width: publicView === 'full' ? '25px':undefined} : publicView === 'full' ? {width: '35px'}:{}
                             )}
                             onClick={onCalendarClick}>
                            <div className={publicView ? (SystemRole.isAuthorized() ? classes.insidePublicCalendarItem : classes.insidePublicCalendarItemNonAuthorized)
                                : classes.insideCalendarItem} style={{
                                backgroundColor: calendarField.eventStatus === "ACCEPTED" ? calendarField.fieldColor : "rgba(255, 255, 255, 1)",
                                color: calendarField.eventStatus === "ACCEPTED" ? calendarField.fontColor : "rgba(0, 0, 0, 1)",
                                fontStyle: calendarField.eventStatus === "ACCEPTED" ? "normal" : "italic",
                                borderTopLeftRadius: calendarField.fieldType % 2 === 1 ? 5 : 0,
                                borderBottomLeftRadius: calendarField.fieldType % 2 === 1 ? 5 : 0,
                                borderTopRightRadius: calendarField.fieldType > 1 ? 5 : 0,
                                borderBottomRightRadius: calendarField.fieldType > 1 ? 5 : 0,
                                borderTop: itemBorderTop,
                                borderRight: itemBorderRight,
                                borderBottom: itemBorderBottom,
                                borderLeft: itemBorderLeft,
                                zIndex: calendarField.fieldType % 2 === 1 || (publicView && j === first) ? 1 : 0,
                                height: publicView? "50px" : "42px",
                                cursor: "pointer",
                                fontSize: publicView? "1.8em" : getFieldTextSize(calendarField, "inside"+i+'-'+j),
                                whiteSpace: "nowrap",
                                width: publicView === "full" ? "35px" : undefined,
                            }} key={"inside" + i + "-" + j}
                                 id={"inside" + i + "-" + j}>{getFieldText(calendarField, "inside" + i + "-" + j)}</div>
                        </div> :
                        <div className={publicView ? classes.publicCalendarItemEmpty : classes.calendarItemEmpty}
                             key={"row" + i + "column" + j + "empty"}
                             style={Object.assign({}, j % 4 === 0 ? {
                                     borderLeft: '1.5px solid #000',
                                     borderBottom,
                                     borderTop: borderTopWithSeparator
                                 } : {borderLeft: publicView === 'full' && j % 2 === 1 ? 'none' : '1.5px dashed #000', borderBottom, borderTop: borderTopWithSeparator},
                                 publicView && j - first === select ? {backgroundColor: "#efefef", width: publicView === 'full' ? '25px':undefined} : publicView === 'full' ? {width: '35px'}:{}
                             )}
                             onClick={onCalendarClick}>
                            <div key={"inside-empty" + i + "-" + j} className={classes.insideCalendarItemEmpty}/>
                        </div>
                    }</div>);
            }
        });
        return rowFields;
    }

    const allCalendarRowsAsJSX = () => {
        const calendarRows: any = [];
        let i = 0;
        let prev = '';
        calendar.forEach((value, classroomId) => {
            value.dataFields.forEach((row : TCalendarRow, k) => {
                i += 1;
                calendarRows.push(
                    <div className={classes.calendarRow} key={"row" + i + "-" + k}>
                        {calendarRowAsJSX(row, i, classroomId, k + 1 === value.dataFields.length, prev !== value.categoryName && prev !== '')}
                    </div>);
                calendarRows.push(<br/>);
                prev = value.categoryName;
            });
        });
        return calendarRows;
    }

    return (
        <div>
            <div className={classes.hourLabelsContainer}>
                {hours.map((item,i) => (<div className={publicView ? classes.publicHourLabel : classes.hourLabel} style={publicView === "full" ? {width:"35px"} : {}} key={"hour"+i}>{publicView !== "now" ? (i + offset) % 4 === 0 ? item : "" : i % 2 === 0 ? item : ""}</div>))}
            </div>
            {!isLoading && allCalendarRowsAsJSX()}
        </div>
    );
}

const useStyles = makeStyles(() => ({
    scrollTextContainer: {
        paddingLeft: "15px", overflow: "hidden", whiteSpace: "nowrap", position: "absolute"
    },
    scrollText: {
        display: "inline-block",
    },
    hourLabelsContainer: {
        display: "flex",
    },

    hourLabel: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        width: "50px",
        minWidth: "50px",
        height: "50px",
        minHeight: "50px",
    },

    publicHourLabel: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        width: "80px",
        minWidth: "5px",
        height: "30px",
        minHeight: "30px",
        fontSize: "1.5em"
    },
    
    calendarRow: {
        display: "inline-flex",
        flexDirection: "row",
    },

    publicCalendarItem: {
        width: "80px",
        height: "60px",
        minWidth: "25px",

        overflow: "visible",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "inherit",
    },
    
    calendarItem: {
        width: "50px",
        height: "50px",
        minWidth: "50px",

        overflow: "visible",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "inherit",
    },

    publicCalendarItemEmpty: {
        width: "80px",
        height: "60px",
        minWidth: "25px",

        overflow: "visible",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "inherit",
    },

    calendarItemEmpty: {
        width: "50px",
        height: "50px",
        minWidth: "50px",

        overflow: "visible",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "center",
        backgroundColor: "inherit",
        '&:hover': {
            backgroundColor: "#bbbfbb !important",
        }
    },

    insideCalendarItem: {
        height: "100%",
        width: "100%",
        border: "none",
        padding: "none",
        margin: "none",
        position: "relative",
        paddingTop: "8px",
        paddingLeft: "5px",
        backgroundColor: "inherit",
    },

    insidePublicCalendarItem: {
        height: "100%",
        width: "100%",
        border: "none",
        padding: "none",
        margin: "none",
        position: "relative",
        paddingTop: "3px",
        paddingLeft: "5px",
        color: "white !important",
        backgroundColor: "inherit",
        fontWeight: "bolder",
    },

    insidePublicCalendarItemNonAuthorized: {
        height: "100%",
        width: "100%",
        border: "none",
        padding: "none",
        margin: "none",
        position: "relative",
        paddingTop: "3px",
        paddingLeft: "5px",
        color: "white !important",
        backgroundColor: "red !important",
        fontWeight: "bolder",
    },

    insideCalendarItemEmpty: {
        backgroundColor: "#e9f0e9",
        '&:hover': {
            backgroundColor: "red !important",
        }
    },
}));

export default CalendarTimetable;

