import React, {useState} from 'react';
import CustomTextButton from "../../../components/form/CustomTextButton";
import {Colors} from "../../../constants/colors";
import CustomInputText from "../../../components/form/CustomInputText";
import {Checkbox, FormControlLabel, Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ActionToolbar} from '../../../utils/styled';
import {SystemRole} from "../../../utils/SystemRole";
import CustomSelectOne from "../../../components/form/CustomSelectOne";
import {useTranslation} from "react-i18next";

interface Props {
    searchCriteria: any,
    updateSearchCriteria: any;
}

const UserSearchCriteria = ({searchCriteria, updateSearchCriteria} : Props) => {
    const [firstName, setFirstName] = useState(searchCriteria.firstName);
    const [lastName, setLastName] = useState(searchCriteria.lastName);
    const [email, setEmail] = useState(searchCriteria.email);
    const [systemRole, setSystemRole] = useState(searchCriteria.systemRole);
    const [showDeleted, setShowDeleted] = useState(searchCriteria.showDeleted);

    const classes = useStyles();
    const {t} = useTranslation();

    const clear = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setSystemRole("");
        setShowDeleted(false);
    };

    const search = () => {
        updateSearchCriteria({
            firstName: firstName,
            lastName: lastName,
            email: email,
            systemRole: systemRole,
            showDeleted: showDeleted,
            page: {
                ...searchCriteria.page,
                pageNr: 0,
                offset: 0,
            }
        });
    };

    return (
        <Paper className={classes.userSearchCriteria}>
            <div className={classes.form}>
                <CustomInputText label={t('user.firstName')} value={firstName} setValue={setFirstName} fullWidth={false} margin={"0px"}/>
                <CustomInputText label={t('user.lastName')} value={lastName} setValue={setLastName} fullWidth={false} margin={"0px"}/>
                <CustomInputText label={t('user.email')} value={email} setValue={setEmail} fullWidth={false} margin={"0px"}/>
                <div className={classes.systemRoleInput}>
                    <CustomSelectOne value={systemRole} setValue={setSystemRole} items={SystemRole.roles} placeholder={t('user.role')}/>
                </div>
            </div>
            <FormControlLabel label={t('user.showDeleted')}
                              control={<Checkbox checked={showDeleted} onChange={() => setShowDeleted(!showDeleted)}/>}/>
            <ActionToolbar>
                <CustomTextButton onClick={clear} backgroundColor={Colors.LIGHT_GREY} text={t('actions.clear')}/>
                <CustomTextButton onClick={search} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.search')}/>
            </ActionToolbar>
        </Paper>
    )
}

const useStyles = makeStyles(theme => ({
    userSearchCriteria: {
        margin: 5,
        marginBottom: 30,
        padding: 15,
        maxWidth: "100vw",
    },

    form: {
        display: "flex",
        flexDirection: "row",
        alignItems: "baseline",
        width: "100%",
        columnGap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
    },

    systemRoleInput: {
        marginTop: 20,
        marginLeft: -16,
        marginRight: 0,
        marginBottom: 10,
    },
}));

export default UserSearchCriteria;



