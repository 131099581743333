import {constDictType, fields} from "./dictType";

export const items = [
    {
        dictType: constDictType.CLASSROOM,
        dictionaryDependencies: [
            constDictType.CATEGORY,
            constDictType.PUBLIC_CATEGORY,
        ],
        dictionaryFields: [
            fields.NAME,
            fields.NUMBER,
            fields.MAIN,
        ],
    },
    {
        dictType: constDictType.DEPARTMENT,
        dictionaryDependencies: [
        ],
        dictionaryFields: [
            fields.NAME,
        ],
    },
    {
        dictType: constDictType.DEGREE_COURSE,
        dictionaryDependencies: [
            constDictType.DEPARTMENT,
        ],
        dictionaryFields: [
            fields.NAME,
        ],
    },
    {
        dictType: constDictType.EQUIPMENT,
        dictionaryDependencies: [
        ],
        dictionaryFields: [
            fields.NAME,
            fields.ACTIVE,
        ],
    },
    {
        dictType: constDictType.EVENT_TYPE,
        dictionaryDependencies: [
        ],
        dictionaryFields: [
            fields.NAME,
        ],
    },
    {
        dictType: constDictType.STUDENT_GROUP,
        dictionaryDependencies: [
            constDictType.DEPARTMENT,
            constDictType.DEGREE_COURSE,
        ],
        dictionaryFields: [
            fields.NAME,
            fields.STUDENT_COUNT,
        ],
    },
    {
        dictType: constDictType.TEACHER,
        dictionaryDependencies: [
        ],
        dictionaryFields: [
            fields.FIRST_NAME,
            fields.LAST_NAME,
            fields.TITLE,
            fields.ORGANIZATION_UNIT,
        ],
    },
    {
        dictType: constDictType.SUBJECT,
        dictionaryDependencies: [
            constDictType.DEPARTMENT,
            constDictType.DEGREE_COURSE,
        ],
        dictionaryFields: [
            fields.NAME,
            fields.LENGTH_IN_SEMESTERS,
            fields.TILE_COLOR_HEX,
            fields.FONT_COLOR_HEX,
            fields.SEMESTER_NUMBER,
        ],
    },
    {
        dictType: constDictType.TECHNICIAN,
        dictionaryDependencies: [
        ],
        dictionaryFields: [
            fields.FIRST_NAME,
            fields.LAST_NAME,
        ]
    },
    {
        dictType: constDictType.CATEGORY,
        dictionaryDependencies: [
        ],
        dictionaryFields: [
            fields.NAME,
            fields.NUMBER,
        ]
    },
    {
        dictType: constDictType.PUBLIC_CATEGORY,
        dictionaryDependencies: [
        ],
        dictionaryFields: [
            fields.NAME,
            fields.NUMBER,
        ]
    },
];

export const checkIfDependencyExist = (dictType: constDictType, dictDependency: constDictType) => {
    for (let i = 0; i < items.length; i += 1) {
        if (items[i].dictType === dictType) {
            for (let j = 0; j < items[i].dictionaryDependencies.length; j += 1) {
                if (items[i].dictionaryDependencies[j] === dictDependency) {
                    return true;
                }
            }
            break;
        }
    }
    return false;
}

export const checkIfFieldExist = (dictType: constDictType, field: fields) => {
    for (let i = 0; i < items.length; i += 1) {
        if (items[i].dictType === dictType) {
            for (let j = 0; j < items[i].dictionaryFields.length; j += 1) {
                if (items[i].dictionaryFields[j] === field) {
                    return true;
                }
            }
            break;
        }
    }
    return false;
}