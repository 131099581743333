import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {ToggleButton} from 'primereact/togglebutton';
import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import {useTranslation} from "react-i18next";
import {Dropdown} from "primereact/dropdown";
import {SystemRole} from "../../../utils/SystemRole";
import {addLocale} from "primereact/api";
import {getCalendarLocale} from "../../../locales/getCalendarLocale";

interface Props {
    chosenDate: Date;
    setChosenDate: any;
    showCalendar: boolean;
    setShowCalendar: any;
    viewType: number;
    setViewType: any;
    onlyMyEvents: boolean;
    setOnlyMyEvents: any;
    showWeek: boolean;
    setShowWeek: any;
    chosenWeekDates: Array<Date>;
    setFocusDate: Function;
    publicView: boolean
}

const CalendarHeader = ({chosenDate, setChosenDate, showCalendar, setShowCalendar, viewType, setViewType, onlyMyEvents,
                            setOnlyMyEvents, showWeek, setShowWeek, chosenWeekDates, setFocusDate, publicView}: Props) => {
    const classes = useStyles();
    const {t, i18n} = useTranslation();

    addLocale('pl', getCalendarLocale(t));

    const viewTypes = SystemRole.isUser() ? [
        {label: t('calendar.viewAccepted'), value: 1},
        {label: t('calendar.viewNotRejected'), value: 2},
    ] : [
        {label: t('calendar.viewAll'), value: 0},
        {label: t('calendar.viewAccepted'), value: 1},
        {label: t('calendar.viewNotRejected'), value: 2},
        {label: t('calendar.viewRejected'), value: 3},
    ];

    useEffect(() => {
        const date = new Date(chosenDate)
        date.setHours(0)
        date.setMinutes(0)
        date.setSeconds(0)
        setChosenDate(date)
        // eslint-disable-next-line
    },[])

    const openCalendar = () => {
        setShowCalendar(true);
    }
    const [publicViewHeader, setPublicViewHeader] = useState<String>("");

    const displayDate = () => {
        if (showWeek) {
            let label = chosenWeekDates[0].getDate() + " - "
            if (chosenWeekDates[0].getDate() > chosenWeekDates[4].getDate()) {
                // aby wyświetlało się "28 października - 1 listopada 2024" zamiast "28 - 1 listopada 2024"
                const options: any = {month: 'long', day: 'numeric'};
                label = chosenWeekDates[0].toLocaleDateString(i18n.language === "pl" ? "pl-PL" : "en", options) + " - "
            }
            if (chosenWeekDates[0].getFullYear() !== chosenWeekDates[4].getFullYear()) {
                // aby wyświetlało się "30 grudnia 2024 - 3 stycznia 2025" zamiast "30 grudnia - 3 stycznia 2025"
                const options: any = {year: 'numeric', month: 'long', day: 'numeric'};
                label = chosenWeekDates[0].toLocaleDateString(i18n.language === "pl" ? "pl-PL" : "en", options) + " - "
            }
            const options: any = {year: 'numeric', month: 'long', day: 'numeric'};
            return label + chosenWeekDates[4].toLocaleDateString(i18n.language === "pl" ? "pl-PL" : "en", options);
        } else {
            const options: any = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
            return chosenDate.toLocaleDateString(i18n.language === "pl" ? "pl-PL" : "en", options);
        }
    }
    const incDay = () => {
        let howMany = showWeek ? 7 : 1
        var next = new Date(chosenDate)
        next.setDate(next.getDate() + howMany)
        setChosenDate(next)
        setFocusDate(undefined)
    }
    const decDay = () => {
        let howMany = showWeek ? 7 : 1
        var next = new Date(chosenDate)
        next.setDate(next.getDate() - howMany)
        setChosenDate(next)
        setFocusDate(undefined)
    }
    const updateChosenDate = (event: any) => {
        setChosenDate(event.value);
        setShowCalendar(false);
    }

    useEffect(() => {
            if (publicView) {
                const options: any = {weekday:'long', month: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute:'numeric',second:'numeric'};
                setPublicViewHeader(new Date().toLocaleString(i18n.language === "pl" ? "pl-PL" : "en", options));
                let id = setInterval(() => {
                    setPublicViewHeader(new Date().toLocaleString(i18n.language === "pl" ? "pl-PL" : "en", options));
                }, 500);
                return () => clearInterval(id);
            }
        },[publicView]);


    if (publicView) {
        return (
            <div className={classes.publicHeader}>
                <b>{publicViewHeader}</b>
            </div>
        )
    }

    return (
        <div className={classes.calendarHeader}>
            <div className={classes.title}>{t('calendar.title')}</div>
            <div className={classes.dayInfo}>
                <i className="pi pi-chevron-left" style={{fontSize: '1.2em', cursor: 'pointer'}} onClick={decDay}/>
                <div className={classes.dayInfoDate} onClick={openCalendar}>
                    <b>{displayDate()}</b>
                </div>
                <i className="pi pi-chevron-right" style={{fontSize: '1.2em', cursor: "pointer"}} onClick={incDay}/>
                <Dialog visible={showCalendar} onHide={() => setShowCalendar(false)} closable={false} dismissableMask={true}>
                    <Calendar inline value={chosenDate} onChange={(e: any) => updateChosenDate(e)} locale="pl"/>
                </Dialog>
            </div>
            <div className={classes.viewSelector}>
                <div className={classes.viewSelectorLabel}>{t('calendar.viewLabel')}</div>
                <ToggleButton checked={showWeek} onChange={(e) => setShowWeek(e.target.value)}
                              onLabel={t('calendar.viewWeek')} offLabel={t('calendar.viewDay')} className={classes.button}/>
                <div style={{width: 5}}/>
                <ToggleButton checked={onlyMyEvents} onChange={(e) => setOnlyMyEvents(e.target.value)}
                              onLabel={t('calendar.viewOnlyMy')} offLabel={t('calendar.viewNotOnlyMy')} className={classes.button}/>
                <div style={{width: 5}}/>
                <Dropdown value={viewType} options={viewTypes} onChange={(e) => setViewType(e.value)}
                          placeholder="Widok" className={classes.button}/>
            </div>
        </div>
    );
}

const useStyles = makeStyles(theme => ({
    calendarHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        paddingTop: 30,
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
    },

    title: {
        fontSize: "1.2em",
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15,
        },
    },

    dayInfo: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15,
        },
    },

    dayInfoDate: {
        fontSize: "1.2em",
        padding: 10,
        minWidth: "15em",
        textAlign: "center",
        cursor: "pointer",
    },

    publicHeader: {
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down('sm')]: {
            marginBottom: 15,
        },
        fontSize: "2em",
        minWidth: "15em",
        // backgroundColor: "white",
        textAlign: "center",
        cursor:"default",

    },

    viewSelector: {
        display: "flex",
        alignItems: "center",
        fontSize: "1.2em",
    },

    viewSelectorLabel: {
        marginRight: 10,
    },

    button: {
        backgroundColor: "#e9f0e9 !important",
        color: "#000 !important",
        border: "none !important",
        borderRadius: "10px !important",
        boxShadow: "0 2px #a0a3a0 !important",
    },
}));

export default CalendarHeader;

