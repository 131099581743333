import React from 'react';
import {Box, FormControlLabel} from "@mui/material";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {InputNumber} from "primereact/inputnumber";

interface Props {
    value: number | undefined,
    setValue: any,
    disabled?: boolean,
    label?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
}));


const CustomInputNumber = ({value, setValue, label = "", disabled = false}: Props) => {
    const spaceAfterLabel = label === "" ? "0px" : "30px";
    const {container} = useStyles();

    return (
        <Box>
            <FormControlLabel
                control={
                    <InputNumber value={value} onValueChange={(e) => setValue(e.value)}
                                 disabled={disabled} allowEmpty/>
                }
                label={label}
                labelPlacement={"start"}
                className={container}
                sx={{gap: spaceAfterLabel, cursor: "initial"}}
            />
        </Box>
    )
}

export default CustomInputNumber;