import React, {useEffect, useState} from 'react';
import {Box, FormControlLabel, Stack} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {SelectButton} from "primereact/selectbutton";
import {getRepeatFrequencyTypesUsingGET as getRepeatFrequencyTypes} from "../swagger/vue-api-client";
import CustomCalendar from "./form/CustomCalendar";
import {useTranslation} from "react-i18next";
import InputTextWithLabel from "./InputTextWithLabel";

interface Props {
    isRepeating: boolean;
    setIsRepeating: any;
    repeatFrequency: any;
    setRepeatFrequency: any;
    repeatEndDate: any;
    setRepeatEndDate: any;
    repeatsNumber: number | undefined;
    setRepeatsNumber: any;
    disabled?: boolean,
    width?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
}));


const SelectEventCharacter = ({isRepeating, setIsRepeating, repeatFrequency, setRepeatFrequency, repeatEndDate,
                                  setRepeatEndDate, repeatsNumber, setRepeatsNumber, disabled = false,
                                  width = "200px", labelPlacement = "start", spaceAfterLabel = "30px"}: Props) => {
    const {t} = useTranslation();
    const {container} = useStyles();
    const isRepeatingSelection = [{label: t('calendar.oneTime'), value: false}, {label: t('calendar.cyclical'), value: true}];
    const [repeatFrequencySelection, setRepeatFrequencySelection] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getRepeatFrequencyTypes().then((res: any) => {
            setRepeatFrequencySelection(res.data);
            setIsLoading(false);
        });
    }, []);

    return (
        <Box>
            {!isLoading &&
            <Stack direction={"column"} spacing={3} alignItems={"baseline"}>
                <FormControlLabel
                    control={
                        <SelectButton value={isRepeating} options={isRepeatingSelection} disabled={disabled}
                                      onChange={(e) => setIsRepeating(e.value)}/>
                    }
                    label={<div style={{width: width}}>{t('eventCharacter.title')}</div>}
                    labelPlacement={labelPlacement}
                    className={container}
                    sx={{gap: spaceAfterLabel, cursor: "initial"}}
                />
                <div>
                    {isRepeating &&
                    <FormControlLabel
                        control={
                            <SelectButton value={repeatFrequency} options={repeatFrequencySelection} disabled={disabled}
                                          onChange={(e) => setRepeatFrequency(e.value)}/>
                        }
                        label={<div style={{width: width}}>{t('eventCharacter.mode')}</div>}
                        labelPlacement={labelPlacement}
                        className={container}
                        sx={{gap: spaceAfterLabel, cursor: "initial"}}
                    />
                    }
                </div>
                {isRepeating &&
                <>
                    <CustomCalendar value={repeatEndDate} setValue={setRepeatEndDate}
                                    label={t('eventCharacter.selectBorderDate')} showButtonBar={true}
                                    width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>
                    <InputTextWithLabel label={t('eventCharacter.repetitionsLabel')} value={repeatsNumber}
                                        setValue={setRepeatsNumber} placeholder={t('eventCharacter.repetitions')}
                                        width={"250px"} spaceAfterLabel={spaceAfterLabel} labelPlacement={labelPlacement}/>
                </>}
            </Stack>
            }
        </Box>
    )
}

export default SelectEventCharacter;