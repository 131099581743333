import React, {useEffect, useState} from "react";
import {CircularProgress, Grid, GridSpacing, Paper,} from "@material-ui/core";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import {useHistory, useParams} from "react-router-dom";
import {
    createOrUpdateUserUsingPOST as createOrUpdateUser,
    getUserUsingGET as getUser
} from "../../swagger/vue-api-client";
import CustomInputText from "../../components/form/CustomInputText";
import {makeStyles} from "@material-ui/core/styles";
import CustomSelectOneMUI from "../../components/form/CustomSelectOneMUI";
import {ActionToolbar} from "../../utils/styled";
import {SystemRole} from "../../utils/SystemRole";
import {useTranslation} from "react-i18next";
import {toastInfo} from "../../services/InfoService";

interface Params {
    userId: string;
}

const UserEditView = ({userId}: Params) => {
    const { id } = useParams<{id: string}>();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [systemRole, setSystemRole] = useState("USER");

    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();

    const backToDetailsView = () => {
        history.push({pathname: "/user/" + id});
    }

    const save = () => {
        const userDto = {
            id: id,
            firstName: firstName,
            lastName: lastName,
            email: email,
            systemRole: systemRole,
        }

        if (!userDto.firstName || !userDto.lastName || !userDto.email || !userDto.systemRole) {
            toastInfo("error", t('user.fillAllFields'));
            return;
        }

        createOrUpdateUser({userDto: userDto})
            .then((res : any) => {
                history.push({pathname: "/user/" + res.data});
                if (localStorage.getItem("userId") === id) {
                    localStorage.setItem("role", systemRole);
                    localStorage.setItem("fullName", firstName + " " + lastName);
                    window.location.reload();
                }
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
            });
    }

    useEffect(() => {
        if (id !== "0") {
            getUser({id})
                .then((res: any) => {
                    setFirstName(res.data.firstName);
                    setLastName(res.data.lastName);
                    setEmail(res.data.email);
                    setSystemRole(res.data.systemRole);
                })
                .catch(() => {
                    toastInfo("error", t('errorOccurred'));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [id, t]);

    return (
        <div className={classes.userEditView}>
            <h2 className={classes.header}>{id !== "0" ? t('user.editTitle') : t('user.newUserFormTitle')}</h2>
            <Paper elevation={2} className={classes.content}>
                {isLoading ? <CircularProgress /> :
                    <Grid container={true} direction={'column'} spacing={2 as GridSpacing}>
                        <Grid item xs={8}>
                            <CustomInputText value={firstName} setValue={setFirstName} label={t('user.firstName')} />
                        </Grid>
                        <Grid item xs={8}>
                            <CustomInputText value={lastName} setValue={setLastName} label={t('user.lastName')} />
                        </Grid>
                        <Grid item xs={8}>
                            <CustomInputText value={email} setValue={setEmail} label={t('user.email')} />
                        </Grid>
                        {id !== localStorage.getItem("userId") && <Grid item xs={8}>
                            <CustomSelectOneMUI value={systemRole} setValue={setSystemRole}
                                                items={SystemRole.roles} label={t('user.role')}
                                                fullWidth={true}/>
                        </Grid>}
                    </Grid>
                }
                <ActionToolbar className={classes.buttons}>
                    <CustomTextButton onClick={backToDetailsView} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={save} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.save')}/>
                </ActionToolbar>
            </Paper>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    userEditView: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    content: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "20px"
    },

    buttons: {
        marginTop: 20,
    },
}));

export default UserEditView;