import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import {Box, InputBase, NativeSelect} from "@material-ui/core";
import styled from "styled-components";
import {FormControlLabel} from "@mui/material";

interface Item {
    value: string,
    label: string,
}

interface Props {
    value: string,
    setValue: any,
    items: Item[],
    label?: string,
    noValueLabel?: string,
    noValueValue?: string,
    showNoValueLabel?: boolean,
    disabled?: boolean,
    fullWidth?: boolean,
    width?: string,
}

const CustomSelectOneMUI = ({
                                value,
                                setValue,
                                items = [],
                                label = "",
                                noValueLabel = '',
                                noValueValue = '',
                                showNoValueLabel = false,
                                disabled = false,
                                fullWidth = false,
                                width = "auto"
                            }: Props) => {

    const spaceAfterLabel = label === "" ? "0px" : "30px";
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <FormControl className={classes.formControl}
                         disabled={disabled}
                         variant="standard"
                         style={{width: width}}>
                <FormControlLabel
                    label={label}
                    control={
                        <NativeSelect
                            id="native-select"
                            value={value}
                            onChange={(event) => setValue(event.target.value)}
                            input={<StyledInput/>}
                        >
                            {showNoValueLabel ? <option value={noValueValue} key={0}>{noValueLabel}</option> : null}
                            {items.map((item, index) => {
                                return (
                                    <option className={classes.option} value={item.value}
                                            key={index}>{item.label}</option>
                                );
                            })}
                        </NativeSelect>
                    }
                    labelPlacement="start"
                    sx={{gap: spaceAfterLabel, cursor: "initial"}}
                />
            </FormControl>
        </Box>
    );
}

const StyledInput = styled(InputBase)`
    display: box;
    background-color: white;
    padding: 5px 10px;
    border-radius: 10px;
`;

const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        [`@media print`]: {
            display: "none"
        },
    },
    formControl: {

    },
    select: {
        alignSelf: "stretch",
    },
    label: {
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: "70px",
        paddingRight: "20px",

    },
    option: {
        backgroundColor: "black",
    },
    valueLabel: {
        display: "flex",
        flexDirection: "row",
        fontSize: "1.2rem",
        alignItems: "baseline",
    },
}));

export default CustomSelectOneMUI;
