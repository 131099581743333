import React, {useEffect, useState} from "react";
import ReservationInfoTile from "./components/ReservationInfoTile";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {
    acceptEventRequestUsingPOST as acceptEventRequest,
    commentEventRequestUsingPOST as commentEventRequest,
    rejectEventRequestUsingPOST as rejectEventRequest,
    searchEventCountUsingPOST as searchEventCount,
    searchEventUsingPOST as searchEvent
} from "../../swagger/vue-api-client";
import CustomSelectOne from "../../components/form/CustomSelectOne";
import {Dialog} from "primereact/dialog";
import ReservationView from "./ReservationView";
import {toastInfo} from "../../services/InfoService";
import {useTranslation} from "react-i18next";
import CustomPagination from "../../components/CustomPagination";
import {Table, TableFooter, TableRow} from "@mui/material";
import {ToggleButton} from "primereact/togglebutton";

interface Props {
    updateRequestCounts: any;
}

const ManageReservationListView = ({updateRequestCounts} : Props) => {
    const location = useLocation();
    const {t} = useTranslation();
    const {status} = useParams<{ status: any }>();
    let searchCriteria = {status: status, showPastEvents: false, page: {}};
    if (location.state) {
        // @ts-ignore
        searchCriteria = location.state.searchCriteria;
    }
    const isSearchResult = status === "search-results";
    let title = t('reservation.manage.allTitle');
    const [statusString, setStatusString] = useState(title);
    const history = useHistory();
    const [openPreview, setOpenPreview] = useState(false);
    const [previewedEvent, setPreviewedEvent] = useState({});

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const classes = useStyles();
    const sortBy = [{value: -1, label: t('reservation.fromNewest')}, {value: 1, label: t('reservation.fromOldest')}]
    const [howToSort, setHowToSort] = useState(-1); // 1 rosnąco, else malejąco
    const statuses = [
        {value: null, label: t('managementPanel.reservationsStatus.all')},
        {value: "PENDING", label: t('managementPanel.reservationsStatus.pending')},
        {value: "NEGOTIATING", label: t('managementPanel.reservationsStatus.negotiating')},
        {value: "ACCEPTED", label: t('managementPanel.reservationsStatus.accepted')},
        {value: "REJECTED", label: t('managementPanel.reservationsStatus.rejected')}]
    const [searchStatus, setSearchStatus] = useState(null);
    const [showPastEvents, setShowPastEvents] = useState(false);

    const [events, setEvents] = useState([]);

    const viewEventDetails = (event: any) => {
        const requiredEquipmentString = event.requiredEquipmentNames.length === 0 ? (event.requiredEquipmentOther === "" ? t('actions.none') : event.requiredEquipmentOther) : event.requiredEquipmentNames.join(", ") + (event.requiredEquipmentOther === "" ? "" : ", " + event.requiredEquipmentOther);
        const tmpClassrooms = [];
        for (let i = 0; i < event.classrooms.length; i++) {
            if (event.classrooms[i] !== event.debriefingRoom) {
                tmpClassrooms.push({name: event.classroomNames[i], id: event.classrooms[i]});
            }
        }
        setPreviewedEvent({
            id: event.id,
            requestStatus: event.requestStatus,
            postFactumStatus: event.postFactumStatus,
            date: event.date,
            startTime: event.startTime.substring(0, 5),
            endTime: event.endTime.substring(0, 5),
            eventType: event.eventTypeName,
            departmentName: event.studentGroupSubjectDepartmentName,
            degreeCourse: event.studentGroupSubjectDegreeCourseName,
            subjectName: event.studentGroupSubjectName,
            studentGroup: event.studentGroupName,
            classroomId: event.classroomNames === null || event.classroomNames.length === 0? 0 : tmpClassrooms[0].id,
            classroom: event.classroomNames === null || event.classroomNames.length === 0? t('actions.none') : tmpClassrooms[0].name,
            classroom2Id: event.classroomNames === null || tmpClassrooms.length < 2 ? 0 : tmpClassrooms[1].id,
            classroom2: event.classroomNames === null || tmpClassrooms.length < 2 ? t('actions.none') : tmpClassrooms[1].name,
            anyClassroom: event.anyClassroom ? t('actions.yes') : t('actions.no'),
            debriefingRoomRequired: event.debriefingRoomRequired ? t('actions.yes') : t('actions.no'),
            debriefingRoomId: event.debriefingRoomId === null ? 0 : event.debriefingRoomId,
            debriefingRoom: event.debriefingRoomId === null ? t('actions.none') : event.debriefingRoomName,
            teachers: event.teacherFullNameWithTitle,
            requiredEquipment: requiredEquipmentString,
            technicianRequired: event.technicianRequired ? t('actions.yes') : t('actions.no'),
            isRepeating: event.repeating ? t('calendar.cyclical') : t('calendar.oneTime'),
            repeatFrequency: !event.repeating || event.repeatFrequency === null ? t('actions.none') : event.repeatFrequency,
            repeatEndDate: !event.repeating ? t('actions.none') : !event.repeatEndDate   || event.repeatEndDate.length === 0? t('actions.none') : event.repeatEndDate.substring(0, 10),
            repeatsNumber: !event.repeating ? t('actions.none') : !event.repeatsNumber ? t('actions.none') : event.repeatsNumber,
            additionalNotes: event.additionalNotes,
            technicianId: event.technicianId,
            technicianName: event.technicianName,
            requestComment: event.requestComment,
        });
        setOpenPreview(true);
    };

    const goToEditView = (eventId: number) => {
        history.push({pathname: "/edit-reservation/" + eventId});
    }

    const acceptRequest = (eventId: number, assignedTechnicianId: number) => {
        acceptEventRequest({eventId: eventId, assignedTechnicianId: assignedTechnicianId}).then((res : any) => {
            if (res.data) {
                toastInfo("success", t('reservation.applicationAccepted'));
                setOpenPreview(false);
            } else {
                toastInfo("error", t('errorOccurred'));
            }
            updateRequestCounts();
            searchEvent({searchCriteria: searchCriteria}).then((res: any) => {
                setEvents(res.data);
            })
        });
    }

    const rejectRequest = (eventId: number) => {
        rejectEventRequest({eventId: eventId}).then((res : any) => {
            if (res.data) {
                toastInfo("success", t('reservation.applicationRejected'));
                setOpenPreview(false);
            } else {
                toastInfo("error", t('errorOccurred'));
            }
            updateRequestCounts();
            searchEvent({searchCriteria: searchCriteria}).then((res: any) => {
                setEvents(res.data);
            })
        });
    }

    const suggestChangeToRequest = (eventId: number, requestComment: string) => {
        commentEventRequest({eventId: eventId, requestComment: requestComment}).then((res : any) => {
            if (res.data) {
                toastInfo("success", t('reservation.applicationCommented'));
            } else {
                toastInfo("error", t('errorOccurred'));
            }
            updateRequestCounts();
            searchEvent({searchCriteria: searchCriteria}).then((res: any) => {
                setEvents(res.data);
            })
        });
    }

    const updateEvents = () => {
        searchCriteria.showPastEvents = showPastEvents;
        searchCriteria.status = isSearchResult ? searchStatus : status;
        searchCriteria.page = {
            offset: rowsPerPage * page,
            limit: rowsPerPage,
            sortOrder: howToSort,
            sortField: "creationDate",
        }

        searchEvent({searchCriteria}).then((res: any) => {
            setEvents(res.data);
        })
        searchEventCount({searchCriteria}).then((res: any) => {
            setTotalRecords(res.data);
        })
    }

    useEffect(() => {
        let title = "";
        if (isSearchResult) {
            title = t('reservation.manage.searchResult');
            searchCriteria.status = searchStatus;
        } else if (!status) {
            title = t('reservation.manage.allTitle');
        } else if (status === "ACCEPTED") {
            title = t('reservation.manage.acceptedTitle');
        } else if (status === "REJECTED") {
            title = t('reservation.manage.rejectedTitle');
        } else if (status === "PENDING") {
            title = t('reservation.manage.pendingTitle');
        } else if (status === "NEGOTIATING") {
            title = t('reservation.manage.negotiatingTitle');
        }
        setStatusString(title)
        updateEvents();
        // eslint-disable-next-line
    }, [status, location, searchStatus, isSearchResult, t]);

    useEffect(() => {
        updateEvents();
        // eslint-disable-next-line
    }, [howToSort, page, rowsPerPage, location, showPastEvents])

    useEffect(() => {
        setSearchStatus(null);
    }, [location])

    return (
        <div className={classes.manageReservationList}>
            <div className={classes.header}><h2>{statusString}</h2></div>
            {status === "search-results" &&
                <CustomSelectOne value={searchStatus} setValue={setSearchStatus} items={statuses} label={t('reservation.status')}/>
            }
            <div className={classes.sorting}>
                <CustomSelectOne value={howToSort} setValue={setHowToSort} items={sortBy} label={t('reservation.sortLabel')}/>
            </div>
            <div className={classes.sorting}>
                <ToggleButton checked={showPastEvents} onChange={(e) => setShowPastEvents(e.target.value)}
                              offLabel={t('reservation.scheduled')} onLabel={t('reservation.ended')} className={classes.button}/>
            </div>
            {events.map((event: any) => (
                <ReservationInfoTile clickable={true} onClick={() => {viewEventDetails(event)}}
                                     eventDTO={event} key={event.id}/>
            ))}
            <Table>
                <TableFooter>
                    <TableRow>
                        <CustomPagination  page={page}
                                           rowsPerPage={rowsPerPage}
                                           setPage={setPage} setRowsPerPage={setRowsPerPage} totalRecords={totalRecords}/>
                    </TableRow>
                </TableFooter>
            </Table>
            <Dialog header={t('reservation.preview')} visible={openPreview} className={classes.previewDialog}
                    onHide={() => setOpenPreview(false)}>
                <ReservationView event={previewedEvent} decisionView={true}
                                editOnClick={goToEditView} acceptOnClick={acceptRequest}
                                 rejectOnClick={rejectRequest} commentOnClick={suggestChangeToRequest}/>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    manageReservationList: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    sorting: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "baseline",
        marginBottom: 20,
        marginRight: 11,
        gap: 15,
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            marginBottom: 30,
        },
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    previewDialog: {
        width: "55vw",
        [theme.breakpoints.down('sm')]: {
            width: "95vw",
        },
    },

    button: {
        backgroundColor: "#e9f0e9 !important",
        color: "#000 !important",
        border: "none !important",
        borderRadius: "10px !important",
        boxShadow: "0 2px #a0a3a0 !important",
    },
}));

export default ManageReservationListView;