import * as React from 'react';
import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import PickersDay from '@mui/lab/PickersDay';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import startOfWeek from 'date-fns/startOfWeek';
import plLocale from "date-fns/locale/pl"
import {Box, FormControlLabel} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({theme, dayIsBetween, isFirstDay, isLastDay}) => ({
    ...(dayIsBetween && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
            backgroundColor: theme.palette.primary.dark,
        },
    }),
    ...(isFirstDay && {
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
    }),
    ...(isLastDay && {
        borderTopRightRadius: '50%',
        borderBottomRightRadius: '50%',
    }),
}));

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },

    field: {
        height: '20px !important',
        marginTop: 20,
    },
}));


export default function CustomDayPicker({value, setValue, label}) {
    const spaceAfterLabel = label === "" ? "0px" : "30px";
    const {container, field} = useStyles();

    const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
        if (!value) {
            return <PickersDay {...pickersDayProps} />;
        }

        let start = new Date(startOfWeek(value));
        let end = new Date(startOfWeek(value));

        if (value.getDay() !== startOfWeek(value).getDay()) {
            start.setDate(start.getDate() + 1);
            end.setDate(end.getDate() + 7);
        } else {
            start.setDate(start.getDate() - 6);
            end.setDate(end.getDate());
        }

        const dayIsBetween = isWithinInterval(date, {start, end});
        const isFirstDay = isSameDay(date, start);
        const isLastDay = isSameDay(date, end);

        return (
            <CustomPickersDay
                {...pickersDayProps}
                dayIsBetween={dayIsBetween}
                isFirstDay={isFirstDay}
                isLastDay={isLastDay}
            />
        );
    };

    return (
        <Box>
            <FormControlLabel
                control={
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                        <DesktopDatePicker
                            style={{marginLeft: "20px"}}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                            }}
                            renderDay={renderWeekPickerDay}
                            renderInput={(params) => <TextField {...params} className={field} variant="standard"/>}
                            inputFormat="'Tydzień z' MMM d"
                        />
                    </LocalizationProvider>
                }
                label={<div style={{width: "200px"}}>{label}</div>}
                labelPlacement="start"
                className={container}
                sx={{gap: spaceAfterLabel, cursor: "initial"}}
            />
        </Box>
    );
}
