import React, {useEffect, useState} from 'react';
import {FormControlLabel} from "@mui/material";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Dropdown} from "primereact/dropdown";
import CustomSelectOne from "./CustomSelectOne";
import {useTranslation} from "react-i18next";

interface Props {
    value: any;
    setValue: any;
    label?: string;
    placeholder?: string;
    showClear?: boolean;
    showFilter?: boolean;
    labelWidth?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
}));


const CustomSelectOneYesNo = ({value, setValue, label = "", placeholder = "", showClear=false, showFilter, labelWidth,
                             labelPlacement = "start", spaceAfterLabel = "30px"}: Props) => {
    const {t} = useTranslation();
    const [items, setItems] = useState<any>();

    useEffect(() => {
        const items = [
            {
                label: t('actions.yes'),
                value: true,
            },
            {
                label: t('actions.no'),
                value: false,
            }
        ]

        setItems(items);
    }, [t]);



    if (label === "")
        spaceAfterLabel = "0px";
    const {container} = useStyles();

    return (
        <CustomSelectOne items={items} value={value} setValue={setValue} label={label==="" ? t('dictionary.active') : t(label)}
        showClear={showClear}/>
    )
}

export default CustomSelectOneYesNo;