import React, {useEffect, useState} from "react";
import ReservationInfoTile from "./components/ReservationInfoTile";
import CustomTextButton from "../../components/form/CustomTextButton";
import AddIcon from "@material-ui/icons/Add";
import {Colors} from "../../constants/colors";
import {ActionToolbar} from "../../utils/styled";
import {makeStyles} from "@material-ui/core/styles";
import {useHistory, useParams} from "react-router-dom";
import {
    changePostFactumStatusUsingPOST as changePostFactumStatus,
    getPostFactumStatusesUsingGET as getPostFactumStatuses,
    searchEventCountUsingPOST as searchEventCount,
    searchEventUsingPOST as searchEvent,
} from "../../swagger/vue-api-client";
import CustomSelectOne from "../../components/form/CustomSelectOne";
import {useTranslation} from "react-i18next";
import {Dialog} from "primereact/dialog";
import {toastInfo} from "../../services/InfoService";
import CustomPagination from "../../components/CustomPagination";
import {Table, TableFooter, TableRow} from "@mui/material";
import {ToggleButton} from "primereact/togglebutton";

const MyReservationListView = () => {
    const {t} = useTranslation();
    const {status} = useParams<{ status: string }>();
    let title = t('reservation.my.allTitle');
    const [statusString, setStatusString] = useState(title);
    const [openPostFactumStatusDialog, setOpenPostFactumStatusDialog] = useState(false);
    const [postFactumStatus, setPostFactumStatus] = useState<any>(null);
    const [editedEventId, setEditedEventId] = useState<any>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [showPastEvents, setShowPastEvents] = useState(false);

    const history = useHistory();

    const classes = useStyles();
    const sortBy = [{value: -1, label: t('reservation.fromNewest')}, {value: 1, label: t('reservation.fromOldest')}]
    const [howToSort, setHowToSort] = useState(-1); // 1 rosnąco, else malejąco
    const [postFactumStatuses, setPostFactumStatuses] = useState<any[]>([]);

    const [events, setEvents] = useState([]);

    const goToMakeReservation = () => {
        history.push({pathname: "/new-reservation/"});
    };

    const verifyPostFactumOrGoToEditView = (requestStatus: string, eventId: number, eventPostFactumStatus: any) => {
        if (requestStatus !== "NEGOTIATING") {
            setOpenPostFactumStatusDialog(true);
            setEditedEventId(eventId);
            setPostFactumStatus(eventPostFactumStatus);
            return;
        }
        history.push({pathname: "/edit-reservation/" + eventId});
    }

    const savePostFactumStatusChange = () => {
        const request = {
            eventId: editedEventId,
            postFactumStatus: postFactumStatus,
        }

        changePostFactumStatus({request: request}).then(() => {
            toastInfo("success", t('reservation.saveChange'));
            setOpenPostFactumStatusDialog(false);
            updateEvents();
        }).catch(() => {
            toastInfo("error", t('errorOccurred'));
            setOpenPostFactumStatusDialog(false);
        });

    }

    useEffect(() => {
        getPostFactumStatuses({}).then((res: any) => {
            setPostFactumStatuses(res.data);
        })
    }, []);

    const updateEvents = () => {
        const searchCriteria = {
            createdByUserId: localStorage.getItem("userId"),
            status: status,
            showPastEvents: showPastEvents,
            page: {
                offset: rowsPerPage * page,
                limit: rowsPerPage,
                sortOrder: howToSort,
                sortField: "creationDate",
            }
        }
        searchEvent({searchCriteria}).then((res: any) => {
            setEvents(res.data);
        })
        searchEventCount({searchCriteria}).then((res: any) => {
            setTotalRecords(res.data);
        })
    }

    useEffect(() => {
        updateEvents();
        // eslint-disable-next-line
    }, [howToSort, page, rowsPerPage, showPastEvents])

    useEffect(() => {
        let title = "";
        if (!status) {
            title = t('reservation.my.allTitle');
        } else if (status === "ACCEPTED") {
            title = t('reservation.my.acceptedTitle');
        } else if (status === "REJECTED") {
            title = t('reservation.my.rejectedTitle');
        } else if (status === "PENDING") {
            title = t('reservation.my.pendingTitle');
        } else if (status === "NEGOTIATING") {
            title = t('reservation.my.negotiatingTitle');
        }
        setStatusString(title);
        updateEvents();
        // eslint-disable-next-line
    }, [status, t]);

    return (
        <div className={classes.reservationList}>
            <div className={classes.headerContainer}>
                <div className={classes.header}><h2>{statusString}</h2></div>
                <CustomTextButton width={"160px"} backgroundColor={Colors.LIGHT_GREY}
                                  onClick={() => goToMakeReservation()}
                                  text={t('reservation.makeReservation')}
                                  icon={<AddIcon/>}/>
            </div>
            <div className={classes.sorting}>
                <CustomSelectOne value={howToSort} setValue={setHowToSort} items={sortBy} label={t('reservation.sortLabel')}/>
            </div>
            <div className={classes.sorting}>
                <ToggleButton checked={showPastEvents} onChange={(e) => setShowPastEvents(e.target.value)}
                              offLabel={t('reservation.scheduled')} onLabel={t('reservation.ended')} className={classes.button}/>
            </div>
            {events.map((event: any) => (
                <ReservationInfoTile eventDTO={event} key={event.id} clickable={true}
                                     onClick={() => verifyPostFactumOrGoToEditView(event.requestStatus, event.id, event.postFactumStatus)}/>
            ))}
            <Table>
                <TableFooter>
                    <TableRow>
                        <CustomPagination page={page}
                                          rowsPerPage={rowsPerPage}
                                          setPage={setPage} setRowsPerPage={setRowsPerPage}
                                          totalRecords={totalRecords}/>
                    </TableRow>
                </TableFooter>
            </Table>
            <Dialog header={t('reservation.postFactumStatus')} visible={openPostFactumStatusDialog}
                    style={{width: '55vw'}}
                    onHide={() => setOpenPostFactumStatusDialog(false)}>
                <CustomSelectOne showClear={true} value={postFactumStatus} setValue={setPostFactumStatus}
                                 items={postFactumStatuses}/>
                <ActionToolbar>
                    <CustomTextButton onClick={savePostFactumStatusChange} backgroundColor={Colors.LIGHT_GREEN}
                                      text={t('actions.save')}/>
                </ActionToolbar>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    reservationList: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    sorting: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "baseline",
        marginBottom: 20,
        marginRight: 11,
        [theme.breakpoints.down('sm')]: {
            justifyContent: "center",
            marginBottom: 30,
        },
    },

    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    header: {
        paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },

    button: {
        backgroundColor: "#e9f0e9 !important",
        color: "#000 !important",
        border: "none !important",
        borderRadius: "10px !important",
        boxShadow: "0 2px #a0a3a0 !important",
    },
}));

export default MyReservationListView;