import React, {useRef} from "react";
import {lighten, makeStyles, Theme} from "@material-ui/core/styles";
import {OverlayPanel} from 'primereact/overlaypanel';
import {Box} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {Colors} from "../../../constants/colors";

interface Props {
    onClick?: any,
    eventDTO: any;
    clickable?: boolean;
}

interface StyleProps {
    color: string;
    clickable: boolean;
}

const getColorOrDefault = (eventDTO: any) => {
    const color = eventDTO.studentGroupSubjectTileColorHex;
    if (color === undefined || color === null || color === "") {
        return Colors.GREY;
    }
    return color;
}

const ReservationInfoTile = ({onClick, eventDTO, clickable = false}: Props) => {
    const styleProps: StyleProps = {color: getColorOrDefault(eventDTO), clickable: clickable};
    const classes = useStyles(styleProps);
    const op = useRef<any>(null);
    const {t} = useTranslation();


    const dateToString = () => {
        const date = new Date(eventDTO.date);
        const options: any = {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'};
        return date.toLocaleDateString("pl-PL", options);
    }

    const getClassroomsString = (classroomNames: any) => {
        if (!classroomNames || classroomNames.length === 0) {
            return t('actions.none');
        }
        let returnString = classroomNames[0];
        if (classroomNames.length === 1) {
            return returnString;
        }
        if (classroomNames.length > 1) {
            returnString += ", " + classroomNames[1];
        }
        if (classroomNames.length > 2) {
            returnString += ", " + classroomNames[2];
        }
        if (classroomNames.length > 3) {
            returnString += ", ...";
        }
        return returnString;
    }

    return (
        <div onClick={onClick} className={classes.reservationInfoTile}>
            <div className={classes.leftColumn}>
                <div>
                    <b className={classes.bigText}>{eventDTO.studentGroupSubjectName}</b>
                    <b>{eventDTO.teacherFullNameWithTitle}</b>
                </div>
                <div><b>{dateToString()} {eventDTO.startTime.substring(0, 5)}-{eventDTO.endTime.substring(0, 5)}</b>
                </div>
                <b>{t('reservation.group')} {eventDTO.studentGroupName}</b>
                <b>{eventDTO.studentGroupSubjectDepartmentName},
                    {" " + t('reservation.course')} {eventDTO.studentGroupSubjectDegreeCourseName}</b>
            </div>
            <div className={classes.rightColumn}>
                <div className={classes.rightItem}>
                    {((eventDTO.classroomNames && eventDTO.classroomNames.length > 1) ? t('reservation.manyClassrooms') : t('reservation.classroom')) + ":"}
                    {!eventDTO.classroomNames && <b className={classes.bigText} style={{paddingRight: 0}}>
                    {" " + t('actions.none')}
                        </b>}
                    {eventDTO.classroomNames &&
                        <b className={classes.bigText} style={{paddingRight: 0}}>
                            {" " + getClassroomsString(eventDTO.classroomNames)}
                        </b>}
                </div>
                <div className={classes.rightColumn}>
                    <div className={classes.rightItem}>
                        {t('reservation.technician')}:
                        {eventDTO.technicianRequired && <b> {t('actions.yes')}</b>}
                        {!eventDTO.technicianRequired && <b> {t('actions.no')}</b>}
                    </div>
                    <div className={classes.rightItem}>
                        {t('reservation.additionalNotes')}:
                        {eventDTO.additionalNotes === "" && <b> {t('actions.no')}</b>}
                        {eventDTO.additionalNotes !== "" &&
                        <Box onMouseEnter={(e) => {
                                if (op.current) {
                                    op.current.toggle(e);
                                }}}
                             onMouseLeave={(e) => {
                                 if (op.current) {
                                     op.current.toggle(e);
                                 }}}>
                            <b>{t('actions.yes')}</b>
                            <OverlayPanel ref={op} className={classes.overlay}>
                                {eventDTO.additionalNotes}
                            </OverlayPanel>
                        </Box>
                        }
                    </div>
                    <div className={classes.rightItem}>
                        {t('reservation.author')}: <b>{eventDTO.createdBy} {eventDTO.creationDate.substr(0,10)}</b>
                        { eventDTO.modificationDate &&
                            <div>{t('reservation.lastChange')}: <b>{eventDTO.lastModifiedBy} {eventDTO.modificationDate.substr(0, 10)}
                            </b></div> }
                    </div>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    reservationInfoTile: props => ({
        MozBoxSizing: "border-box",
        WebkitBoxSizing: "border-box",
        BoxSizing: "border-box",
        borderRadius: "25px",
        background: lighten(props.color, 0.75),
        width: "100%",
        marginBottom: "25px",
        padding: "25px",
        paddingLeft: "30px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        cursor: props.clickable ? "pointer" : "unset",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            gap: 15,
            padding: 18,
        },
    }),
    leftColumn: {
        display: "flex",
        flexDirection: "column",
        gap: 15,
    },
    rightColumn: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 15,
    },
    rightItem: {
        textAlign: "right",
        [theme.breakpoints.down('sm')]: {
            textAlign: "left",
        },
    },
    bigText: {
        fontSize: "1.3rem",
        paddingRight: "15px",
    },
    overlay: {
        width: "300px",
        height: "300px",
        overflowWrap: "break-word",
    }
}));

export default ReservationInfoTile;