/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form
    }, config)
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    Api Documentation
 ==========================================================*/
/**
 * getCategories
 * request: getCategoriesUsingGET
 * url: getCategoriesUsingGETURL
 * method: getCategoriesUsingGET_TYPE
 * raw_url: getCategoriesUsingGET_RAW_URL
 * @param query - query
 */
export const getCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/categories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getCategoriesUsingGET_RAW_URL = function() {
  return '/dictionary/categories'
}
export const getCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/categories'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getClassrooms
 * request: getClassroomsUsingGET
 * url: getClassroomsUsingGETURL
 * method: getClassroomsUsingGET_TYPE
 * raw_url: getClassroomsUsingGET_RAW_URL
 * @param query - query
 */
export const getClassroomsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/classrooms'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getClassroomsUsingGET_RAW_URL = function() {
  return '/dictionary/classrooms'
}
export const getClassroomsUsingGET_TYPE = function() {
  return 'get'
}
export const getClassroomsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/classrooms'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getClassroomsWithCategories
 * request: getClassroomsWithCategoriesUsingGET
 * url: getClassroomsWithCategoriesUsingGETURL
 * method: getClassroomsWithCategoriesUsingGET_TYPE
 * raw_url: getClassroomsWithCategoriesUsingGET_RAW_URL
 * @param query - query
 */
export const getClassroomsWithCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/classrooms-with-categories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getClassroomsWithCategoriesUsingGET_RAW_URL = function() {
  return '/dictionary/classrooms-with-categories'
}
export const getClassroomsWithCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getClassroomsWithCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/classrooms-with-categories'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getClassroomsWithPublicCategories
 * request: getClassroomsWithPublicCategoriesUsingGET
 * url: getClassroomsWithPublicCategoriesUsingGETURL
 * method: getClassroomsWithPublicCategoriesUsingGET_TYPE
 * raw_url: getClassroomsWithPublicCategoriesUsingGET_RAW_URL
 * @param query - query
 */
export const getClassroomsWithPublicCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/classrooms-with-public-categories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getClassroomsWithPublicCategoriesUsingGET_RAW_URL = function() {
  return '/dictionary/classrooms-with-public-categories'
}
export const getClassroomsWithPublicCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getClassroomsWithPublicCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/classrooms-with-public-categories'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDegreeCourses
 * request: getDegreeCoursesUsingGET
 * url: getDegreeCoursesUsingGETURL
 * method: getDegreeCoursesUsingGET_TYPE
 * raw_url: getDegreeCoursesUsingGET_RAW_URL
 * @param query - query
 */
export const getDegreeCoursesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/degree-courses'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDegreeCoursesUsingGET_RAW_URL = function() {
  return '/dictionary/degree-courses'
}
export const getDegreeCoursesUsingGET_TYPE = function() {
  return 'get'
}
export const getDegreeCoursesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/degree-courses'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDegreeCoursesAndDepartment
 * request: getDegreeCoursesAndDepartmentUsingPOST
 * url: getDegreeCoursesAndDepartmentUsingPOSTURL
 * method: getDegreeCoursesAndDepartmentUsingPOST_TYPE
 * raw_url: getDegreeCoursesAndDepartmentUsingPOST_RAW_URL
 * @param dictionaryTypeDto - dictionaryTypeDTO
 */
export const getDegreeCoursesAndDepartmentUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/degree-courses/department'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dictionaryTypeDto'] !== undefined) {
    body = parameters['dictionaryTypeDto']
  }
  if (parameters['dictionaryTypeDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dictionaryTypeDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getDegreeCoursesAndDepartmentUsingPOST_RAW_URL = function() {
  return '/dictionary/degree-courses/department'
}
export const getDegreeCoursesAndDepartmentUsingPOST_TYPE = function() {
  return 'post'
}
export const getDegreeCoursesAndDepartmentUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/degree-courses/department'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDepartments
 * request: getDepartmentsUsingGET
 * url: getDepartmentsUsingGETURL
 * method: getDepartmentsUsingGET_TYPE
 * raw_url: getDepartmentsUsingGET_RAW_URL
 * @param query - query
 */
export const getDepartmentsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/departments'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getDepartmentsUsingGET_RAW_URL = function() {
  return '/dictionary/departments'
}
export const getDepartmentsUsingGET_TYPE = function() {
  return 'get'
}
export const getDepartmentsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/departments'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateDictionary
 * request: createOrUpdateDictionaryUsingPOST
 * url: createOrUpdateDictionaryUsingPOSTURL
 * method: createOrUpdateDictionaryUsingPOST_TYPE
 * raw_url: createOrUpdateDictionaryUsingPOST_RAW_URL
 * @param dictionaryDto - dictionaryDTO
 */
export const createOrUpdateDictionaryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/dictionary/createOrUpdate'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dictionaryDto'] !== undefined) {
    body = parameters['dictionaryDto']
  }
  if (parameters['dictionaryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dictionaryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateDictionaryUsingPOST_RAW_URL = function() {
  return '/dictionary/dictionary/createOrUpdate'
}
export const createOrUpdateDictionaryUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateDictionaryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/dictionary/createOrUpdate'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDelDictionary
 * request: getDelDictionaryUsingPOST
 * url: getDelDictionaryUsingPOSTURL
 * method: getDelDictionaryUsingPOST_TYPE
 * raw_url: getDelDictionaryUsingPOST_RAW_URL
 * @param dictionaryDto - dictionaryDTO
 */
export const getDelDictionaryUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/dictionary/getDel'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dictionaryDto'] !== undefined) {
    body = parameters['dictionaryDto']
  }
  if (parameters['dictionaryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dictionaryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getDelDictionaryUsingPOST_RAW_URL = function() {
  return '/dictionary/dictionary/getDel'
}
export const getDelDictionaryUsingPOST_TYPE = function() {
  return 'post'
}
export const getDelDictionaryUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/dictionary/getDel'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDictionaries
 * request: getDictionariesUsingPOST
 * url: getDictionariesUsingPOSTURL
 * method: getDictionariesUsingPOST_TYPE
 * raw_url: getDictionariesUsingPOST_RAW_URL
 * @param dictionarySearchDto - dictionarySearchDTO
 */
export const getDictionariesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/dictionary/search-criteria'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dictionarySearchDto'] !== undefined) {
    body = parameters['dictionarySearchDto']
  }
  if (parameters['dictionarySearchDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dictionarySearchDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getDictionariesUsingPOST_RAW_URL = function() {
  return '/dictionary/dictionary/search-criteria'
}
export const getDictionariesUsingPOST_TYPE = function() {
  return 'post'
}
export const getDictionariesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/dictionary/search-criteria'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getDictionariesCount
 * request: getDictionariesCountUsingPOST
 * url: getDictionariesCountUsingPOSTURL
 * method: getDictionariesCountUsingPOST_TYPE
 * raw_url: getDictionariesCountUsingPOST_RAW_URL
 * @param dictionarySearchDto - dictionarySearchDTO
 */
export const getDictionariesCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/dictionary/search-criteria/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dictionarySearchDto'] !== undefined) {
    body = parameters['dictionarySearchDto']
  }
  if (parameters['dictionarySearchDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dictionarySearchDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getDictionariesCountUsingPOST_RAW_URL = function() {
  return '/dictionary/dictionary/search-criteria/count'
}
export const getDictionariesCountUsingPOST_TYPE = function() {
  return 'post'
}
export const getDictionariesCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/dictionary/search-criteria/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEquipments
 * request: getEquipmentsUsingGET
 * url: getEquipmentsUsingGETURL
 * method: getEquipmentsUsingGET_TYPE
 * raw_url: getEquipmentsUsingGET_RAW_URL
 * @param query - query
 */
export const getEquipmentsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/equipments'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEquipmentsUsingGET_RAW_URL = function() {
  return '/dictionary/equipments'
}
export const getEquipmentsUsingGET_TYPE = function() {
  return 'get'
}
export const getEquipmentsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/equipments'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEventTypes
 * request: getEventTypesUsingGET
 * url: getEventTypesUsingGETURL
 * method: getEventTypesUsingGET_TYPE
 * raw_url: getEventTypesUsingGET_RAW_URL
 * @param query - query
 */
export const getEventTypesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/eventTypes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventTypesUsingGET_RAW_URL = function() {
  return '/dictionary/eventTypes'
}
export const getEventTypesUsingGET_TYPE = function() {
  return 'get'
}
export const getEventTypesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/eventTypes'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPublicCategories
 * request: getPublicCategoriesUsingGET
 * url: getPublicCategoriesUsingGETURL
 * method: getPublicCategoriesUsingGET_TYPE
 * raw_url: getPublicCategoriesUsingGET_RAW_URL
 * @param query - query
 */
export const getPublicCategoriesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/public-categories'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPublicCategoriesUsingGET_RAW_URL = function() {
  return '/dictionary/public-categories'
}
export const getPublicCategoriesUsingGET_TYPE = function() {
  return 'get'
}
export const getPublicCategoriesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/public-categories'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getStudentsGroups
 * request: getStudentsGroupsUsingGET
 * url: getStudentsGroupsUsingGETURL
 * method: getStudentsGroupsUsingGET_TYPE
 * raw_url: getStudentsGroupsUsingGET_RAW_URL
 * @param query - query
 */
export const getStudentsGroupsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/student-groups'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getStudentsGroupsUsingGET_RAW_URL = function() {
  return '/dictionary/student-groups'
}
export const getStudentsGroupsUsingGET_TYPE = function() {
  return 'get'
}
export const getStudentsGroupsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/student-groups'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSubjectWithDegreeCoursesAndDepartment
 * request: getSubjectWithDegreeCoursesAndDepartmentUsingPOST
 * url: getSubjectWithDegreeCoursesAndDepartmentUsingPOSTURL
 * method: getSubjectWithDegreeCoursesAndDepartmentUsingPOST_TYPE
 * raw_url: getSubjectWithDegreeCoursesAndDepartmentUsingPOST_RAW_URL
 * @param dictionaryTypeDto - dictionaryTypeDTO
 */
export const getSubjectWithDegreeCoursesAndDepartmentUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/subject/departmentAndDegreeCourse'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dictionaryTypeDto'] !== undefined) {
    body = parameters['dictionaryTypeDto']
  }
  if (parameters['dictionaryTypeDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dictionaryTypeDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getSubjectWithDegreeCoursesAndDepartmentUsingPOST_RAW_URL = function() {
  return '/dictionary/subject/departmentAndDegreeCourse'
}
export const getSubjectWithDegreeCoursesAndDepartmentUsingPOST_TYPE = function() {
  return 'post'
}
export const getSubjectWithDegreeCoursesAndDepartmentUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/subject/departmentAndDegreeCourse'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getSubjects
 * request: getSubjectsUsingGET
 * url: getSubjectsUsingGETURL
 * method: getSubjectsUsingGET_TYPE
 * raw_url: getSubjectsUsingGET_RAW_URL
 * @param query - query
 */
export const getSubjectsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/subjects'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getSubjectsUsingGET_RAW_URL = function() {
  return '/dictionary/subjects'
}
export const getSubjectsUsingGET_TYPE = function() {
  return 'get'
}
export const getSubjectsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/subjects'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTeachers
 * request: getTeachersUsingGET
 * url: getTeachersUsingGETURL
 * method: getTeachersUsingGET_TYPE
 * raw_url: getTeachersUsingGET_RAW_URL
 * @param query - query
 */
export const getTeachersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/teachers'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTeachersUsingGET_RAW_URL = function() {
  return '/dictionary/teachers'
}
export const getTeachersUsingGET_TYPE = function() {
  return 'get'
}
export const getTeachersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/teachers'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getTechnicians
 * request: getTechniciansUsingGET
 * url: getTechniciansUsingGETURL
 * method: getTechniciansUsingGET_TYPE
 * raw_url: getTechniciansUsingGET_RAW_URL
 * @param query - query
 */
export const getTechniciansUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/dictionary/technicians'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getTechniciansUsingGET_RAW_URL = function() {
  return '/dictionary/technicians'
}
export const getTechniciansUsingGET_TYPE = function() {
  return 'get'
}
export const getTechniciansUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/dictionary/technicians'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingGET
 * url: errorHtmlUsingGETURL
 * method: errorHtmlUsingGET_TYPE
 * raw_url: errorHtmlUsingGET_RAW_URL
 */
export const errorHtmlUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingGET_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingGET_TYPE = function() {
  return 'get'
}
export const errorHtmlUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingHEAD
 * url: errorHtmlUsingHEADURL
 * method: errorHtmlUsingHEAD_TYPE
 * raw_url: errorHtmlUsingHEAD_RAW_URL
 */
export const errorHtmlUsingHEAD = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('head', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingHEAD_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingHEAD_TYPE = function() {
  return 'head'
}
export const errorHtmlUsingHEADURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPOST
 * url: errorHtmlUsingPOSTURL
 * method: errorHtmlUsingPOST_TYPE
 * raw_url: errorHtmlUsingPOST_RAW_URL
 */
export const errorHtmlUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPOST_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPOST_TYPE = function() {
  return 'post'
}
export const errorHtmlUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPUT
 * url: errorHtmlUsingPUTURL
 * method: errorHtmlUsingPUT_TYPE
 * raw_url: errorHtmlUsingPUT_RAW_URL
 */
export const errorHtmlUsingPUT = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPUT_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPUT_TYPE = function() {
  return 'put'
}
export const errorHtmlUsingPUTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingDELETE
 * url: errorHtmlUsingDELETEURL
 * method: errorHtmlUsingDELETE_TYPE
 * raw_url: errorHtmlUsingDELETE_RAW_URL
 */
export const errorHtmlUsingDELETE = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingDELETE_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingDELETE_TYPE = function() {
  return 'delete'
}
export const errorHtmlUsingDELETEURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingOPTIONS
 * url: errorHtmlUsingOPTIONSURL
 * method: errorHtmlUsingOPTIONS_TYPE
 * raw_url: errorHtmlUsingOPTIONS_RAW_URL
 */
export const errorHtmlUsingOPTIONS = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('options', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingOPTIONS_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingOPTIONS_TYPE = function() {
  return 'options'
}
export const errorHtmlUsingOPTIONSURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * errorHtml
 * request: errorHtmlUsingPATCH
 * url: errorHtmlUsingPATCHURL
 * method: errorHtmlUsingPATCH_TYPE
 * raw_url: errorHtmlUsingPATCH_RAW_URL
 */
export const errorHtmlUsingPATCH = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/error'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('patch', domain + path, body, queryParameters, form, config)
}
export const errorHtmlUsingPATCH_RAW_URL = function() {
  return '/error'
}
export const errorHtmlUsingPATCH_TYPE = function() {
  return 'patch'
}
export const errorHtmlUsingPATCHURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/error'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * acceptEventRequest
 * request: acceptEventRequestUsingPOST
 * url: acceptEventRequestUsingPOSTURL
 * method: acceptEventRequestUsingPOST_TYPE
 * raw_url: acceptEventRequestUsingPOST_RAW_URL
 * @param assignedTechnicianId - assignedTechnicianId
 * @param eventId - eventId
 */
export const acceptEventRequestUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/accept'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['assignedTechnicianId'] !== undefined) {
    queryParameters['assignedTechnicianId'] = parameters['assignedTechnicianId']
  }
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['eventId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const acceptEventRequestUsingPOST_RAW_URL = function() {
  return '/event/accept'
}
export const acceptEventRequestUsingPOST_TYPE = function() {
  return 'post'
}
export const acceptEventRequestUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/accept'
  if (parameters['assignedTechnicianId'] !== undefined) {
    queryParameters['assignedTechnicianId'] = parameters['assignedTechnicianId']
  }
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * areThereLongerClasses
 * request: areThereLongerClassesUsingPOST
 * url: areThereLongerClassesUsingPOSTURL
 * method: areThereLongerClassesUsingPOST_TYPE
 * raw_url: areThereLongerClassesUsingPOST_RAW_URL
 * @param hour - hour
 * @param searchCriteria - searchCriteria
 */
export const areThereLongerClassesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/after-hour'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['hour'] !== undefined) {
    queryParameters['hour'] = parameters['hour']
  }
  if (parameters['hour'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: hour'))
  }
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const areThereLongerClassesUsingPOST_RAW_URL = function() {
  return '/event/after-hour'
}
export const areThereLongerClassesUsingPOST_TYPE = function() {
  return 'post'
}
export const areThereLongerClassesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/after-hour'
  if (parameters['hour'] !== undefined) {
    queryParameters['hour'] = parameters['hour']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllEvents
 * request: getAllEventsUsingGET
 * url: getAllEventsUsingGETURL
 * method: getAllEventsUsingGET_TYPE
 * raw_url: getAllEventsUsingGET_RAW_URL
 */
export const getAllEventsUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getAllEventsUsingGET_RAW_URL = function() {
  return '/event/all'
}
export const getAllEventsUsingGET_TYPE = function() {
  return 'get'
}
export const getAllEventsUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/all'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePostFactumStatus
 * request: changePostFactumStatusUsingPOST
 * url: changePostFactumStatusUsingPOSTURL
 * method: changePostFactumStatusUsingPOST_TYPE
 * raw_url: changePostFactumStatusUsingPOST_RAW_URL
 * @param request - request
 */
export const changePostFactumStatusUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/changePostFactumStatus'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['request'] !== undefined) {
    body = parameters['request']
  }
  if (parameters['request'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: request'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePostFactumStatusUsingPOST_RAW_URL = function() {
  return '/event/changePostFactumStatus'
}
export const changePostFactumStatusUsingPOST_TYPE = function() {
  return 'post'
}
export const changePostFactumStatusUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/changePostFactumStatus'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * commentEventRequest
 * request: commentEventRequestUsingPOST
 * url: commentEventRequestUsingPOSTURL
 * method: commentEventRequestUsingPOST_TYPE
 * raw_url: commentEventRequestUsingPOST_RAW_URL
 * @param eventId - eventId
 * @param requestComment - requestComment
 */
export const commentEventRequestUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/comment'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['eventId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventId'))
  }
  if (parameters['requestComment'] !== undefined) {
    queryParameters['requestComment'] = parameters['requestComment']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const commentEventRequestUsingPOST_RAW_URL = function() {
  return '/event/comment'
}
export const commentEventRequestUsingPOST_TYPE = function() {
  return 'post'
}
export const commentEventRequestUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/comment'
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['requestComment'] !== undefined) {
    queryParameters['requestComment'] = parameters['requestComment']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getNewEvent
 * request: getNewEventUsingGET
 * url: getNewEventUsingGETURL
 * method: getNewEventUsingGET_TYPE
 * raw_url: getNewEventUsingGET_RAW_URL
 * @param room - room
 * @param time - time
 */
export const getNewEventUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/create'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['room'] !== undefined) {
    queryParameters['room'] = parameters['room']
  }
  if (parameters['room'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: room'))
  }
  if (parameters['time'] !== undefined) {
    queryParameters['time'] = parameters['time']
  }
  if (parameters['time'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: time'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getNewEventUsingGET_RAW_URL = function() {
  return '/event/create'
}
export const getNewEventUsingGET_TYPE = function() {
  return 'get'
}
export const getNewEventUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/create'
  if (parameters['room'] !== undefined) {
    queryParameters['room'] = parameters['room']
  }
  if (parameters['time'] !== undefined) {
    queryParameters['time'] = parameters['time']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * eventToCsv
 * request: eventToCsvUsingGET
 * url: eventToCsvUsingGETURL
 * method: eventToCsvUsingGET_TYPE
 * raw_url: eventToCsvUsingGET_RAW_URL
 */
export const eventToCsvUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/csv'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const eventToCsvUsingGET_RAW_URL = function() {
  return '/event/csv'
}
export const eventToCsvUsingGET_TYPE = function() {
  return 'get'
}
export const eventToCsvUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/csv'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getEvent
 * request: getEventUsingGET
 * url: getEventUsingGETURL
 * method: getEventUsingGET_TYPE
 * raw_url: getEventUsingGET_RAW_URL
 * @param id - id
 */
export const getEventUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/get/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getEventUsingGET_RAW_URL = function() {
  return '/event/get/{id}'
}
export const getEventUsingGET_TYPE = function() {
  return 'get'
}
export const getEventUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/get/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getPostFactumStatuses
 * request: getPostFactumStatusesUsingGET
 * url: getPostFactumStatusesUsingGETURL
 * method: getPostFactumStatusesUsingGET_TYPE
 * raw_url: getPostFactumStatusesUsingGET_RAW_URL
 */
export const getPostFactumStatusesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/postFactumStatuses'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getPostFactumStatusesUsingGET_RAW_URL = function() {
  return '/event/postFactumStatuses'
}
export const getPostFactumStatusesUsingGET_TYPE = function() {
  return 'get'
}
export const getPostFactumStatusesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/postFactumStatuses'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * rejectEventRequest
 * request: rejectEventRequestUsingPOST
 * url: rejectEventRequestUsingPOSTURL
 * method: rejectEventRequestUsingPOST_TYPE
 * raw_url: rejectEventRequestUsingPOST_RAW_URL
 * @param eventId - eventId
 */
export const rejectEventRequestUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/reject'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters['eventId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const rejectEventRequestUsingPOST_RAW_URL = function() {
  return '/event/reject'
}
export const rejectEventRequestUsingPOST_TYPE = function() {
  return 'post'
}
export const rejectEventRequestUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/reject'
  if (parameters['eventId'] !== undefined) {
    queryParameters['eventId'] = parameters['eventId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRepeatFrequencyTypes
 * request: getRepeatFrequencyTypesUsingGET
 * url: getRepeatFrequencyTypesUsingGETURL
 * method: getRepeatFrequencyTypesUsingGET_TYPE
 * raw_url: getRepeatFrequencyTypesUsingGET_RAW_URL
 */
export const getRepeatFrequencyTypesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/repeatFrequencyTypes'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRepeatFrequencyTypesUsingGET_RAW_URL = function() {
  return '/event/repeatFrequencyTypes'
}
export const getRepeatFrequencyTypesUsingGET_TYPE = function() {
  return 'get'
}
export const getRepeatFrequencyTypesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/repeatFrequencyTypes'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * reportToCsv
 * request: reportToCsvUsingPOST
 * url: reportToCsvUsingPOSTURL
 * method: reportToCsvUsingPOST_TYPE
 * raw_url: reportToCsvUsingPOST_RAW_URL
 * @param reportQueryDto - reportQueryDTO
 */
export const reportToCsvUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/reports/csv'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['reportQueryDto'] !== undefined) {
    body = parameters['reportQueryDto']
  }
  if (parameters['reportQueryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reportQueryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const reportToCsvUsingPOST_RAW_URL = function() {
  return '/event/reports/csv'
}
export const reportToCsvUsingPOST_TYPE = function() {
  return 'post'
}
export const reportToCsvUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/reports/csv'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getReport
 * request: getReportUsingPOST
 * url: getReportUsingPOSTURL
 * method: getReportUsingPOST_TYPE
 * raw_url: getReportUsingPOST_RAW_URL
 * @param reportQueryDto - reportQueryDTO
 */
export const getReportUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/reports/time'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['reportQueryDto'] !== undefined) {
    body = parameters['reportQueryDto']
  }
  if (parameters['reportQueryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reportQueryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getReportUsingPOST_RAW_URL = function() {
  return '/event/reports/time'
}
export const getReportUsingPOST_TYPE = function() {
  return 'post'
}
export const getReportUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/reports/time'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * reportToXlsx
 * request: reportToXlsxUsingPOST
 * url: reportToXlsxUsingPOSTURL
 * method: reportToXlsxUsingPOST_TYPE
 * raw_url: reportToXlsxUsingPOST_RAW_URL
 * @param reportQueryDto - reportQueryDTO
 */
export const reportToXlsxUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/reports/xlsx'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['reportQueryDto'] !== undefined) {
    body = parameters['reportQueryDto']
  }
  if (parameters['reportQueryDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reportQueryDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const reportToXlsxUsingPOST_RAW_URL = function() {
  return '/event/reports/xlsx'
}
export const reportToXlsxUsingPOST_TYPE = function() {
  return 'post'
}
export const reportToXlsxUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/reports/xlsx'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRequestStatuses
 * request: getRequestStatusesUsingGET
 * url: getRequestStatusesUsingGETURL
 * method: getRequestStatusesUsingGET_TYPE
 * raw_url: getRequestStatusesUsingGET_RAW_URL
 */
export const getRequestStatusesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/requestStatuses'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRequestStatusesUsingGET_RAW_URL = function() {
  return '/event/requestStatuses'
}
export const getRequestStatusesUsingGET_TYPE = function() {
  return 'get'
}
export const getRequestStatusesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/requestStatuses'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * saveEvent
 * request: saveEventUsingPOST
 * url: saveEventUsingPOSTURL
 * method: saveEventUsingPOST_TYPE
 * raw_url: saveEventUsingPOST_RAW_URL
 * @param eventDto - eventDTO
 */
export const saveEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/save'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['eventDto'] !== undefined) {
    body = parameters['eventDto']
  }
  if (parameters['eventDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: eventDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const saveEventUsingPOST_RAW_URL = function() {
  return '/event/save'
}
export const saveEventUsingPOST_TYPE = function() {
  return 'post'
}
export const saveEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/save'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEvent
 * request: searchEventUsingPOST
 * url: searchEventUsingPOSTURL
 * method: searchEventUsingPOST_TYPE
 * raw_url: searchEventUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEventUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/search/event'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEventUsingPOST_RAW_URL = function() {
  return '/event/search/event'
}
export const searchEventUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEventUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/search/event'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEventCount
 * request: searchEventCountUsingPOST
 * url: searchEventCountUsingPOSTURL
 * method: searchEventCountUsingPOST_TYPE
 * raw_url: searchEventCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEventCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/search/event/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEventCountUsingPOST_RAW_URL = function() {
  return '/event/search/event/count'
}
export const searchEventCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEventCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/search/event/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchEventCountsByStatus
 * request: searchEventCountsByStatusUsingPOST
 * url: searchEventCountsByStatusUsingPOSTURL
 * method: searchEventCountsByStatusUsingPOST_TYPE
 * raw_url: searchEventCountsByStatusUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchEventCountsByStatusUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/search/event/count/status'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchEventCountsByStatusUsingPOST_RAW_URL = function() {
  return '/event/search/event/count/status'
}
export const searchEventCountsByStatusUsingPOST_TYPE = function() {
  return 'post'
}
export const searchEventCountsByStatusUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/search/event/count/status'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * eventToXlsx
 * request: eventToXlsxUsingGET
 * url: eventToXlsxUsingGETURL
 * method: eventToXlsxUsingGET_TYPE
 * raw_url: eventToXlsxUsingGET_RAW_URL
 */
export const eventToXlsxUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/event/xlsx'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const eventToXlsxUsingGET_RAW_URL = function() {
  return '/event/xlsx'
}
export const eventToXlsxUsingGET_TYPE = function() {
  return 'get'
}
export const eventToXlsxUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/event/xlsx'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * isTokenValid
 * request: isTokenValidUsingGET
 * url: isTokenValidUsingGETURL
 * method: isTokenValidUsingGET_TYPE
 * raw_url: isTokenValidUsingGET_RAW_URL
 */
export const isTokenValidUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/is-token-valid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const isTokenValidUsingGET_RAW_URL = function() {
  return '/is-token-valid'
}
export const isTokenValidUsingGET_TYPE = function() {
  return 'get'
}
export const isTokenValidUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/is-token-valid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * login
 * request: loginUsingPOST
 * url: loginUsingPOSTURL
 * method: loginUsingPOST_TYPE
 * raw_url: loginUsingPOST_RAW_URL
 * @param authenticationRequest - authenticationRequest
 */
export const loginUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['authenticationRequest'] !== undefined) {
    body = parameters['authenticationRequest']
  }
  if (parameters['authenticationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: authenticationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const loginUsingPOST_RAW_URL = function() {
  return '/login'
}
export const loginUsingPOST_TYPE = function() {
  return 'post'
}
export const loginUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/login'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * register
 * request: registerUsingPOST
 * url: registerUsingPOSTURL
 * method: registerUsingPOST_TYPE
 * raw_url: registerUsingPOST_RAW_URL
 * @param registrationRequest - registrationRequest
 */
export const registerUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/register'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['registrationRequest'] !== undefined) {
    body = parameters['registrationRequest']
  }
  if (parameters['registrationRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: registrationRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const registerUsingPOST_RAW_URL = function() {
  return '/register'
}
export const registerUsingPOST_TYPE = function() {
  return 'post'
}
export const registerUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/register'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * confirmEmail
 * request: confirmEmailUsingPOST
 * url: confirmEmailUsingPOSTURL
 * method: confirmEmailUsingPOST_TYPE
 * raw_url: confirmEmailUsingPOST_RAW_URL
 * @param confirmEmailToken - confirmEmailToken
 */
export const confirmEmailUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/register/email/confirm/{confirmEmailToken}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{confirmEmailToken}', `${parameters['confirmEmailToken']}`)
  if (parameters['confirmEmailToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: confirmEmailToken'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const confirmEmailUsingPOST_RAW_URL = function() {
  return '/register/email/confirm/{confirmEmailToken}'
}
export const confirmEmailUsingPOST_TYPE = function() {
  return 'post'
}
export const confirmEmailUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/register/email/confirm/{confirmEmailToken}'
  path = path.replace('{confirmEmailToken}', `${parameters['confirmEmailToken']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateRestrictedDateTime
 * request: createOrUpdateRestrictedDateTimeUsingPOST
 * url: createOrUpdateRestrictedDateTimeUsingPOSTURL
 * method: createOrUpdateRestrictedDateTimeUsingPOST_TYPE
 * raw_url: createOrUpdateRestrictedDateTimeUsingPOST_RAW_URL
 * @param restrictedDateTimesDto - restrictedDateTimesDTO
 */
export const createOrUpdateRestrictedDateTimeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/restricted-date-time'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['restrictedDateTimesDto'] !== undefined) {
    body = parameters['restrictedDateTimesDto']
  }
  if (parameters['restrictedDateTimesDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: restrictedDateTimesDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateRestrictedDateTimeUsingPOST_RAW_URL = function() {
  return '/restricted-date-time'
}
export const createOrUpdateRestrictedDateTimeUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateRestrictedDateTimeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/restricted-date-time'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * checkIsDateRestricted
 * request: checkIsDateRestrictedUsingPOST
 * url: checkIsDateRestrictedUsingPOSTURL
 * method: checkIsDateRestrictedUsingPOST_TYPE
 * raw_url: checkIsDateRestrictedUsingPOST_RAW_URL
 * @param dateString - dateString
 */
export const checkIsDateRestrictedUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/restricted-date-time/check'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateString'] !== undefined) {
    queryParameters['dateString'] = parameters['dateString']
  }
  if (parameters['dateString'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateString'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const checkIsDateRestrictedUsingPOST_RAW_URL = function() {
  return '/restricted-date-time/check'
}
export const checkIsDateRestrictedUsingPOST_TYPE = function() {
  return 'post'
}
export const checkIsDateRestrictedUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/restricted-date-time/check'
  if (parameters['dateString'] !== undefined) {
    queryParameters['dateString'] = parameters['dateString']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * deleteRestrictedDateTime
 * request: deleteRestrictedDateTimeUsingPOST
 * url: deleteRestrictedDateTimeUsingPOSTURL
 * method: deleteRestrictedDateTimeUsingPOST_TYPE
 * raw_url: deleteRestrictedDateTimeUsingPOST_RAW_URL
 * @param id - id
 */
export const deleteRestrictedDateTimeUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/restricted-date-time/delete/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const deleteRestrictedDateTimeUsingPOST_RAW_URL = function() {
  return '/restricted-date-time/delete/{id}'
}
export const deleteRestrictedDateTimeUsingPOST_TYPE = function() {
  return 'post'
}
export const deleteRestrictedDateTimeUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/restricted-date-time/delete/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getRestrictedDateTime
 * request: getRestrictedDateTimeUsingGET
 * url: getRestrictedDateTimeUsingGETURL
 * method: getRestrictedDateTimeUsingGET_TYPE
 * raw_url: getRestrictedDateTimeUsingGET_RAW_URL
 * @param id - id
 */
export const getRestrictedDateTimeUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/restricted-date-time/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getRestrictedDateTimeUsingGET_RAW_URL = function() {
  return '/restricted-date-time/{id}'
}
export const getRestrictedDateTimeUsingGET_TYPE = function() {
  return 'get'
}
export const getRestrictedDateTimeUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/restricted-date-time/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getAllRestrictedDateTimes
 * request: getAllRestrictedDateTimesUsingPOST
 * url: getAllRestrictedDateTimesUsingPOSTURL
 * method: getAllRestrictedDateTimesUsingPOST_TYPE
 * raw_url: getAllRestrictedDateTimesUsingPOST_RAW_URL
 */
export const getAllRestrictedDateTimesUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/restricted-date-time'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const getAllRestrictedDateTimesUsingPOST_RAW_URL = function() {
  return '/search/restricted-date-time'
}
export const getAllRestrictedDateTimesUsingPOST_TYPE = function() {
  return 'post'
}
export const getAllRestrictedDateTimesUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/restricted-date-time'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUser
 * request: searchUserUsingPOST
 * url: searchUserUsingPOSTURL
 * method: searchUserUsingPOST_TYPE
 * raw_url: searchUserUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserUsingPOST_RAW_URL = function() {
  return '/search/user'
}
export const searchUserUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * searchUserCount
 * request: searchUserCountUsingPOST
 * url: searchUserCountUsingPOSTURL
 * method: searchUserCountUsingPOST_TYPE
 * raw_url: searchUserCountUsingPOST_RAW_URL
 * @param searchCriteria - searchCriteria
 */
export const searchUserCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/search/user/count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['searchCriteria'] !== undefined) {
    body = parameters['searchCriteria']
  }
  if (parameters['searchCriteria'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: searchCriteria'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const searchUserCountUsingPOST_RAW_URL = function() {
  return '/search/user/count'
}
export const searchUserCountUsingPOST_TYPE = function() {
  return 'post'
}
export const searchUserCountUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/search/user/count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * createOrUpdateUser
 * request: createOrUpdateUserUsingPOST
 * url: createOrUpdateUserUsingPOSTURL
 * method: createOrUpdateUserUsingPOST_TYPE
 * raw_url: createOrUpdateUserUsingPOST_RAW_URL
 * @param userDto - userDTO
 */
export const createOrUpdateUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userDto'] !== undefined) {
    body = parameters['userDto']
  }
  if (parameters['userDto'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userDto'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const createOrUpdateUserUsingPOST_RAW_URL = function() {
  return '/user'
}
export const createOrUpdateUserUsingPOST_TYPE = function() {
  return 'post'
}
export const createOrUpdateUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * changePassword
 * request: changePasswordUsingPOST
 * url: changePasswordUsingPOSTURL
 * method: changePasswordUsingPOST_TYPE
 * raw_url: changePasswordUsingPOST_RAW_URL
 * @param passwordChangeRequest - passwordChangeRequest
 */
export const changePasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/change'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordChangeRequest'] !== undefined) {
    body = parameters['passwordChangeRequest']
  }
  if (parameters['passwordChangeRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordChangeRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const changePasswordUsingPOST_RAW_URL = function() {
  return '/user/password/change'
}
export const changePasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const changePasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/change'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * remindPassword
 * request: remindPasswordUsingPOST
 * url: remindPasswordUsingPOSTURL
 * method: remindPasswordUsingPOST_TYPE
 * raw_url: remindPasswordUsingPOST_RAW_URL
 * @param passwordRemindRequest - passwordRemindRequest
 */
export const remindPasswordUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/password/remind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['passwordRemindRequest'] !== undefined) {
    body = parameters['passwordRemindRequest']
  }
  if (parameters['passwordRemindRequest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: passwordRemindRequest'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const remindPasswordUsingPOST_RAW_URL = function() {
  return '/user/password/remind'
}
export const remindPasswordUsingPOST_TYPE = function() {
  return 'post'
}
export const remindPasswordUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/password/remind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * restoreDeletedUser
 * request: restoreDeletedUserUsingPOST
 * url: restoreDeletedUserUsingPOSTURL
 * method: restoreDeletedUserUsingPOST_TYPE
 * raw_url: restoreDeletedUserUsingPOST_RAW_URL
 * @param userId - userId
 */
export const restoreDeletedUserUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/restoreDeleted'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['userId'] !== undefined) {
    queryParameters['userId'] = parameters['userId']
  }
  if (parameters['userId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const restoreDeletedUserUsingPOST_RAW_URL = function() {
  return '/user/restoreDeleted'
}
export const restoreDeletedUserUsingPOST_TYPE = function() {
  return 'post'
}
export const restoreDeletedUserUsingPOSTURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/restoreDeleted'
  if (parameters['userId'] !== undefined) {
    queryParameters['userId'] = parameters['userId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUserRoles
 * request: getUserRolesUsingGET
 * url: getUserRolesUsingGETURL
 * method: getUserRolesUsingGET_TYPE
 * raw_url: getUserRolesUsingGET_RAW_URL
 */
export const getUserRolesUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/roles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserRolesUsingGET_RAW_URL = function() {
  return '/user/roles'
}
export const getUserRolesUsingGET_TYPE = function() {
  return 'get'
}
export const getUserRolesUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/roles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUser
 * request: getUserUsingGET
 * url: getUserUsingGETURL
 * method: getUserUsingGET_TYPE
 * raw_url: getUserUsingGET_RAW_URL
 * @param id - id
 */
export const getUserUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/user/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUserUsingGET_RAW_URL = function() {
  return '/user/{id}'
}
export const getUserUsingGET_TYPE = function() {
  return 'get'
}
export const getUserUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/user/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * getUsers
 * request: getUsersUsingGET
 * url: getUsersUsingGETURL
 * method: getUsersUsingGET_TYPE
 * raw_url: getUsersUsingGET_RAW_URL
 * @param query - query
 */
export const getUsersUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/users'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters['query'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: query'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const getUsersUsingGET_RAW_URL = function() {
  return '/users'
}
export const getUsersUsingGET_TYPE = function() {
  return 'get'
}
export const getUsersUsingGETURL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/users'
  if (parameters['query'] !== undefined) {
    queryParameters['query'] = parameters['query']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}