import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {CircularProgress, Paper, TableContainer, TableFooter} from "@material-ui/core";
import CustomTextButton from "../../../components/form/CustomTextButton";
import {Colors} from "../../../constants/colors";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {IUser} from "../../../interfaces";
import {SystemRole} from "../../../utils/SystemRole";
import {useTranslation} from "react-i18next";
import CustomPagination from "../../../components/CustomPagination";
import SortableTableHead from "../../../components/SortableTableHead";

interface Props {
    users: IUser[];
    isLoading: Boolean;
    searchCriteria: any;
    totalRecords: number;
    updateSearchCriteria: any;
}

const UserTable = ({users, isLoading, searchCriteria, totalRecords, updateSearchCriteria} : Props) => {

    const history = useHistory();
    const {t} = useTranslation();

    const headCells = [
        {label: t('user.firstName'), field: "firstName", joinField: "", joinField2: "", sortable: true},
        {label: t('user.lastName'), field: "lastName", joinField: "", joinField2: "", sortable: true},
        {label: t('user.email'), field: "email", joinField: "", joinField2: "", sortable: true},
        {label: t('user.role'), field: "role", joinField: "", joinField2: "", sortable: false},
        {label: "", field: "", joinField: "", joinField2: "", sortable: false}];

    const classes = useStyles();

    const showUser = (id : string) => {
        history.push({pathname: "/user/" + id});
    };

    const setPageNr = (page: number) => {
        const newSearchCriteria = {
            ...searchCriteria,
            page: {
                ...searchCriteria.page,
                pageNr: page,
                offset: page * searchCriteria.page.limit,
            }
        }
        updateSearchCriteria(newSearchCriteria);
    }
    const setRowsPerPage = (rowsPerPage: number) => {
        const newSearchCriteria = {
            ...searchCriteria,
            page: {
                ...searchCriteria.page,
                offset: searchCriteria.page.pageNr * rowsPerPage,
                limit: rowsPerPage,
            }
        }
        updateSearchCriteria(newSearchCriteria);
    }

    const setPage = (page: any) => {
        updateSearchCriteria({
            ...searchCriteria,
            page: {
                ...searchCriteria.page,
                ...page
            }
        });
    }

    return (
        <>
            {isLoading ? <CircularProgress/> :
                <TableContainer component={Paper} className={classes.userTable}>
                    <Table>
                        <SortableTableHead headCells={headCells} page={searchCriteria.page} setPage={setPage}/>

                        <TableBody>
                            {users.length === 0 && <TableRow><TableCell>{t('user.notFound')}</TableCell></TableRow>}
                            {users.map(user => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{SystemRole.roles.filter((r) => r.value === user.systemRole)[0].label}</TableCell>
                                    <TableCell width={80}>
                                        <CustomTextButton text={t('actions.show')}
                                                          onClick={() => showUser(user.id as string)}
                                                          backgroundColor={Colors.LIGHT_GREEN}/>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <CustomPagination
                                    page={searchCriteria.page.pageNr}
                                    setPage={setPageNr}
                                    rowsPerPage={searchCriteria.page.limit}
                                    setRowsPerPage={setRowsPerPage}
                                    totalRecords={totalRecords}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            }
        </>
    );
}

const useStyles = makeStyles(() => ({
    userTable: {
        margin: 5,
    },
}));

export default UserTable;