import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import pl from "./locales/pl/translation.json";
import en from "./locales/en/translation.json";

i18n
    .use(initReactI18next)
    .init({
        lng: "pl",
        debug: true,
        interpolation: { escapeValue: false },
        resources: {
            en: {
                translation: en,
            },
            pl: {
                translation: pl,
            },
        },
    });

/*
<h1>{t('description.part1')}</h1>
<Trans t={t}>description.part1</Trans>
 */

export default i18n;