import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import logoUE from "../assets/FE_Wiedza_Edukacja_Rozwoj_rgb-1.jpg";
import logaUE from "../assets/logotypyUE.png";
import {Colors} from "../constants/colors";
interface Props {
    publicView: boolean;
}

const Footer = ({publicView}: Props) => {
    const classes = useStyles();
    const {t} = useTranslation();
    if (publicView)
        return (
            <div className={classes.publicFooterContainer}>
                <img src={logaUE} alt={t('app.logo')} className={classes.publicEULogos}/>
                <span style={{fontSize: "85%", textAlign: "center", marginRight: "30px"}}>Projekt <i>„Wdrożenie programu rozwoju Uniwersytetu Medycznego w Białymstoku z wykorzystaniem
                    Centrum Symulacji Medycznej”</i><br/>
                    współfinansowany przez Unię Europejską ze środków Europejskiego Funduszu Społecznego <br/>
                    w ramach Programu Operacyjnego Wiedza Edukacja Rozwój 2014 - 2020.</span>
                <span style={{fontSize: "85%"}}>{t('app.version')} 1.53.7</span>
            </div>
        );
    return (
            <div className={classes.footerContainer}>
                <img src={logoUE} alt={t('app.logo')} className={classes.EULogos}/>
                <span style={{fontSize: "85%"}}>{t('app.version')} 1.53.7</span>
            </div>
        )
}

const useStyles = makeStyles(() => ({
    footerContainer: {
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "solid 1px white",
        borderColor: Colors.LIGHT_GREY,
    },
    publicFooterContainer: {
        backgroundColor: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "solid 1px white",
        borderColor: Colors.LIGHT_GREY,
        position: "fixed",
        bottom: 0,
        width: "100%",
    },
    EULogos: {
        height: 70,
        maxWidth: "100vw",
    },
    publicEULogos: {
        height: 70,
        maxWidth: "100vw",
    }
}));

export default Footer;