import 'react-toastify/dist/ReactToastify.css';
import {toast} from "react-toastify";

export const toastInfo = (type: string, text: string) => {
    if (type === "success") {
        toast.success(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
        });
    }
    else if (type === "error") {
        toast.error(text, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            theme: "colored",
        });
    }
}