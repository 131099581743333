import React from 'react';
import {Box, FormControlLabel} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {Checkbox} from "primereact/checkbox";

interface Props {
    value: boolean,
    setValue: any,
    disabled?: boolean,
    label?: any;
}

const useStyles = makeStyles(() => ({
    container: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
}));


const CustomCheckbox = ({value, setValue, label = "", disabled = false}: Props) => {
    const spaceBeforeLabel = label === "" ? "0px" : "30px";
    const {container} = useStyles();

    return (
        <Box className={container}>
            <FormControlLabel
                control={
                    <Checkbox onChange={e => setValue(e.checked)} checked={value} disabled={disabled}/>
                }
                label={label}
                labelPlacement={"end"}
                sx={{cursor: "initial", gap: spaceBeforeLabel}}
                style={{marginLeft: "15px"}}
            />
        </Box>
    )
}

export default CustomCheckbox;