import React from 'react'
import {useTranslation} from "react-i18next";

const NotFoundView = () => {

    const {t} = useTranslation();

    return (
        <div style={{marginTop: 150, textAlign: "center"}}>
            <h1>{t('notFound.title')}</h1>
        </div>
    )
}

export default NotFoundView;