import React, {useEffect, useState} from "react";
import TextWithLabel from "../../components/form/TextWithLabel";
import {Stack} from "@mui/material";
import {ActionToolbar} from "../../utils/styled";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import {getDictionariesUsingPOST as getDictionaries} from "../../swagger/vue-api-client";
import CustomAutocomplete from "../../components/form/CustomAutocomplete";
import {toastInfo} from "../../services/InfoService";
import CustomInputText from "../../components/form/CustomInputText";
import {useTranslation} from "react-i18next";
import {findEnumValue} from "../../constants/dictType";
import {SystemRole} from "../../utils/SystemRole";

interface Props {
    event: any;
    decisionView: boolean;
    editOnClick?: any;
    acceptOnClick?: any;
    rejectOnClick?: any;
    commentOnClick?: any;
}

const ReservationView = ({event, decisionView, editOnClick, acceptOnClick, rejectOnClick, commentOnClick} : Props) => {
    const [assignedTechnician, setAssignedTechnician] = useState({name: event.technicianName, id: event.technicianId});
    const [debriefingRoom, setDebriefingRoom] = useState({name: event.debriefingRoom, id: event.debriefingRoomId});
    const [classroom, setClassroom] = useState({name: event.classroom, id: event.classroomId});
    const [classroom2, setClassroom2] = useState({name: event.classroom2, id: event.classroom2Id});
    const [requestComment, setRequestComment] = useState<string>("");
    const [displayCommentInput, setDisplayCommentInput] = useState(false);
    const {t} = useTranslation();

    const acceptRequest = () => {
        if (event.technicianRequired === t('actions.yes') && !assignedTechnician.id) {
            toastInfo("error", t('reservation.noTechnician'));
            return;
        }
        acceptOnClick(event.id, assignedTechnician.id);
    }

    const commentRequest = () => {
        if (!requestComment) {
            toastInfo("error", t('reservation.noFeedback'));
            return;
        }
        commentOnClick(event.id, requestComment);
    }

    const getRepeatFrequencyString = (repeatFrequency: string) => {
        if (!repeatFrequency || repeatFrequency === t('actions.none')) {
            return t('actions.none');
        }
        return repeatFrequency === "WEEKLY" ? t('reservation.weekly') : t('reservation.daily');
    }

    const getPostFactumStatusString = (postFactumStatus: string) => {
        if (!postFactumStatus || postFactumStatus === t('actions.none')) {
            return t('actions.none');
        }
        if (postFactumStatus === "OK") {
            return t('reservation.accordingToPlan');
        } else if (postFactumStatus === "DELAYED") {
            return t('reservation.delayed');
        } else {
            return t('reservation.cancelled');
        }
    }

    return (
        <div>
            <Stack direction={"column"} spacing={1}>
                {(event.requestComment !== null && event.requestComment !== undefined) &&
                    <TextWithLabel label={<b>{t('reservation.requestComment')}</b>} text={<b style={{color: 'red'}}>{event.requestComment}</b>} />
                }
                {event.postFactumStatus !== null && event.postFactumStatus !== undefined &&
                    <TextWithLabel label={<b>{t('reservation.postFactumStatus')}</b>}
                                   text={getPostFactumStatusString(event.postFactumStatus)}/>
                }
                <TextWithLabel label={<b>{t('reservation.date')}</b>} text={event.date} />
                <TextWithLabel label={<b>{t('reservation.startTime')}</b>} text={event.startTime} />
                <TextWithLabel label={<b>{t('reservation.endTime')}</b>} text={event.endTime} />
                <TextWithLabel label={<b>{t('reservation.eventType')}</b>} text={event.eventType} />
                <TextWithLabel label={<b>{t('reservation.departmentName')}</b>} text={event.departmentName} />
                <TextWithLabel label={<b>{t('reservation.degreeCourse')}</b>} text={event.degreeCourse} />
                <TextWithLabel label={<b>{t('reservation.subjectName')}</b>} text={event.subjectName} />
                <TextWithLabel label={<b>{t('reservation.studentGroup')}</b>} text={event.studentGroup} />
                <TextWithLabel label={<b>{t('reservation.anyClassroom')}</b>} text={event.anyClassroom}/>
                {event.anyClassroom === t('actions.no') && <React.Fragment>
                    <TextWithLabel label={<b>{t('reservation.classroom')}</b>} text={event.classroom} />
                    <TextWithLabel label={<b>{t('reservation.classroom2')}</b>} text={event.classroom2} />
                </React.Fragment>}
                {(decisionView && event.anyClassroom === t('actions.yes') && event.requestStatus === "PENDING") &&
                    <React.Fragment>
                        <CustomAutocomplete value={classroom} setValue={setClassroom} completeMethod={getDictionaries}
                                            filters={{
                                                dictType: findEnumValue("CLASSROOM"),
                                            }}
                                            label={t('reservation.classroom')}/>
                        <CustomAutocomplete value={classroom2} setValue={setClassroom2} completeMethod={getDictionaries}
                                            filters={{
                                                dictType: findEnumValue("CLASSROOM"),
                                            }}
                                            label={t('reservation.classroom')}/>
                    </React.Fragment>
                }
                {(decisionView && event.anyClassroom === t('actions.yes') && event.requestStatus !== "PENDING") && <React.Fragment>
                    <TextWithLabel label={<b>{t('reservation.classroom')}</b>} text={event.classroom} />
                    <TextWithLabel label={<b>{t('reservation.classroom2')}</b>} text={event.classroom2} />
                    </React.Fragment>}

                <TextWithLabel label={<b>{t('reservation.debriefingRoomRequired')}</b>} text={event.debriefingRoomRequired}/>
                { (decisionView && event.debriefingRoomRequired === t('actions.yes') && event.requestStatus === "PENDING") &&
                    <CustomAutocomplete value={debriefingRoom} setValue={setDebriefingRoom} completeMethod={getDictionaries}
                                        filters={{
                                            dictType: findEnumValue("CLASSROOM"),
                                        }}
                                        label={t('reservation.debriefingRoom')}/> }
                { (decisionView && event.debriefingRoomRequired === t('actions.yes') && event.requestStatus !== "PENDING") &&
                    <TextWithLabel label={<b>{t('reservation.debriefingRoom')}</b>} text={event.debriefingRoom} />}
                <TextWithLabel label={<b>{t('reservation.teacher')}</b>} text={event.teachers} />
                <TextWithLabel label={<b>{t('reservation.requiredEquipment')}</b>} text={event.requiredEquipment} />
                <TextWithLabel label={<b>{t('reservation.technicianRequired')}</b>} text={event.technicianRequired} />
                { (decisionView && event.technicianRequired === t('actions.yes') && event.requestStatus === "PENDING") &&
                <CustomAutocomplete value={assignedTechnician} setValue={setAssignedTechnician} completeMethod={getDictionaries}
                                    filters={{
                                        dictType: findEnumValue("TECHNICIAN"),
                                    }}
                                    label={t('reservation.technician')}/> }
                { (decisionView && event.technicianRequired === t('actions.yes') && event.requestStatus !== "PENDING") &&
                <TextWithLabel label={<b>{t('reservation.technician')}</b>} text={event.technicianName} />}
                <TextWithLabel label={<b>{t('reservation.isRepeating')}</b>} text={event.isRepeating} />
                {event.isRepeating === t('calendar.cyclical') &&
                    <Stack direction={"column"} spacing={1}>
                        <TextWithLabel label={<b>{t('reservation.repeatFrequency')}</b>}
                                       text={getRepeatFrequencyString(event.repeatFrequency)}/>
                        <TextWithLabel label={<b>{t('reservation.repeatEndDate')}</b>} text={event.repeatEndDate} />
                        <TextWithLabel label={<b>{t('reservation.repeatsNumber')}</b>} text={event.repeatsNumber} />
                        <TextWithLabel label={<b>{t('reservation.additionalNotes')}</b>} text={event.additionalNotes} />
                    </Stack>
                }
            </Stack>
            {decisionView && !displayCommentInput &&
            <ActionToolbar>
                {!(SystemRole.isUser() && event.createdBy !== localStorage.getItem("fullName")) &&
                <CustomTextButton onClick={() => editOnClick(event.id)} backgroundColor={Colors.LIGHT_GREY} text={t('actions.edit')}/> }
                {event.requestStatus === "PENDING" &&
                <CustomTextButton onClick={() => acceptRequest()} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.accept')}/> }
                {event.requestStatus === "PENDING" &&
                <CustomTextButton onClick={() => setDisplayCommentInput(!displayCommentInput)} backgroundColor={Colors.LIGHT_BLUE} text={t('reservation.suggestChange')}/> }
                {event.requestStatus === "PENDING" &&
                <CustomTextButton onClick={() => rejectOnClick(event.id)} backgroundColor={Colors.LIGHT_RED} text={t('actions.reject')}/> }
            </ActionToolbar> }
            {displayCommentInput &&
            <div>
                <CustomInputText value={requestComment} setValue={setRequestComment} label={t('reservation.requestComment')} margin="auto" />
                <ActionToolbar>
                    <CustomTextButton onClick={() => setDisplayCommentInput(false)} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={() => commentRequest()} backgroundColor={Colors.LIGHT_GREEN} text={t('reservation.sendRequestComment')}/>
                </ActionToolbar>
            </div>}
        </div>
    )
}

export default ReservationView;