import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, useLocation,} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import LeftMenu from "../components/menu/LeftMenu";
import TopMenu from "../components/menu/TopMenu";
import Footer from "../components/Footer";
import PrivateRoute from "../PrivateRoute";
import {searchEventCountsByStatusUsingPOST as searchEventCountsByStatus} from "../swagger/vue-api-client";
import CustomTextButton from "../components/form/CustomTextButton";
import CalendarView from "./calendar/CalendarView";
import HomePage from "./HomePage";
import umb from "../assets/logo_umb.jpg"
import csm from "../assets/logoCSM.png"
import {Colors} from "../constants/colors";
import logaUE from "../assets/logotypyUE.png";
import {SystemRole} from "../utils/SystemRole";

function PrivateContentView() {
    const [activeTab, setActiveTab] = useState(0);
    const [myRequestCounts, setMyRequestCounts] = useState({});
    const [requestCounts, setRequestCounts] = useState({});
    const [openMenu, setOpenMenu] = useState(false);
    const [publicView, setPublicView] = useState<number | null | false>(Number(localStorage.getItem("publicView")));

    const classes = useStyles();

    useEffect(() => {
        updateRequestCounts();
    }, [activeTab]);

    const updateRequestCounts = () => {
        if (SystemRole.isAuthorized()) {
            searchEventCountsByStatus({searchCriteria: {createdByUserId: localStorage.getItem("userId"), showPastEvents: false}}).then((res: any) => {
                setMyRequestCounts(res.data.body);
            });

            if (localStorage.getItem("role") !== "USER") {
                searchEventCountsByStatus({searchCriteria: {showPastEvents: false}}).then((res: any) => {
                    setRequestCounts(res.data.body);
                });
            }
        }
    }

    const closePublicView = () => {
        localStorage.removeItem("publicView");
        setPublicView(null);
    }

    return (
        <Router>
            <div className={classes.appContainer}>
                {!publicView ? (
                    <>
                        <TopMenu openMenu={openMenu} setOpenMenu={setOpenMenu}/>
                        <div className={classes.content}>
                            <LeftMenu
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                                myRequestCounts={myRequestCounts}
                                requestCounts={requestCounts}
                                updateRequestCounts={updateRequestCounts}
                                openMenu={openMenu}
                                setOpenMenu={setOpenMenu}
                                setPublicView={setPublicView}
                            />
                            <div className={classes.rightContainer}>
                                <PrivateRoute updateRequestCounts={updateRequestCounts} setPublicView={setPublicView}/>
                                <Footer publicView={false}/>
                            </div>
                        </div>
                    </>
                ) : (
                    <div className={classes.publicView}>
                        <img src={umb} className={classes.logo}/>
                        <img src={csm} className={classes.logo} style={{right: 0, marginTop: 0}}/>
                        <div className={classes.publicContent}>
                            <HomePage publicView={publicView}/>
                            <div className={classes.exit}>
                                <CustomTextButton onClick={closePublicView} backgroundColor={Colors.LIGHT_GREY} text={"EXIT"}/>
                            </div>
                        </div>
                        <Footer publicView={true}/>
                    </div>
                )}
            </div>
        </Router>
    )
}

const useStyles = makeStyles(theme => ({
    appContainer: {
        minHeight: "100vh",
    },
    logo: {
        height: 70,
        margin: 10,
        position: "fixed",
    },
    exit: {
        right: 30,
        bottom: 70,
        width: 50,
        position: "fixed",
        zIndex: 1,
    },
    content: {
        display: "grid",
        gridTemplateColumns: "min(20%, 300px) auto",
        paddingTop: 90,
        minHeight: "100vh",
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "1fr",
        },
    },
    publicView: {
        flexDirection: "column",
        justifyContent: "space-between",
    },
    rightContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    publicContent: {
        top: 0,
        position: "absolute",
        width: "100%",
        height: "100vh",
    },
}));

export default PrivateContentView;
