import React from 'react';
import {TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Theme} from "@material-ui/core";
import {Colors} from "../../constants/colors";

interface StyleProps {
    margin: string,
    width: string,
}

type TextInputProps = {
    value: string;
    setValue: any;
    label: string;
    disabled?: boolean;
    password?: boolean;
    width?: string,
    margin?: string,
    fullWidth?: boolean
    backgroundColor?: string;
    onKeyPress?: () => void,
}

const CustomInputSearchText = ({value, setValue, label, disabled = false, password = false, width="auto", margin="0px",
                         onKeyPress=() => {}, fullWidth = true}: TextInputProps) => {
    const styleProps: StyleProps = {margin: margin, width: width};
    const classes = useStyles(styleProps)

    return (
        <div className={`${classes.container}`}>
            <TextField
                type={password ? "password" : "text"}
                value={value}
                onChange={(event) => setValue(event.target.value)}
                margin="dense"
                disabled={disabled}
                className={classes.field}
                variant="outlined"
                fullWidth={fullWidth}
                onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                        onKeyPress();
                    }
                }}
            />
        </div>
    );
};

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
    container: props => ({
        margin: props.margin,
        display: "flex",
        // flex: 1,
        width: props.width,
    }),

    field: {
        height: '40px !important',
        background: Colors.WHITE,
        borderRadius: "5px",
        marginBottom: 8,
    },
}))

export default CustomInputSearchText;
