import React from 'react';
import {FormControlLabel} from "@mui/material";
import {Box} from "@material-ui/core";
import CustomInputText from "./form/CustomInputText";

interface Props {
    label: any;
    placeholder: string;
    value: any;
    setValue: any;
    width?: string;
    labelPlacement?: "end" | "start" | "top" | "bottom";
    spaceAfterLabel?: string;
}

const InputTextWithLabel = ({label, placeholder, value, setValue, width = "200px", labelPlacement = "start",
                           spaceAfterLabel = "30px"}: Props) => {
    if (label === "")
        spaceAfterLabel = "0px";

    return (
        <Box>
            <FormControlLabel
                label={<div style={{width: width}}>{label}</div>}
                control={
                    <CustomInputText value={value} setValue={setValue} label={placeholder} width={"260px"} marginTop={"0px"}/>
                }
                labelPlacement={labelPlacement}
                sx={{gap: spaceAfterLabel, alignItems: "center", cursor: "initial"}}
            />
        </Box>
    )
}

export default InputTextWithLabel;