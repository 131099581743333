import React, {useEffect, useState} from "react";
import {CircularProgress, Grid, GridSpacing, Paper,} from "@material-ui/core";

import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import {useHistory, useParams} from "react-router-dom";
import {
    createOrUpdateRestrictedDateTimeUsingPOST as createOrUpdateRestrictedDateTime,
    getRestrictedDateTimeUsingGET as getRestrictedDateTime,
} from "../../swagger/vue-api-client";
import CustomInputText from "../../components/form/CustomInputText";
import {makeStyles} from "@material-ui/core/styles";
import {ActionToolbar} from "../../utils/styled";
import CustomCalendar from "../../components/form/CustomCalendar";
import YesNoSelect from "../../components/form/YesNoSelect";
import {useTranslation} from "react-i18next";
import {toastInfo} from "../../services/InfoService";

const RestrictedDateFormView = () => {
    const { id } = useParams<{id: string}>();
    const [startDateTime, setStartDateTime] = useState<Date>();
    const [endDateTime, setEndDateTime] = useState<Date>();
    const [repeatingEachYear, setRepeatingEachYear] = useState(false);
    const [reason, setReason] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const classes = useStyles();
    const {t} = useTranslation();

    const backToListView = () => {
        history.push({pathname: "/restricted-dates/"});
    }

    const save = () => {
        if (!startDateTime || !endDateTime || startDateTime.getTime() > endDateTime.getTime()) {
            toastInfo("error", t('restrictedDate.incorrectDates'));
            return;
        }
        if (!reason) {
            toastInfo("error", t('restrictedDate.noReason'));
            return;
        }
        const restrictedDateTimesDto = {
            id: (id === "new") ? null : id,
            startDateTime: startDateTime,
            endDateTime: endDateTime,
            repeatingEachYear: repeatingEachYear,
            reason: reason,
        }

        createOrUpdateRestrictedDateTime({restrictedDateTimesDto: restrictedDateTimesDto})
            .then(() => {
                history.push({pathname: "/restricted-dates/"});
            })
            .catch(() => {
                toastInfo("error", t('restrictedDates.errorDuringSavingInfo'));
            });
    }

    useEffect(() => {
        if (id !== "new") {
            getRestrictedDateTime({id})
                .then((res: any) => {
                    setStartDateTime(new Date(Date.parse(res.data.startDateTime)));
                    setEndDateTime(new Date(Date.parse(res.data.endDateTime)));
                    setRepeatingEachYear(res.data.repeatingEachYear);
                    setReason(res.data.reason);
                })
                .catch(() => {
                    toastInfo("error", t('errorOccurred'));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            setIsLoading(false);
        }
    }, [id, t]);

    return (
        <div className={classes.restrictedDateForm}>
            <h2 className={classes.header}>{(id === "new") ? t('restrictedDate.title.new') : t('restrictedDate.title.edit')}</h2>
            <Paper elevation={2} className={classes.content}>
                {isLoading ? <CircularProgress /> :
                    <Grid container={true} direction={'column'} spacing={2 as GridSpacing}>
                        <Grid item xs={8}>
                            <CustomCalendar value={startDateTime} setValue={setStartDateTime} label={t('restrictedDate.from')}/>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomCalendar value={endDateTime} setValue={setEndDateTime} label={t('restrictedDate.to')}/>
                        </Grid>
                        <Grid item xs={8}>
                            <YesNoSelect value={repeatingEachYear} setValue={setRepeatingEachYear} label={t('restrictedDate.repeatingEachYear')}/>
                        </Grid>
                        <Grid item xs={8}>
                            <CustomInputText value={reason} setValue={setReason} label={t('restrictedDate.reason')} />
                        </Grid>
                    </Grid>
                }
                <ActionToolbar className={classes.buttons}>
                    <CustomTextButton onClick={backToListView} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={save} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.save')}/>
                </ActionToolbar>
            </Paper>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    restrictedDateForm: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    content: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "20px",
    },

    buttons: {
        marginTop: 20,
    },
}));

export default RestrictedDateFormView;