import React from 'react';
import {TableSortLabel} from "@mui/material";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";

interface Props {
    headCells: Array<any>,
    page: any,
    setPage: any,
    previewButton?: boolean,
}

// czy aktualnie wybrane sortowanie jest po kolumnie z headCell
const isHeadActive = (headCell: any, page: any) => {
    return headCell.field === page.sortField && headCell.joinField === page.joinSortField && headCell.joinField2 === page.joinSortField2;
}

const SortableTableHead = ({headCells, page, setPage, previewButton = true}: Props) => {

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, key) => (
                    <TableCell key={key}>
                        {(headCell.sortable) &&
                            <TableSortLabel
                                active={isHeadActive(headCell, page)}
                                direction={page.sortOrder === 1 || !isHeadActive(headCell, page) ? 'asc' : 'desc'}
                                onClick={() => setPage({
                                    joinSortField: headCell.joinField,
                                    joinSortField2: headCell.joinField2,
                                    sortField: headCell.field,
                                    sortOrder: !isHeadActive(headCell, page) ? 1 : 1 - page.sortOrder
                                })}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        }
                        {!headCell.sortable && <div>{headCell.label}</div>}
                    </TableCell>
                ))}
                {previewButton && <TableCell />}
            </TableRow>
        </TableHead>
    );
}

export default SortableTableHead;