import React, {useEffect, useState} from "react";
import UserTable from "./components/UserTable";
import UserSearchCriteria from "./components/UserSearchCriteria";
import {
    searchUserCountUsingPOST as searchUserCount,
    searchUserUsingPOST as getUsers
} from "../../swagger/vue-api-client"
import {IUser} from "../../interfaces";
import {useTranslation} from "react-i18next";
import {toastInfo} from "../../services/InfoService";
import {makeStyles} from "@material-ui/core/styles";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import AddIcon from "@material-ui/icons/Add";
import {useHistory} from "react-router-dom";

const UserListView = () => {
    const [users, setUsers] = useState<IUser[]>([]);
    const [isLoading, setIsLoading] = useState<Boolean>(true);
    const [searchCriteria, setSearchCriteria] = useState(getClearUserSearchCriteria());
    const [totalRecords, setTotalRecords] = useState(0);

    const {t} = useTranslation();
    const history = useHistory();

    const classes = useStyles();

    const updateSearchCriteria = (sc : any) => {
        setSearchCriteria(sc);
        updateTotalRecords(sc);
    }

    const goToAddUserView = () => {
        history.push({pathname: "/user/edit/0"});
    }

    const updateTotalRecords = (sc: any) => {
        if(sc.systemRole === "") {
            sc.systemRole = null;
        }
        searchUserCount({searchCriteria: sc})
            .then((res: any) => {
                setTotalRecords(res.data);
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
            });
    }

    useEffect(() => {
        if(searchCriteria.systemRole === "") {
            searchCriteria.systemRole = null;
        }
        getUsers({searchCriteria})
            .then((res : any) => {
                setUsers(res.data);
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [searchCriteria, t]);

    useEffect(() => {
        updateSearchCriteria(getClearUserSearchCriteria());
        // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.userList}>
            <h2>{t('user.listTitle')}</h2>
            <CustomTextButton width={"160px"} backgroundColor={Colors.LIGHT_GREY}
                              onClick={() => {goToAddUserView()}}
                              text={t('user.addUser')}
                              icon={<AddIcon/>}/>
            <UserSearchCriteria searchCriteria={searchCriteria} updateSearchCriteria={updateSearchCriteria}/>
            <UserTable users={users}
                       isLoading={isLoading}
                       searchCriteria={searchCriteria}
                       updateSearchCriteria={updateSearchCriteria}
                       totalRecords={totalRecords}/>
        </div>
    )
};

const getClearUserSearchCriteria = () : any => {
    return {
        firstName: "",
        lastName: "",
        email: "",
        systemRole: null,
        showDeleted: false,
        page: {
            pageNr: 0,
            limit: 10,
            offset: 0,
            sortField: null,
            sortOrder: null,
        },
    };
} ;

const useStyles = makeStyles(theme => ({
    userList: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },
}));

export default UserListView;