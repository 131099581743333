export const getCalendarLocale = (t: any) => {
    return {
        firstDayOfWeek: 1,
        dayNames: [
            t('calendar.dayNames.sunday'),
            t('calendar.dayNames.monday'),
            t('calendar.dayNames.tuesday'),
            t('calendar.dayNames.wednesday'),
            t('calendar.dayNames.thursday'),
            t('calendar.dayNames.friday'),
            t('calendar.dayNames.saturday'),
        ],
        dayNamesShort: [
            t('calendar.dayNamesShort.sunday'),
            t('calendar.dayNamesShort.monday'),
            t('calendar.dayNamesShort.tuesday'),
            t('calendar.dayNamesShort.wednesday'),
            t('calendar.dayNamesShort.thursday'),
            t('calendar.dayNamesShort.friday'),
            t('calendar.dayNamesShort.saturday'),
        ],
        dayNamesMin: [
            t('calendar.dayNamesMin.sunday'),
            t('calendar.dayNamesMin.monday'),
            t('calendar.dayNamesMin.tuesday'),
            t('calendar.dayNamesMin.wednesday'),
            t('calendar.dayNamesMin.thursday'),
            t('calendar.dayNamesMin.friday'),
            t('calendar.dayNamesMin.saturday'),
        ],
        monthNames: [
            t('calendar.monthNames.january'),
            t('calendar.monthNames.february'),
            t('calendar.monthNames.march'),
            t('calendar.monthNames.april'),
            t('calendar.monthNames.may'),
            t('calendar.monthNames.june'),
            t('calendar.monthNames.july'),
            t('calendar.monthNames.august'),
            t('calendar.monthNames.september'),
            t('calendar.monthNames.october'),
            t('calendar.monthNames.november'),
            t('calendar.monthNames.december')
        ],
        monthNamesShort: [
            t('calendar.monthNamesShort.january'),
            t('calendar.monthNamesShort.february'),
            t('calendar.monthNamesShort.march'),
            t('calendar.monthNamesShort.april'),
            t('calendar.monthNamesShort.may'),
            t('calendar.monthNamesShort.june'),
            t('calendar.monthNamesShort.july'),
            t('calendar.monthNamesShort.august'),
            t('calendar.monthNamesShort.september'),
            t('calendar.monthNamesShort.october'),
            t('calendar.monthNamesShort.november'),
            t('calendar.monthNamesShort.december')
        ],
        today: t('calendar.today'),
        clear: t('actions.clear'),
        weekHeader: t('calendar.weekHeader')
    }
}