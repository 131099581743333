import React, {useState} from "react";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import YesNoSelect from "../../components/form/YesNoSelect";
import {useTranslation} from "react-i18next";
import styled from "styled-components";
import {useHistory} from "react-router-dom";
import CustomInputNumber from "../../components/form/CustomInputNumber";

interface Props {
    setPublicView: any;
}
const SystemView = ({setPublicView}: Props) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [refreshTime,setRefreshTime] = useState<number>(15)
    const turnOnPublicView = () => {
        localStorage.setItem("publicView", String(refreshTime));
        setPublicView(refreshTime);
        history.push("/");
    }
    return (
        <Container>
            <h2>{t('system.title')}</h2>
            <CustomInputNumber value={refreshTime} setValue={setRefreshTime} label={t('system.publicRefreshTime')}/>
            <CustomTextButton onClick={turnOnPublicView} backgroundColor={Colors.LIGHT_GREY} text={t('system.publicView')}/>
        </Container>
    );
}

const Container = styled.div`
padding: 30px;
margin: 10px;
`;

export default SystemView