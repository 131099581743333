import React, {useState} from 'react'
import {makeStyles} from "@material-ui/core/styles";
import CustomInputText from "../components/form/CustomInputText";
import CustomTextButton from "../components/form/CustomTextButton";
import {Colors} from "../constants/colors";
import {Link, useHistory} from "react-router-dom";

import {registerUsingPOST as register} from "../swagger/vue-api-client"
import {toastInfo} from "../services/InfoService";
import styled from "styled-components";
import umbLogoWithName from "../assets/logo-i-orzel.png";
import {useTranslation} from "react-i18next";

const RegisterView = () => {
    const classes = useStyles();
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const passwordRegExp = new RegExp("^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\\[\\]{};:+=|,<.>\\`~£§?/'\\-\\_\"])(?=.*[a-zA-Z]).{8,}$");

    const history = useHistory();
    const {t} = useTranslation();

    const signUpFunction = () => {
        if (password !== confirmPassword) {
            toastInfo("error", t('changePassword.passwordsDiffer'));
            return;
        }
        if (!passwordRegExp.test(password)) {
            toastInfo("error", t('changePassword.insufficientPassword'));
            return;
        }
        // TODO tymczasowe rozwiązanie - do poprawy
        if (email.split('@').length !== 2 || email.split('@')[1] !== 'umb.edu.pl') {
            alert("Adres email musi należeć do domeny umb.edu.pl.")
            return;
        }

        let registrationRequest = {
            email: email,
            password: password,
            firstName: name,
            lastName: lastName,
        };

        register({registrationRequest}).then((res: any) => {
            if (res.data === "notDomainEmail") {
                toastInfo("error", t('register.wrongEmail'));
                return;
            }
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("role", res.data.role);
            localStorage.setItem("fullName", res.data.fullName);
            localStorage.setItem("userId", res.data.userId);

            toastInfo("success", t('register.registered'));

            history.push("login");
        }).catch(() => {
            toastInfo("error", t('register.notRegistered'));
        });
    }

    return (
        <div>
            <div className={classes.logoContainer}>
                <img src={umbLogoWithName} alt={t('register.logo')} height={120} style={{marginLeft: 90}}/>
            </div>
            <div className={classes.container}>
                <div style={{alignItems: "left", textAlign: "left", marginLeft: 10}}>
                    <StyledLink to={"/login"}>{t('register.login')}</StyledLink>
                </div>
                <div style={{marginTop: 5, marginBottom: 5}}>
                    <h1 style={{display: "inline"}}>{t('register.title')}</h1>
                </div>
                <CustomInputText value={email} setValue={setEmail} label={t('register.email')} margin="auto" width="300px" />
                <CustomInputText value={name} setValue={setName} label={t('register.firstName')}  margin="auto" width="300px"/>
                <CustomInputText value={lastName} setValue={setLastName} label={t('register.lastName')} margin="auto" width="300px"/>
                <CustomInputText value={password} setValue={setPassword} label={t('register.password')} password={true} margin="auto" width="300px"/>
                <CustomInputText value={confirmPassword} setValue={setConfirmPassword} label={t('register.repeatPassword')} password={true} margin="auto" width="300px"/>
                <div style={{marginLeft: 65, marginTop: 30}}>
                    <CustomTextButton onClick={() => signUpFunction()} backgroundColor={Colors.LIGHT_GREEN} text={t('register.submitButton')} width="300px"/>
                </div>
            </div>
        </div>
    )
}

const useStyles = makeStyles(() => ({
    logoContainer: {
        display: "flex",
        width: '100%',
        justifyContent: "center",
        alignItems: "center",
        margin: "auto",
        marginTop: 80,
    },
    container: {
        height: 450,
        width: 450,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        border: 3,
        borderColor: Colors.PRIMARY_GREEN,
        color: Colors.PRIMARY_GREEN,
        borderStyle: "ridge",
        margin: "auto",
        marginTop: 30,
        borderRadius: 10,
        fontFamily: 'PT Sans',
    },
    a: {
        color: Colors.PRIMARY_GREEN,
    },
}))

const StyledLink = styled(Link)`
  text-decoration: underline;
  color: #ab9424;
  &:hover, &:active, &:visited {
    color: #ab9424;
  }
`;

export default RegisterView;