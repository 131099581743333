import React, {useEffect, useState} from 'react';
import CalendarView from "./calendar/CalendarView";
import {useLocation} from "react-router-dom";

interface Props {
    publicView?: number | false
}
const HomePage = ({publicView = false}: Props) => {
    const location = useLocation<{
        date: Date;
    }>();

    const [date, setDate] = useState(new Date())

    useEffect(() => {
        date.setHours(8)
        date.setMinutes(0)
        date.setSeconds(0)
    }, [])

    if (location.state && location.state.date !== undefined) {
        return (<CalendarView date={location.state.date} setDate={setDate} publicView={publicView}/>)
    }

    return (<CalendarView date={date} setDate={setDate} publicView={publicView}/>)
}

export default HomePage;