import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {constDictType, fields, findEnumValue, getLabelOfDictionary} from "../../constants/dictType";
import {getDelDictionaryUsingPOST, getDictionariesUsingPOST as getDictionaries,} from "../../swagger/vue-api-client";
import {useHistory, useParams} from "react-router-dom";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@material-ui/core";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import {checkIfDependencyExist, checkIfFieldExist} from "../../constants/dictDependecies";
import {useTranslation} from "react-i18next";
import {RiArrowGoBackFill} from "react-icons/all";
import {getColorLabel} from "../../constants/colors";
import {toastInfo} from "../../services/InfoService";

const DictionaryInfo = () => {
    const [openConfirmDeleteUserDialog, setOpenConfirmDeleteUserDialog] = useState(false);
    const {id} = useParams<{id: string}>();
    const {dicTypeString} = useParams<{dicTypeString: string}>();
    const [dictDetails, setDictDetails] = useState<any>();
    const classes = useStyles()
    const history = useHistory();
    const {t} = useTranslation();

    const dictType = findEnumValue(dicTypeString);

    useEffect(() => {
        const dictionarySearchDto = {
            dictType: dicTypeString,
            id: parseInt(id, 10),
        }

        getDictionaries({dictionarySearchDto: dictionarySearchDto}).then((result: any) => {
            setDictDetails(result.data[0]);
        });
    }, [dicTypeString, id]);


    const editDictionary = () => {
        if (dictDetails !== undefined) {
            history.push({pathname: "/dictionary/edit/" + dicTypeString + "/" + id.toString()});
        }
    }

    const backToListView = () => {
        if (dicTypeString !== undefined) {
            history.push({pathname: "/dictionary/" + dicTypeString});
        }
    }

    const deleteDictionary = () => {
        const dictionaryDto = {
            dictType: dicTypeString,
            id: parseInt(id, 10),
        }

        if (dictDetails !== undefined) {
            getDelDictionaryUsingPOST({dictionaryDto: dictionaryDto}).then(() => {
                history.push({pathname: "/dictionary/" + dicTypeString});
                setOpenConfirmDeleteUserDialog(false);
                toastInfo("success", t('dictionary.deleteSuccess'));
            }).catch(() => {
                setOpenConfirmDeleteUserDialog(false);
            });
        }
    }

    return (
        <div className={classes.dictionaryInfo}>
            <h2 className={classes.header}>{t('dictionary.infoHeader')} {getLabelOfDictionary(dictType, t)}</h2>
            <Paper className={classes.content} elevation={2}>
                <div className={classes.buttons}>
                    <CustomTextButton backgroundColor={Colors.LIGHT_GREY} text={t('actions.back')}
                                      icon={<RiArrowGoBackFill/>} onClick={backToListView}/>
                </div>
                {dictDetails === undefined ? <CircularProgress /> :
                    <div className={classes.userDetails}>
                        {checkIfFieldExist(dictType, fields.NAME) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.name')}:</span>
                            <span>{dictDetails.name}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.LENGTH_IN_SEMESTERS) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.lengthInSemesters')}:</span>
                            <span>{dictDetails.lengthInSemesters}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.STUDENT_COUNT) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.studentCount')}:</span>
                            <span>{dictDetails.studentCount}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.TITLE) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.title')}:</span>
                            <span>{dictDetails.title}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.FIRST_NAME) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.firstName')}:</span>
                            <span>{dictDetails.firstName}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.LAST_NAME) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.lastName')}:</span>
                            <span>{dictDetails.lastName}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.ORGANIZATION_UNIT) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.organizationUnit')}:</span>
                            <span>{dictDetails.organizationUnit}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.TILE_COLOR_HEX) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.tileColorHex')}</span>
                            <div key={dictDetails.tileColorHex} className={classes.square}
                                 style={dictDetails.tileColorHex === "#ffffff" ? {background: dictDetails.tileColorHex, border: "solid 1px grey"} : {background: dictDetails.tileColorHex}} />
                            <span>&nbsp;{getColorLabel(dictDetails.tileColorHex)}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.FONT_COLOR_HEX) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.fontColorHex')}</span>
                            <div key={dictDetails.fontColorHex} className={classes.square}
                                 style={dictDetails.fontColorHex === "#ffffff" ? {background: dictDetails.fontColorHex, border: "solid 1px grey"} : {background: dictDetails.fontColorHex}} />
                            <span>&nbsp;{getColorLabel(dictDetails.fontColorHex)}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.NUMBER) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.number')}:</span>
                            <span>{dictDetails.number}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.ACTIVE) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.active')}:</span>
                            <span>{dictDetails.active ? t('actions.yes') : t('actions.no')}</span>
                        </div> : ""}
                        {checkIfFieldExist(dictType, fields.MAIN) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.main')}:</span>
                            <span>{dictDetails.main ? t('actions.yes') : t('actions.no')}</span>
                        </div> : ""}
                        {checkIfDependencyExist(dictType, constDictType.SUBJECT) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.subjectName')}</span>
                            <span>{dictDetails.subjectName}</span>
                        </div> : ""}
                        {checkIfDependencyExist(dictType, constDictType.DEGREE_COURSE) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.degreeCourseName')}</span>
                            <span>{dictDetails.degreeCourseName}</span>
                        </div> : ""}
                        {checkIfDependencyExist(dictType, constDictType.DEPARTMENT) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.departmentName')}</span>
                            <span>{dictDetails.departmentName}</span>
                        </div> : ""}
                        {checkIfDependencyExist(dictType, constDictType.CATEGORY) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.category')}</span>
                            <span>{dictDetails.categoryName}</span>
                        </div> : ""}
                        {checkIfDependencyExist(dictType, constDictType.PUBLIC_CATEGORY) ? <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('dictionary.publicCategory')}</span>
                            <span>{dictDetails.publicCategoryName}</span>
                        </div> : ""}
                    </div>
                }
                <div className={classes.buttons}>
                    <CustomTextButton onClick={() => setOpenConfirmDeleteUserDialog(true)} backgroundColor={Colors.LIGHT_RED} text={t('actions.delete')}/>
                    <CustomTextButton onClick={() => editDictionary()} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.edit')}/>
                </div>
            </Paper>
            <Dialog open={openConfirmDeleteUserDialog} onClose={() => setOpenConfirmDeleteUserDialog(false)}
                    PaperProps={{
                        style: {
                            minHeight: "20vh",
                            minWidth: "30vw"
                        }
                    }}>
                <DialogTitle>{t('dictionary.deleteLabel')}</DialogTitle>
                <DialogContent><DialogContentText>{t('dictionary.deleteLabelWarning')}</DialogContentText></DialogContent>
                <DialogActions>
                    <CustomTextButton onClick={() => setOpenConfirmDeleteUserDialog(false)} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={() => deleteDictionary()} backgroundColor={Colors.LIGHT_RED} text={t('actions.delete')}/>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    dictionaryInfo: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        maxWidth: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    content: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: 20,
        maxWidth: "100%",
    },

    userDetails: {
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    userDetailRow: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            marginBottom: 15,
        },
    },

    userDetailLabel: {
        fontWeight: "bold",
        minWidth: "160px",
        display: "inline-block",
    },
    userDeleted: {
        color: "red",
        marginBottom: "10px",
    },
    square: {
        width: 20,
        height: 20,
    },

    buttons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
    },
}));


export default DictionaryInfo;