import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import CustomInputText from "../../components/form/CustomInputText";
import {
    constDictType,
    fields,
    findEnumValue,
    getDictionaryTypeString,
    getLabelOfDictionary
} from "../../constants/dictType";
import CustomSelectOneDictionary from "../../components/form/dictionary/CustomSelectOneDictionary";
import {useHistory, useParams} from "react-router-dom";
import {Paper} from "@material-ui/core";
import {ActionToolbar} from "../../utils/styled";
import CustomTextButton from "../../components/form/CustomTextButton";
import {Colors} from "../../constants/colors";
import DictionaryTable from "./components/DictionaryTable";
import {items} from "../../constants/dictDependecies";
import {
    createOrUpdateDictionaryUsingPOST as createDictionary,
    getDictionariesCountUsingPOST as searchDictionaryCount,
} from "../../swagger/vue-api-client";
import {toastInfo} from "../../services/InfoService";
import CustomSelectOneColor from "../../components/form/CustomSelectOneColor";
import {useTranslation} from "react-i18next";
import {RiArrowGoBackFill} from "react-icons/all";
import CustomSelectOneYesNo from "../../components/form/CustomSelectOneYesNo";
import CustomColorPicker from "../../components/form/CustomColorPicker";
import YesNoSelect from "../../components/form/YesNoSelect";

const DictionaryView = () => {
    const classes = useStyles();
    const {t} = useTranslation();
    const history = useHistory();
    const [name, setName] = useState<string>("");
    const [lengthInSemesters, setLengthInSemester] = useState<string>("");
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [organizationUnit, setOrganizationUnit] = useState<string>("");
    const [tileColor, setTileColor] = useState<string>("");
    const [fontColor, setFontColor] = useState<string>("");
    const [studentCount, setStudentCount] = useState<string>("");
    const [dictDepartment, setDictDepartment] = useState<any>(null);
    const [dictDegreeCourse, setDictDegreeCourse] = useState<any>(null);
    const [dictSubject, setDictSubject] = useState<any>(null);
    const [dictPublicCategory, setDictPublicCategory] = useState<any>(null);
    const [number, setNumber] = useState<string>("");
    const [active, setActive] = useState<boolean>(true);
    const [totalRecords, setTotalRecords] = useState(0);

    const [main, setMain] = useState<boolean|null>(null);
    // eslint-disable-next-line
    const [reloadSearch, setReloadSearch] = useState<boolean>(false);
    const [searchCriteria, setSearchCriteria] = useState(getClearDictionarySearchCriteria());

    const {dicTypeString} = useParams<{ dicTypeString: string }>();
    const dictType: constDictType = findEnumValue(dicTypeString);

    useEffect(() => {
        setDictDegreeCourse(null);
        setDictSubject(null);
        setDictPublicCategory(null);
    }, [dictDepartment]);

    useEffect(() => {
        setDictSubject(null);
    }, [dictDegreeCourse]);

    useEffect(() => {
        clear();
        updateSearchCriteria(getClearDictionarySearchCriteria());
    }, [dicTypeString])

    useEffect(() => {
        setSearchCriteria({
            ...searchCriteria,
            departmentId: dictDepartment,
            subjectId: dictSubject,
            degreeCourseId: dictDegreeCourse,
            categoryId: dictDepartment,
            publicCategoryId: dictPublicCategory,
            name: name,
            firstName: firstName,
            lastName: lastName,
            title: title,
            tileColor: tileColor,
            fontColor: fontColor,
            organizationUnit: organizationUnit,
            studentCount: studentCount,
            lengthInSemesters: lengthInSemesters,
            number: number,
            active: active,
            main: main,
            page: {
                ...searchCriteria.page,
            }
        });
    }, [firstName, lastName, dictDepartment, dictSubject, dictDegreeCourse, dictPublicCategory, name, title, tileColor,
        fontColor, organizationUnit, studentCount, lengthInSemesters, number, active, main])


    const getItemsIndex = () => {
        for (let i = 0; i < items.length; i += 1) {
            if (items[i].dictType === dictType) {
                return i;
            }
        }
        return 0;
    }

    const updateSearchCriteria = (sc: any) => {
        setSearchCriteria(sc);
        updateTotalRecords(sc);
    }

    const updateTotalRecords = (sc: any) => {
        if(sc.systemRole === "") {
            sc.systemRole = null;
        }
        const dictionarySearchDto = {
            dictType: getDictionaryTypeString(dictType),
            departmentId: searchCriteria.departmentId,
            degreeCourseId: searchCriteria.degreeCourseId,
            subjectId: searchCriteria.subjectId,
            categoryId: searchCriteria.categoryId,
            publicCategoryId: searchCriteria.publicCategoryId,
            name: searchCriteria.name,
            firstName: searchCriteria.firstName,
            lastName: searchCriteria.lastName,
            title: searchCriteria.title,
            organizationUnit: searchCriteria.organizationUnit,
            lengthInSemesters: parseInt(searchCriteria.lengthInSemesters, 10),
            studentCount: parseInt(searchCriteria.studentCount, 10),
            tileColorHex: searchCriteria.tileColor,
            active: searchCriteria.active,
            page: searchCriteria.page,
            main: searchCriteria.main,
            number: searchCriteria.number,
        }
        searchDictionaryCount({dictionarySearchDto: dictionarySearchDto})
            .then((res: any) => {
                setTotalRecords(res.data);
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
            });
    }

    const clear = () => {
        setName("");
        setLengthInSemester("");
        setFirstName("");
        setLastName("");
        setTitle("");
        setOrganizationUnit("");
        setTileColor("");
        setFontColor("");
        setStudentCount("");
        setDictDepartment(null);
        setDictDegreeCourse(null);
        setDictSubject(null);
        setDictPublicCategory(null);
        setNumber("");
        setMain(null);
        setSearchCriteria({
            categoryId: null,
            publicCategoryId: null,
            departmentId: null,
            subjectId: null,
            degreeCourseId: null,
            name: "",
            firstName: "",
            lastName: "",
            title: "",
            tileColor: "",
            fontColor: "",
            organizationUnit: "",
            studentCount: "",
            lengthInSemesters: "",
            number: "",
            active: true,
            main: null,
            page: {
                pageNr: 0,
                limit: 10,
                offset: 0,
                sortField: null,
                sortOrder: null,
                joinSortField: null,
            },
        });
    }

    const addDictionary = () => {
        const dictionaryDto = {
            categoryId: dictDepartment,
            publicCategoryId: dictPublicCategory,
            departmentId: dictDepartment,
            degreeCourseId: dictDegreeCourse,
            subjectId: dictSubject,
            dictType: getDictionaryTypeString(dictType),
            firstName: firstName,
            lastName: lastName,
            organizationUnit: organizationUnit,
            name: name,
            main: main,
            title: title,
            lengthInSemesters: parseInt(lengthInSemesters, 10),
            studentCount: parseInt(studentCount, 10),
            tileColorHex: tileColor,
            fontColorHex: fontColor,
            number: number,
            active: active,
        }

        if (!validate(dictionaryDto)) {
            toastInfo("error", t('dictionary.incomplete'));
        } else {
            createDictionary({dictionaryDto: dictionaryDto}).then((res: any) => {
                if (res.data == "taken") {
                    toastInfo("error", t('nameTaken'));
                } else {
                    clear();
                    toastInfo("success", t('dictionary.addedNew'));
                }
            }).catch(() => {
                toastInfo("error", t('errorOccurred'));
            });
        }
    }
    // const search = () => {
    //     // setFirstName("");
    //     // setLastName("")
    //     // setTimeout(() => {
    //     //     setSearchCriteria({
    //     //         departmentId: dictDepartment,
    //     //         subjectId: dictSubject,
    //     //         degreeCourseId: dictDegreeCourse,
    //     //         name: name,
    //     //         firstName: firstName,
    //     //         lastName: lastName,
    //     //         title: title,
    //     //         tileColor: tileColor,
    //     //         fontColor: fontColor,
    //     //         organizationUnit: organizationUnit,
    //     //         studentCount: studentCount,
    //     //         lengthInSemesters: lengthInSemesters,
    //     //     });
    //     // }, 1000)
    // }

    const listOfSelectDictionaries = () => {
        const indexListOfSelect: number = getItemsIndex();
        const listSelect = items[indexListOfSelect].dictionaryDependencies.map((DICT_TYPE, index) => {
                if (index === 0) {
                    return <CustomSelectOneDictionary dictType={DICT_TYPE} value={dictDepartment} key={index}
                                                      setValue={setDictDepartment} parentValue={null}/>
                } else if (DICT_TYPE === constDictType.PUBLIC_CATEGORY) {
                    return <CustomSelectOneDictionary dictType={DICT_TYPE} value={dictPublicCategory} key={index}
                                                      setValue={setDictPublicCategory} parentValue={dictDepartment}/>
                } else if (index === 1) {
                    return <CustomSelectOneDictionary dictType={DICT_TYPE} value={dictDegreeCourse} key={index}
                                                      setValue={setDictDegreeCourse} parentValue={dictDepartment}/>
                } else {
                    return <CustomSelectOneDictionary dictType={DICT_TYPE} value={dictSubject} setValue={setDictSubject} key={index}
                                                      parentValue={{
                                                          dictDepartment: dictDepartment,
                                                          dictDegreeCourse: dictDegreeCourse
                                                      }}/>
                }
            }
        );

        return (
            <div className={classes.dictionaryCriteria}>{listSelect}</div>
        )
    };

    const listOfSelectInputText = () => {
        const createInputTextComponent = (FIELD_NAME: fields) => {
            if (FIELD_NAME === fields.NAME) {
                return <CustomInputText value={name} setValue={setName} label={t('dictionary.name')} width="300px" key={FIELD_NAME}/>
            } else if (FIELD_NAME === fields.LENGTH_IN_SEMESTERS) {
                return <CustomInputText value={lengthInSemesters} setValue={setLengthInSemester} key={FIELD_NAME}
                                        label={t('dictionary.lengthInSemesters')} width="300px" number={true}/>
            } else if (FIELD_NAME === fields.STUDENT_COUNT) {
                return <CustomInputText value={studentCount} setValue={setStudentCount} label={t('dictionary.studentCount')}
                                        width="300px" number={true} key={FIELD_NAME}/>
            } else if (FIELD_NAME === fields.TILE_COLOR_HEX) {
                return <div key={FIELD_NAME}><CustomColorPicker value={tileColor} setValue={setTileColor} label={t('dictionary.tileColorHex')} labelWidth={"50px"}/></div>
            } else if (FIELD_NAME === fields.FONT_COLOR_HEX) {
                return <div key={FIELD_NAME}><CustomColorPicker value={fontColor} setValue={setFontColor} label={t('dictionary.fontColorHex')} labelWidth={"100px"}/></div>
            } else if (FIELD_NAME === fields.FIRST_NAME) {
                return <CustomInputText value={firstName} setValue={setFirstName} label={t('dictionary.firstName')} width="300px" key={FIELD_NAME}/>
            } else if (FIELD_NAME === fields.LAST_NAME) {
                return <CustomInputText value={lastName} setValue={setLastName} label={t('dictionary.lastName')} width="300px" key={FIELD_NAME}/>
            } else if (FIELD_NAME === fields.TITLE) {
                return <CustomInputText value={title} setValue={setTitle} label={t('dictionary.title')} width="300px" key={FIELD_NAME}/>
            } else if (FIELD_NAME === fields.ORGANIZATION_UNIT) {
                return <CustomInputText value={organizationUnit} setValue={setOrganizationUnit} label={t('dictionary.organizationUnit')} width="300px" key={FIELD_NAME}/>
            } else if (FIELD_NAME === fields.NUMBER) {
                return <CustomInputText value={number} setValue={setNumber} label={t('dictionary.number')}
                                        width="300px" key={FIELD_NAME} number={true}/>
            } else if (FIELD_NAME === fields.ACTIVE) {
                return <div key={FIELD_NAME} style={{marginTop: 25}}><CustomSelectOneYesNo value={active} setValue={setActive} key={FIELD_NAME}/></div>
            } else if (FIELD_NAME === fields.MAIN) {
                return <div key={FIELD_NAME} style={{marginTop: 25}}><CustomSelectOneYesNo value={main} setValue={setMain} key={FIELD_NAME} label={"dictionary.main"} showClear={true}/></div>
            }
        };

        const indexListOfSelect: number = getItemsIndex();
        const listSelect = items[indexListOfSelect].dictionaryFields.map((FIELD) =>
            createInputTextComponent(FIELD)
        );

        return (
            <div className={classes.dictionaryCriteria}>{listSelect}</div>
        )
    };

    const isEmptyOrNull  = function (...args:string[])  {
        for (let i = 0; i < arguments.length; i++) {
            let str = arguments[i];
            if (str === null || str.length === 0) {
                return true;
            }
        }
        return false;
    }

    const validate = (dictDto:any) => {
        if (dictDto.dictType == null) {
            return false;
        }
        switch (findEnumValue(dictDto.dictType)) {
            case constDictType.CLASSROOM:
                return !isEmptyOrNull(dictDto.name) && dictDto.main != null;
            case constDictType.DEGREE_COURSE:
                return !isEmptyOrNull(dictDto.name) && dictDto.departmentId != null;
            case constDictType.DEPARTMENT:
                return !isEmptyOrNull(dictDto.name);
            case constDictType.EQUIPMENT:
                return !isEmptyOrNull(dictDto.name);
            case constDictType.STUDENT_GROUP:
                return dictDto.degreeCourseId != null && !isNaN(dictDto.studentCount) && !isEmptyOrNull(dictDto.name);
            case constDictType.EVENT_TYPE:
                return !isEmptyOrNull(dictDto.name);
            case constDictType.TEACHER:
                return !isEmptyOrNull(dictDto.firstName, dictDto.lastName, dictDto.title, dictDto.organizationUnit);
            case constDictType.SUBJECT:
                return !isEmptyOrNull(dictDto.name, dictDto.tileColorHex, dictDto.fontColorHex) && !isNaN(dictDto.lengthInSemesters) && dictDto.degreeCourseId != null;
            case constDictType.TECHNICIAN:
                return !isEmptyOrNull(dictDto.firstName, dictDto.lastName);
            case constDictType.CATEGORY:
                return !isEmptyOrNull(dictDto.name) && !isNaN(dictDto.number);
            case constDictType.PUBLIC_CATEGORY:
                return !isEmptyOrNull(dictDto.name) && !isNaN(dictDto.number);
        }
        return false;
    }

    const backToListView = () => {
        history.push({pathname: "/dictionaries"});
    }

    return (
        <div className={classes.dictionary}>
            <div className={classes.headerContainer}>
                <h2 className={classes.header}>{t('dictionary.header')} {getLabelOfDictionary(dictType, t)}</h2>
                <CustomTextButton width={"100px"} backgroundColor={Colors.LIGHT_GREY}
                                  text={t('actions.back')}
                                  icon={<RiArrowGoBackFill/>}
                                  onClick={backToListView}
                />
            </div>
            <Paper className={classes.content}>
                {listOfSelectDictionaries()}
                {listOfSelectInputText()}
                <br/>
                <ActionToolbar>
                    <CustomTextButton onClick={addDictionary} backgroundColor={Colors.BEIGE} text={t('actions.add')}/>
                </ActionToolbar>
            </Paper>
            <DictionaryTable searchCriteria={searchCriteria} dictType={dictType} reloadSearch={reloadSearch}
                             totalRecords={totalRecords} updateSearchCriteria={updateSearchCriteria}/>
        </div>
    )
}

const getClearDictionarySearchCriteria = () : any => {
    return {
        departmentId: null,
        subjectId: null,
        degreeCourseId: null,
        page: {
            pageNr: 0,
            limit: 10,
            offset: 0,
            sortField: null,
            sortOrder: null,
            joinSortField: null,
        },
    }
}

const useStyles = makeStyles(theme => ({
    dictionary: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    header: {
        paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },

    content: {
        margin: "5px",
        marginBottom: "30px",
        padding: "15px",
        maxWidth: "100%",
    },

    dictionaryCriteria: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        width: "100%",
        columnGap: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        },
    },
}));

export default DictionaryView;
