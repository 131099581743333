import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {
    createOrUpdateUserUsingPOST as createOrUpdateUser,
    getUserUsingGET as getUser,
    remindPasswordUsingPOST,
    restoreDeletedUserUsingPOST as restoreDeletedUser
} from "../../swagger/vue-api-client";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../constants/colors";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper
} from "@material-ui/core";
import {IUser} from "../../interfaces";
import CustomTextButton from "../../components/form/CustomTextButton";
import {SystemRole} from "../../utils/SystemRole";
import {useTranslation} from "react-i18next";
import {toastInfo} from "../../services/InfoService";
import {RiArrowGoBackFill} from "react-icons/all";

interface Params {
    userId: string;
}

const UserView = ({userId}: Params) => {
    const [openConfirmDeleteUserDialog, setOpenConfirmDeleteUserDialog] = useState(false);
    const [openConfirmRestoreUserDialog, setOpenConfirmRestoreUserDialog] = useState(false);
    const [userDetails, setUserDetails] = useState<IUser>();
    const history = useHistory();

    const {id} = useParams<{id: string}>();

    const classes = useStyles();
    const {t} = useTranslation();

    useEffect(() => {
        getUser({id})
            .then((res: any) => {
                setUserDetails(res.data);
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
            });
    }, [id, t]);

    const deleteUser = () => {
        const deletedUser : IUser = {...userDetails, deleted: true};
        createOrUpdateUser({userDto : deletedUser})
            .then(() => {
                setUserDetails(deletedUser);
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
            })
            .finally(() => {
                setOpenConfirmDeleteUserDialog(false);
            });
    }

    const restoreUser = () => {
        restoreDeletedUser({userId : userDetails?.id})
            .then(() => {
                const updatedUserDetails = userDetails;
                // @ts-ignore
                updatedUserDetails.deleted = false;
                setUserDetails(updatedUserDetails);
            })
            .catch(() => {
                toastInfo("error", t('errorOccurred'));
            })
            .finally(() => {
                setOpenConfirmRestoreUserDialog(false);
            });
    }

    const editUser = () => {
        if (userDetails !== undefined) {
            history.push({pathname: "/user/edit/" + userDetails.id});
        }
    }

    const resetPassword = () => {
        if (userDetails !== undefined) {
            remindPasswordUsingPOST({passwordRemindRequest: {email: userDetails.email}}).then(() => {
                toastInfo("success", t('user.sentPasswordResetEmail'));
            }).catch(() => {
                toastInfo("error", t('errorOccurred'));
            });
        }
    }

    const backToListView = () => {
        history.push({pathname: "/users"});
    }

    return (
        <div className={classes.userView}>
            <div className={classes.headerContainer}>
                <h2 className={classes.header}>{t('user.title')}</h2>
                <CustomTextButton backgroundColor={Colors.LIGHT_GREY} text={t('actions.back')}
                                  icon={<RiArrowGoBackFill/>} onClick={backToListView}/>
            </div>
            <Paper className={classes.content} elevation={2}>
                {userDetails === undefined ? <CircularProgress /> :
                    <div className={classes.userDetails}>
                        {userDetails.deleted &&
                        <div className={classes.userDeleted}>
                            <span>{t('user.userDeleted')}</span>
                        </div>}
                        <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('user.firstName')}</span>
                            <span>{userDetails.firstName}</span>
                        </div>
                        <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('user.lastName')}</span>
                            <span>{userDetails.lastName}</span>
                        </div>
                        <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('user.email')}</span>
                            <span>{userDetails.email}</span>
                        </div>
                        <div className={classes.userDetailRow}>
                            <span className={classes.userDetailLabel}>{t('user.role')}</span>
                            <span>{SystemRole.roles.filter((r) => r.value === userDetails.systemRole)[0].label}</span>
                        </div>
                    </div>
                }
                <div className={classes.buttons}>
                    { !userDetails?.deleted &&
                        <CustomTextButton onClick={() => setOpenConfirmDeleteUserDialog(true)} backgroundColor={Colors.LIGHT_RED} text={t('actions.delete')}/> }
                    { userDetails?.deleted &&
                        <CustomTextButton onClick={() => setOpenConfirmRestoreUserDialog(true)} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.restore')}/> }
                    <CustomTextButton onClick={editUser} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.edit')}
                                        disabled={userDetails?.deleted}/>
                    <CustomTextButton onClick={resetPassword} backgroundColor={Colors.LIGHT_GREY} text={t('actions.resetPassword')}
                                        disabled={userDetails?.deleted}/>
                </div>
            </Paper>
            <Dialog open={openConfirmDeleteUserDialog} onClose={() => setOpenConfirmDeleteUserDialog(false)}
                    PaperProps={{
                        style: {
                            minHeight: "20vh",
                            minWidth: "30vw"
                        }
                    }}>
                <DialogTitle>{t('user.deleteUserLabel')}</DialogTitle>
                <DialogContent><DialogContentText>{t('user.deleteUserLabelWarning')}</DialogContentText></DialogContent>
                <DialogActions>
                    <CustomTextButton onClick={() => setOpenConfirmDeleteUserDialog(false)} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={() => deleteUser()} backgroundColor={Colors.LIGHT_RED} text={t('actions.delete')}/>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmRestoreUserDialog} onClose={() => setOpenConfirmRestoreUserDialog(false)}
                    PaperProps={{
                        style: {
                            minHeight: "20vh",
                            minWidth: "30vw"
                        }
                    }}>
                <DialogTitle>{t('user.restoreUserLabel')}</DialogTitle>
                <DialogContent/>
                <DialogActions>
                    <CustomTextButton onClick={() => setOpenConfirmRestoreUserDialog(false)} backgroundColor={Colors.LIGHT_GREY} text={t('actions.cancel')}/>
                    <CustomTextButton onClick={() => restoreUser()} backgroundColor={Colors.LIGHT_GREEN} text={t('actions.restore')}/>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
    userView: {
        padding: "20px 40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        maxWidth: "100vw",
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
    },

    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "baseline",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            alignItems: "center",
            marginBottom: 20,
        },
    },

    header: {
        paddingRight: 100,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
    },

    content: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: 20,
        maxWidth: "100%",
    },

    userDetails: {
        padding: 20,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    userDetailRow: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            marginBottom: 15,
        },
    },
    userDetailLabel: {
        fontWeight: "bold",
        minWidth: "160px",
        display: "inline-block",
    },
    userDeleted: {
        color: "red",
        marginBottom: "10px",
    },

    buttons: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        width: "100%",
    },
}));

export default UserView;